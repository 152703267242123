import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   QuantityExpedition extends Component{

    state = {
        id : null,
        name : "",
        error : "",
        unites : [],
        formData : null,
        isModal : false,
        btn : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    storeQuantity = (e) =>{
        e.preventDefault();
        // toast("Quantité à expedier bien actualisée", { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        let total = parseInt(this.props.formData.stock), expedie = parseInt(this.props.formData.expedie)
        this.props.formData?.colidetails?.map((val) => {
            total -= val.expedie
            expedie += val.expedie
        })
        this.props.formData.stock = total
        this.props.formData.expedie = expedie
        let data = {
            colis : [this.props.formData],
            manifesteId :  this.props.manifesteId
        }
        this.setState({
            btn : !this.state.btn
        })

        axios.post('manifestecolis/', data).then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(this.props.formData, this.props.coliIdex)
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
    }

    form=()=>{
        let total = parseInt(this.props.formData.stock), expedie = parseInt(this.props.formData.expedie)
        this.props.formData?.colidetails?.map((val) => {
            total += val.expedie
            expedie -= val.expedie
        })
        this.props.formData.stock = total
        this.props.formData.expedie = expedie
        
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="sm"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header className="border border-2 mt-4 border-primary border-bottom-0">
                    <Modal.Title  className="nom-id-icon">{ "Quantité expédiée" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0 border border-2 border-primary  border-top-0">
                    <form onSubmit={this.storeQuantity} >
                    <div className="col-md-12 mt-2 p-0">
                        <table className="table_css -border-bottom col-12 ">
                        {
                            this.props.formData?.colidetails?.map((colidetail, count) => {
                                return(
                                    <tr className="border border-white">
                                        <td className="border border-white">
                                            <input type="number" placeholder={ colidetail.stock - colidetail.expedie } min={0} max={  parseInt(colidetail.stock ) - parseFloat( colidetail.expedieT) } className="border rounded-4 w-100 form form-control form-sm"
                                                onChange={(e) => {
                                                    let data = this.props.formData.colidetails
                                                    if(parseInt(e.target.value) <= parseInt(colidetail.stock)) 
                                                    {
                                                        data[count].expedie = parseInt(e.target.value) 
                                                    }
                                                    else
                                                    {
                                                        data[count].expedie += parseInt(e.target.value) > 0 ?  0 : 0
                                                    }
                                                    this.props.formData.colidetails = data
                                                }}
                                            />
                                        </td>
                                        <td className="border border-white">{ parseInt(colidetail.stock ) - parseFloat( colidetail.expedieT) } #{ colidetail.unite?.name } { colidetail.article?.name }</td>
                                    </tr>
                                )
                            })
                        }
                        </table>

                    </div>

                    <div className="col-md-12 mt-2 form">
                        <Modal.Footer>
                            <button type="submit" disabled={ this.state.btn} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Enregistrer</button>
                        </Modal.Footer>
                    </div>
                    </form>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default QuantityExpedition;
