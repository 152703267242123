import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import '../assets/css/invoicelandspace.css'
class   PrintManifeste_ extends Component{

    render(){
        
        let rows = []
        for (let index = 0; index < (10 - this.props.manifeste?.manifestecolis?.length); index++) {
            rows.push(<tr>
                <td>*</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
       
        return(
            <div>
                <div className="row mt-4 pt-4 p-0 m-0">

                    <div  ref={el => (this.componentRef= el )} style={{ borderWidth : 1}}>

                    <div  style={{ fontSize : 11}} className="rotated">

                        <div className=" d-flex  border-1 border-bottom">
                            <div className="flex-shrink-1"><img src={ this.props.manifeste?.origine?.logo} style={{ width : "45px", height : "45px"}}/></div>
                            <div className="w-100 text-center">
                                <h5><strong><small>AGENCE DE TRANSPORT </small> { this.props.manifeste?.origine?.name }</strong></h5>
                                <div><small>{ this.props.manifeste?.origine?.social_reason }{ this.props.manifeste?.origine?.trade_registry }{ this.props.manifeste?.origine?.nif }{ this.props.manifeste?.origine?.activity_sector }{ this.props.manifeste?.origine?.tax_center }</small></div>
                            </div>
                        </div>
                        <div className="manifesteBodyPdf row rounded-4 bg-white shadow-3">
                        <div className="col-3">

                            <div className="cardImage ratio-1:1 w-200 rounded-4">
                            {/* <div className="cardImage ratio ratio-1:1 w-200 rounded-4"> */}
                                <div className="cardImage__content">
                                    <strong  style={{ fontSize : 11}}>N° { this.props.manifeste?.ref_manifeste }</strong>
                                    <div className="">
                                        
                                        <div className="text-12 lh-14 text-light-1">Expéditeur</div>
                                        <div className="text-12 lh-14 text-light-1">{ this.props.manifeste?.user?.name }</div>
                                        <div className="text-12 lh-14 text-light-1"><a href={"tel:" + this.props.manifeste?.user?.tel}>{this.props.manifeste?.user?.tel}</a> </div>
                                        
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-6">
                            <h3 className="text-12 lh-14 fw-400 text-center"> { this.props.manifeste?.origine?.city } <i className="fa fa-road text-primary"></i> { this.props.manifeste?.destination?.city } </h3>

                            <div className="">
                                <div className="items-center">
                                    <p className="text-12 text-center">
                                        {/* <span className=""> { this.props.manifeste?.vehicle?.description}</span> */}
                                        <button data-x-click="mapFilter" className="text-blue-1  ml-10">  { this.props.manifeste?.vehicle?.plaque} { this.props.manifeste?.vehicle?.mark?.name}</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-3 text-right md:text-left">
                            <div className="d-flex flex-column justify-between h-full">
                                <div className="row justify-end items-center md:justify-start">
                                    <div className="col-auto">
                                        <div className="text-12 lh-14 fw-500">Chauffeur</div>
                                        <div className="text-12 lh-14 text-light-1">{this.props.manifeste?.driver?.name}</div>
                                        <div className="text-12 lh-14 text-light-1"><a href={"tel:" + this.props.manifeste?.driver?.tel}>{this.props.manifeste?.driver?.tel}</a> </div>
                                        <div className="text-12 lh-14 fw-500">Date sortie</div>
                                        <div className="text-12 lh-14 text-light-1">{ this.props.manifeste?.datesortie ?  new Date(this.props.manifeste?.datesortie).toLocaleDateString("en-Gb")  : "..../..../...."}</div>
                                    </div>
                                </div>

                                
                            </div>
                        </div>

                        </div>

                        <div className={"tabs__content js-tabs-content mb-10 pb-10"}>
                        <div className="tabs__pane -tab-item-1 is-tab-el-active">
                            <div className="">
                            <table className="table_css_invoice_ -border-bottom col-12">
                                <thead className="bg-light-2">
                                    <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                        <th>N°</th>
                                        <th>NOMS</th>
                                        <th>TELEPHONE</th>
                                        <th title="Nombre des colis">COLIS</th>
                                        <th>A PAYER</th>
                                        <th>PAYE KPL</th>
                                        <th>EMPRUNT</th>
                                        <th>OBSERVATION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.props.coliData.list}
                                    { rows }
                                    <tr><td colSpan={8}>.</td></tr>
                                    
                                </tbody>
                            </table>
                            </div>
                        </div>
                        </div>

                        </div>
                    </div>
                    </div>
                    <div>
                        <ReactToPrint
                            trigger={() => <button type="button" className="border-light mb-4 text-underline  rounded-100 py-5 px-20 text-14 lh-14 text-primary"> <i className="fa fa-print"></i> Imprimer manifeste { this.props.coliData.city } </button>}
                            content={() =>  this.componentRef}
                        />
                </div>
        </div>
        )
    }
}
export default PrintManifeste_;
