import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   ProfilForm extends Component{

    state = {
        id : null,
        name : "",
        error : "",
        profilepermissions : [],
        isModal : false,
        title : "",
        permissionId : [],
        permissions : [],
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async permissions () {
        this.setState({
            permissionId : []
        })

        var permissions = await axios.get('permissions');
        if(permissions.status == 200){
            this.setState({
                permissions : permissions.data
            });
            var permissionId = [];
            permissions.data.map((permission) => {
                var count = this.state.profilepermissions.filter(data => data.permissionId == permission.id).length;
                if(count > 0)
                permissionId.push(permission.id);
            })

            this.setState({
                permissionId : permissionId
            })
        }
    };

    storeProfile = () =>{
        
        var permissionId = [];

        this.setState({
            btn : !this.state.btn
        })

        for (let x in this.state.permissionId) {
                permissionId.push({ permissionId : this.state.permissionId[x]})
          }

        var data = {
                name : this.state.name,
                permissionId : permissionId,
        }

        var request = this.state.id ? axios.put('profiles/'+this.state.id, data) : axios.post('profiles', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal()
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
        })
    }

    edit=()=>  {
        this.form();
        this.permissions()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            name: this.props.formData ? this.props.formData.name : "",
            title : this.props.formData ? "Modifier profil " + this.props.formData?.name : "Ajouter profil des utilisateurs",
            profilepermissions : this.props.formData ? this.props.formData.profilepermissions ? this.props.formData.profilepermissions.length > 0 ? this.props.formData.profilepermissions : [] : [] : [],
            error : "",
        })
    }

    handleInputPermission = (e) => {
        var permissionId = this.state.permissionId;
        var permissions = this.state.permissions;
        this.setState({
            permissionId : [],
            permissions : []
        })
        var exist = permissionId.filter(data => data == e.target.name).length;
        if(exist > 0)
            permissionId = permissionId.filter(data => data != e.target.name);
        else
            permissionId.push(e.target.name);

        this.setState({
            permissionId : permissionId,
            permissions : permissions
        })
        
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    delete = (profile) =>{
        if(window.confirm("cette action est irreversible") == true){
            axios.delete("profiles/" + profile).then((response) => {
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
                this.props.showModal()
            }).catch((error)=>{})
        }
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val == item.name)
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size=""
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Body>
                    <div>{ this.state.title}</div>
                    <form>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-12 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Profil</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                            </div>
                        </div>

                        <div className="form-group has-icon-left">
                            {
                                this.state.permissions.map((permission) => {
                                    return(
                                        <label className="px-1 rounded rounded-3 border border-1 mt-2 ms-2 mx-2" key={ permission.id}>
                                            <input type="checkbox" className="round round-3" onChange={this.handleInputPermission} name={ permission.id }  checked={this.state.permissionId.filter(data => data == permission.id).length}  id={permission.name} />
                                            <label htmlFor={permission.name}>{ permission.name }</label>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {
                    (this.handleCheck("supprimer profil") == true && this.state.id) && (
                        <a href="#" onClick={() => this.delete(this.state.id)} className="button py-5 px-5 -dark-1 bg-red-1 text-white"><i className="fa fa-trash font-15"></i> Supprimer</a>
                    )}
                    <button onClick={this.props.showModal} className="button py-5 px-5 -red-1 bg-blue-1 text-white"><i className="fa fa-corner-down-left"></i> Fermer</button>
                    {
                    (this.handleCheck("ajouter profil") == true || this.handleCheck("modifier profil") == true  && this.state.id) && (
                        <button onClick={() => this.storeProfile()} disabled={ this.state.btn } className="button py-5 px-5 py-5 -dark-1 bg-blue-1 text-white"><i className="fa fa-check"></i> Sauvegarder</button>
                    )}
                </Modal.Footer>
            </Modal>


        </div>
        )
    }
}
export default ProfilForm;
