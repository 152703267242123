import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/404.css'

const NotFound = () => (
  <div className="container">
    <div className="wrapper"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-500 0 2920 1080">
 
   
  <g id="fortyfour" data-name="Layer 2">
    <g className="four a">
      
      <rect className="cls-2 yellow" x="233.74" y="391.14" width="50.71" height="466.29" rx="5.1" ry="57.1" transform="translate(918.39 330.19) rotate(90)"  style={{ color : "yellow" }}/>
        
      <rect className="cls-3 yellow" x="333.83" y="475.1" width="50.71" height="396.88" rx="6.36" ry="60.36"/>
     
      <rect className="cls-3 yellow" x="197.13" y="122.89" width="50.71" height="604.75" rx="6.36" ry="60.36" transform="translate(290.49 -70.78) rotate(35)"/>
  
    </g>
    <g className="four b">

      <rect className="cls-2 red" x="1558.84" y="391.91" width="50.71" height="466.29" rx="5.1" ry="57.1" transform="translate(2244.26 -994.14) rotate(90)"/>
  
   
      <rect className="cls-3 red" x="1658.92" y="475.87" width="50.71" height="396.88" rx="6.36" ry="60.36"/>

      <rect className="cls-3 red" x="1522.22" y="123.66" width="50.71" height="604.75" rx="6.36" ry="60.36" transform="translate(530.57 -830.68) rotate(35)"/>
 
    </g>
    <path className="cls-3 border" id="ou" d="M956.54,168.2c-194.34,0-351.89,157.55-351.89,351.89S762.19,872,956.54,872s351.89-157.55,351.89-351.89S1150.88,168.2,956.54,168.2Zm0,584.49c-128.46,0-232.6-104.14-232.6-232.6s104.14-232.6,232.6-232.6,232.6,104.14,232.6,232.6S1085,752.69,956.54,752.69Z"/>
  </g>
  
 
  <g id="clock" data-name="Layer 8">

    <circle className="cls-5" cx="847.7" cy="247.59" r="74.66" transform="translate(-32.91 314.05) rotate(-20.6)"/>
    <circle className="cls-1" cx="847.7" cy="247.59" r="63.44" transform="translate(-32.91 314.05) rotate(-20.6)"/>
    <rect className="cls-3 clock-hand-1" x="845" y="189.5" width="6.04" height="58" rx="3.02" ry="3.02" />
    <rect className="cls-3 clock-hand-2" x="845" y="209.5" width="6.04" height="38" rx="3.02" ry="3.02" transform="translate(1611.22 -230.4) rotate(130.4)"/>
        <circle className="cls-3" cx="847.7" cy="247.59" transform="translate(-32.91 314.05) rotate(-20.6)" r="3" />
  </g>
  
  <g id="bike" data-name="Layer 5">
    <path className="cls-8 wheel" d="M1139.82,780.44a76.59,76.59,0,1,0-57.9,91.53A76.59,76.59,0,0,0,1139.82,780.44Zm-28.12,6.33a47.59,47.59,0,0,1,.83,15.8c-30.14-6.28-47.68-21.65-54.39-52.52A47.73,47.73,0,0,1,1111.69,786.77Zm-70.46-30.9c10.35,26.88,10.14,50.4-13.73,70.77a47.67,47.67,0,0,1,13.73-70.77Zm34.35,88a47.55,47.55,0,0,1-34.94-5.62c16.8-20.36,41.71-25.94,67.09-19.46A47.66,47.66,0,0,1,1075.58,843.85Z"/>
    <path className="cls-8 wheel" d="M864.89,789.69a76.59,76.59,0,1,0-66.13,85.78A76.59,76.59,0,0,0,864.89,789.69Zm-28.59,3.7a47.59,47.59,0,0,1-.64,15.81c-29.43-9-45.47-26-49.3-57.33A47.73,47.73,0,0,1,836.3,793.39ZM769,756.1c7.82,27.72,5.43,51.12-20.22,69.2A47.67,47.67,0,0,1,769,756.1Zm26.06,90.78a47.55,47.55,0,0,1-34.27-8.83c18.61-18.72,43.93-22,68.6-13.16A47.66,47.66,0,0,1,795.06,846.88Z"/>
    <g>
      <rect className="cls-1" x="871.39" y="693.37" width="12.87" height="53.21" transform="translate(-165.97 273.38) rotate(-16.19)"/>
      <path className="cls-5" d="M813.93,679.35c-3.72-5.2,2.24-18.5,9.16-16.13,33.43,11.46,73.85,10.45,73.85,10.45,8.84.15,14.44,10.34,7.27,15.48-14.36,8.79-33.13,17-56.35,9.76C830.17,693.41,819.83,687.6,813.93,679.35Z"/>
      <path className="cls-7" d="M813.93,679.35c-3.72-5.2,2.24-18.5,9.16-16.13,33.43,11.46,73.85,10.45,73.85,10.45,8.84.15,14.44,10.34,7.27,15.48-14.36,8.79-33.13,17-56.35,9.76C830.17,693.41,819.83,687.6,813.93,679.35Z"/>
      <path className="cls-5" d="M817.15,680.06c-3.59-5,1.69-16.51,8.37-14.22,32.3,11.09,71.41,7.83,71.41,7.83,8.54.14,17.45,9.94,7.43,15.88-13.87,8.51-32,16.44-54.44,9.44C832.84,693.67,822.85,688,817.15,680.06Z"/>
    </g>
    <g>
      <circle className="cls-9" cx="1022.66" cy="599.55" r="11.57" transform="translate(-4.86 8.38) rotate(-0.47)"/>
      <path className="cls-1" d="M1069.76,792.37l-34.89-96.74,1.93-.8-1.71-4.15-1.74.72-13.26-36.76,1.27-.42-2.25-6.76,5.94-2-2.57-7.72-9.7,3.22c-11.55-22.55,2-36.33,15-41.86l-5.57-8.81c-23,10.29-29.61,28.75-19.53,54l-9.38,3.12,2.56,7.72,5.47-1.82,2.25,6.76,2.36-.78,13.62,37.76-2.35,1,1.71,4.15,2.16-.89,34.65,96.09a7.47,7.47,0,0,0,9.56,4.49h0A7.47,7.47,0,0,0,1069.76,792.37Z"/>
      <circle className="cls-11" cx="1027.9" cy="587.94" r="12.99" transform="translate(-4.77 8.42) rotate(-0.47)"/>
    </g>
    <path className="cls-5" d="M1021.29,654l-17.73,6.15,1.72,5.59-31.41,82.36c-19.35,32.53-66.3,36.72-75.56,16.68l-7.09-21.5L879,747.1l3.28,10.09-94.65,33.95c-11.49,2.29-11.85,15.79-2.61,17.84,0,0,39.11,3.66,103,9.5a92.75,92.75,0,0,0,40.89-5.29c44.32-16.56,57.73-50.67,57.73-50.67l26.82-67.26a1.37,1.37,0,0,1,2.53,0l1.42,3.33,17.75-7.62Z"/>
    <path className="cls-7" d="M1021.29,654l-17.73,6.15,1.72,5.59-31.41,82.36c-19.35,32.53-66.3,36.72-75.56,16.68l-7.09-21.5L879,747.1l3.28,10.09-94.65,33.95c-11.49,2.29-11.85,15.79-2.61,17.84,0,0,39.11,3.66,103,9.5a92.75,92.75,0,0,0,40.89-5.29c44.32-16.56,57.73-50.67,57.73-50.67l26.82-67.26a1.37,1.37,0,0,1,2.53,0l1.42,3.33,17.75-7.62Z"/>
  </g>
</svg>
</div>
<div className="text-center mb-4">
  <h4 className="h4">Page not found</h4>
  <a href="/" className="btn btn-primary btn-sm">Acceuil</a>
</div>

</div>
);

export default NotFound;