import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import ArticleForm from "./ArticleForm";
import UniteForm from "./UniteForm";
import StaffForm from "./StaffForm";
import { toast } from "react-toastify";

class   ColiForm extends Component{

    state = {
        id : null,
        ref_coli : null,
        quantity : null,
        // weight : null,
        client_id : null,
        destinationId : null,
        status: 'EnStock',
        error : "",
        isClient : false,
        isArticle : false,
        isUnite : false,
        due : 0,

        title : "",
        articles : [],
        unites : [],
        clients : [],
        colis : [],
        branches : [],
        colisCount : [],
        colidetails : [{
            index : 0,
            articleId : null,
            quantity : "",
            price : "",
            unitePrice : "",
            uniteId : null
        }],

        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleInputDetail = (val) => {
        
        let colidetails = this.state.colidetails;
        let exist = colidetails.filter(data => data.index == val?.index)
        if(!val.index)
        {
            colidetails.push({
                index : colidetails.length,
                articleId : null,
                quantity : "",
                price : "",
                uniteId : null,
                unitePrice : ""
            })
        }
        else if(exist.length > 0 ){
            colidetails = colidetails.filter(data => data.index != val.index)
        }
        this.setState({ colidetails : colidetails})
    }

    inputSelect = () => {
        this.setState({
            isUnite : true
        })
    }

    async getColis(){
        let request = await axios.get('/colis/status/EnStock');
        if(request.status === 200)
            this.setState({colis : request.data })
    }

    async getBranches(){
        let request = await axios.get('/branches');
        if(request.status === 200)
            this.setState({branches : request.data })
    }

    async getColi(val){
        let request = await axios.get('/colis/' + val);
        if(request.status === 200){
            let colidetails = [], index = -1
            request.data.colidetails.map((colidetail) => {
                index = index + 1
                colidetails.push({
                    index : index,
                    articleId : colidetail.articleId,
                    quantity : colidetail.quantity,
                    price : colidetail.price,
                    unitePrice : parseFloat (colidetail.price) / parseFloat(colidetail.quantity),
                    uniteId : colidetail.uniteId
                })
            })
            
            this.setState({ ref_coli : request.data.ref_coli, destinationId : request.data.destinationId, colidetails : colidetails })
        }
    }

    async colisCount(){
        let request = await axios.get("/colis/count/status")
            if(request.status == 200)
                this.setState({ colisCount : request.data})
    }

    async getArticles(){
        let request = await axios.get('/articles')
        if(request.status === 200)
            this.setState({ articles : request.data })
    }

    async getClients(){
        let request = await axios.get('/staffs_/$userprofile.profileId$/' + process.env.REACT_APP_ID_CLIENT)
        if(request.status === 200)
            this.setState({ clients : request.data })
    }



    async getUnites(){
        let request = await axios.get('/unites')
        if(request.status === 200)
            this.setState({ unites : request.data })
    }
    

    storeColi = (e) =>{
        e.preventDefault();
        this.setState({
            btn : !this.state.btn
        })
        
        let price_Total = 0, quantity_Total= 0
        this.state.colidetails.map(colidetail => { 
            price_Total += (parseFloat(colidetail.unitePrice) * parseFloat(colidetail.quantity) )
            quantity_Total += parseFloat(colidetail.quantity) 
        } )

        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}
        
        var data = {
            ref_coli : this.state.ref_coli,
            client_id : this.state.client_id,
            destinationId : this.state.destinationId,
            quantity : quantity_Total,
            price : price_Total,
            // weight : weight_Total,
            due : 0,
            status : this.state.status,
            brancheId : branche.userprofile?.brancheId,
            colidetails : this.state.colidetails
        }

        var request = this.state.id ? axios.put('colis/'+this.state.id, data) : axios.post('colis', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(response.data?.data)
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
   }

    showModalArticle = (val = null) => {
        this.setState({
            isArticle : false,
        })
        if(val != null){
            let data = this.state.colidetails
            data[this.state.colidetails.length - 1].articleId = val
            this.setState({ colidetails : data })
            this.getArticles()
        }
    }

    showModalUnite = (val = null) => {
        this.setState({
            isUnite : false,
        })
        if(val != null){
            let data = this.state.colidetails
            data[this.state.colidetails.length -1].uniteId = val
            this.setState({ colidetails : data })
            this.getUnites()
        }
    }

    showModalClient = (val = null) => {
        this.setState({
            isClient : false,
        })
        if(val != null && !val?.id){
            this.setState({ client_id : val})
            this.getClients()
        }
    }

    edit=()=>  {
        this.form()
        this.getBranches()
        this.getArticles()
        this.getUnites()
        this.getClients()
        this.getColis()
        this.colisCount()
        
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            status: this.props.formData ? this.props.formData.status : "EnStock",
            ref_coli: this.props.formData ? this.props.formData.ref_coli : null,
            client_id: this.props.formData ? this.props.formData.client_id : null,
            destinationId: this.props.formData ? this.props.formData.destinationId : null,
            colidetails: this.props.formData ? this.props.formData.colidetails : [{
                index : 0,
                articleId : null,
                quantity : "",
                price : "",
                uniteId : null,
                unitePrice : ""
            }],
            title : this.props.formData ? "Modifier colis de " + this.props.formData?.price + "$" : "Ajouter colis",
            error : "",
        })
        if(this.props.formData?.id)
            this.getColi(this.props.formData?.id)
        
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let count = 0
        this.state.colisCount.map((data) => {
            count += data.count
        })
        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}
        let date = new Date()
        // let ref_coli = "COL-" + count + "/" + date.getMonth() + "" + (date.getFullYear() - 2000);
        let ref_coli = count < 10 ? "00" +  (count + 1) : count < 100  ? "0" + (count + 1) : count + 1 ;
        if(this.state.ref_coli)
            ref_coli = this.state.ref_coli
        let optionArticles = [{
            value : null,
            label :<span>Nouvel article</span>
        }],
        optionBranches = [{
            value : null,
            label :<span></span>
        }],
        optionUnites = [{
            value : null,
            label :<span>Nouvelle unite</span>
        }],
        
        optionColis = [{
            value : -1,
            label :<span>Nouveau recu {ref_coli}</span>
        }];

        this.state.colis.filter(val => val.coli_count == 1 &&  val.client && val.client.id === this.state.client_id && val.status == "EnStock").map((coli) => {
            optionColis.push({
                label : coli.client?.name + ", "  + coli.ref_coli+ ""+ coli.coli_count + " (" + coli.price +"$)",
                value : coli.id
            })
        })

        this.state.unites.map((unite) => {
            optionUnites.push({
                label : unite.name,
                value : unite.id
            })
        })

        this.state.branches.filter(val => val.id != branche.userprofile?.brancheId).map((branche) => {
            optionBranches.push({
                label : branche.country + " " + branche.city,
                value : branche.id
            })
        })

        this.state.articles.map((article) => {
            optionArticles.push({
                label : article.name,
                value : article.id
            })
        })
        
        let optionClients = [{
            value : null,
            label :<span>Nouveau client</span>
        }]
        this.state.clients.map((client) => {
            optionClients.push({
                label : client.name,
                value : client.id
            })
        })

        let price_Total = 0,  quantity_Total = 0

        let colidetailInput =
        this.state.colidetails.map((colidetail, count) => {
            price_Total += (parseFloat(colidetail.unitePrice) * parseFloat(colidetail.quantity))
            quantity_Total += parseFloat(colidetail.quantity)
            return(
                <div className="row">
                    <div className="col-md-2">
                        <input type="number" required={true} min={1} className="form-control" placeholder="quantite" 
                        value={colidetail.quantity} 
                        onChange={(e) => {
                            let data = this.state.colidetails
                                data[count].quantity = e.target.value
                                this.setState({
                                    colidetails : data
                                })
                        }}  id="nom-id-icon" />
                    </div>

                    <div className={"col-md-3" } >
                        <Select
                            className="form-control form-control-sm p-0"
                            value = {
                                optionUnites.filter(option => 
                                    colidetail.uniteId === option.value)
                            }
                            required={true} 
                            defaultValue={colidetail.uniteId}
                            placeholder={ colidetail.uniteId}
                            onChange={
                                (select) => {
                                    let data = this.state.colidetails
                                    data[count].uniteId = select.value
                                    this.setState({
                                        colidetails : data,
                                        isUnite : select.value ? false : true
                                    })
                                }
                            }
                            options={optionUnites}
                        />
                    </div>

                    <div className={"col-md-3" } >
                        <Select
                            className="form-control form-control-sm p-0"
                            value = {
                                optionArticles.filter(option => 
                                    colidetail.articleId === option.value)
                            }
                            required={true} 
                            defaultValue={colidetail.articleId}
                            placeholder={ colidetail.articleId}
                            onChange={
                                (select) => {
                                    let data = this.state.colidetails
                                    data[count].articleId = select.value
                                    this.setState({
                                        colidetails : data,
                                        isArticle : select.value ? false : true
                                    })
                                }
                            }
                            options={optionArticles}
                        />
                    </div>

                    <div className="col-md-2">
                        <input type="number" required={true} className="form-control" placeholder="prix unitaire"  
                        value={ colidetail.unitePrice}
                        onChange={(e) => {
                            let data = this.state.colidetails
                                data[count].unitePrice = e.target.value
                                this.setState({
                                    colidetails : data
                                })
                        }}  id="nom-id-icon" />
                    </div>

                    <div className="col-md-2">
                        <input type="number" required={true} className="form-control" placeholder=""  value={colidetail.unitePrice * colidetail.quantity}/>
                    </div>

                    {/* <div className="col-md-2"> */}
                    <div  className="d-flex justify-content-end mb-4" >
                        {
                            this.state.colidetails.length === count+1 ?
                            <>
                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => this.handleInputDetail({})}> <i className="fa fa-plus"></i> </button>
                            { colidetail.index > 0 && (<button type="button" className="btn btn-outline-danger btn-sm" onClick={() =>this.handleInputDetail(colidetail)}> <i className="fa fa-minus"></i> </button>)}
                            </>
                            : colidetail.index > 0 && (<button type="button" className="btn btn-outline-danger btn-sm" onClick={() =>this.handleInputDetail(colidetail)}> <i className="fa fa-minus"></i> </button>)
                        }
                    </div>
                </div>
            )
        })

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <form onSubmit={this.storeColi}>
                <Modal.Body className="row">
                    <Modal.Title>{ this.state.id ? this.props.formData?.id ? "Modifier colis bordereau N°"  : "Attacher au bordereau N°."  : "Ajouter colis bordereau N°." } <strong className="text-primary">{ ref_coli } </strong> {  price_Total > 0 ? price_Total + "$" : "" } </Modal.Title>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>

                    <div className="row">
                        <div className={ (optionColis.length > 1 && !this.props.formData?.id) ? "col-md-4" : "col-md-6"}>
                            <Select
                                className="form-control form-control-sm p-0"
                                value = {
                                    optionClients.filter(option => 
                                        this.state.client_id != null && this.state.client_id === option.value)
                                }
                                required={true} 
                                defaultValue={ this.state.client_id }
                                placeholder={ "Client*"}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            client_id : select.value,
                                            isClient : select.value ? false : true,
                                            id : this.props.formData?.id ? this.state.id : null,
                                            ref_coli : this.props.formData?.id ? this.state.ref_coli :null,
                                            colidetails : this.props.formData?.id ? this.state.colidetails : [{
                                                index : 0,
                                                articleId : null,
                                                quantity : "",
                                                price : "",
                                                unitePrice : "",
                                                uniteId : null
                                            }]
                                        })
                                    }
                                }
                                options={optionClients}
                            />
                        </div>

                        <div className={ (optionColis.length > 1 && !this.props.formData?.id) ? "col-md-4" : "d-none" } >
                            <Select
                                className="form-control form-control-sm p-0"
                                value = {
                                    optionColis.filter(option => 
                                        this.state.id != null && this.state.id === option.value)
                                }
                                defaultValue={ this.state.id }
                                placeholder={ "Nouveau recu " + ref_coli }
                                onChange={
                                    (select) => {
                                        if(select.value != -1){
                                            this.getColi(select.value)
                                            this.setState({
                                                id : select.value,
                                            })
                                        }else{
                                            this.setState({
                                                id : null,
                                                ref_coli : null,
                                                colidetails : [{
                                                    index : 0,
                                                    articleId : null,
                                                    quantity : "",
                                                    price : "",
                                                    unitePrice : "",
                                                    uniteId : null
                                                }]
                                            })
                                        }
                                    }
                                }
                                options={optionColis}
                            />
                        </div>

                        <div className={  (optionColis.length > 1 && !this.props.formData?.id) ? "col-md-4" : "col-md-6" } >
                            <Select
                                className="form-control form-control-sm p-0"
                                value = {
                                    optionBranches.filter(option => 
                                        this.state.destinationId != null && this.state.destinationId === option.value)
                                }
                                required={true} 
                                defaultValue={ this.state.destinationId }
                                placeholder={ "Destination *"}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            destinationId : select.value,
                                        })
                                    }
                                }
                                options={optionBranches}
                            />
                        </div>

                        
                    </div>

                    <div className={  "col-md-6 mt-2" }>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Total quantite article<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="number" readOnly={true} className="form-control" placeholder=""  value={quantity_Total} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className={ "col-md-6 mt-2" }>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Prix total general($)<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="number" readOnly={true} required={true}  className="form-control" placeholder=""  value={ price_Total } id="nom-id-icon" />
                            </div>
                        </div>
                    </div>


                    <hr className="mt-1" />
                    <div className="row">
                        <div className="col-md-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Qté<span className="text-danger">*</span></label>
                            </div>
                        </div>

                        <div className={"col-md-3" } >
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Unité<span className="text-danger">*</span></label>
                            </div>
                        </div>

                        <div className={"col-md-3" } >
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Article<span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Prix.U<span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Prix.T</label>
                            </div>
                        </div>
                        
                    </div>
                    <div className={ (this.state.isClient) ? "d-none" : ""}>
                        { colidetailInput }
                    </div>
 
                </Modal.Body>
                <Modal.Footer>
                    <div className={ (this.state.isClient) ? "d-none" : ""}>
                        <button onClick={this.props.showModal} type="button" className="btn btn-outline-secondary btn-sm"><i className="fa fa-corner-down-left"></i> Fermer</button>
                        <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="fa fa-check"></i> Sauvegarder</button>
                    </div>
                </Modal.Footer>
                </form>
            </Modal>

            <ArticleForm
                isModal={this.state.isArticle}
                showModal={this.showModalArticle}
            />

            <UniteForm
                isModal={this.state.isUnite}
                showModal={this.showModalUnite}
            />

            <StaffForm
                isModal={this.state.isClient}
                profile={"Client"}
                profileId={ process.env.REACT_APP_ID_CLIENT}
                showModal={this.showModalClient}
            />
            
        </div>
        )
    }
}
export default ColiForm;
