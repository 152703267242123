import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import MarkForm from "./MarkForm";
import { toast } from "react-toastify";

class   VehicleForm extends Component{

    state = {
        id : null,
        markId : null,
        plaque : "",
        description : "",
        error : "",
        groupe : "",

        title : "",
        isMark : false,
        marks : [],
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getMarks(){
        let data = await axios.get('/marks')
        if(data.status == 200)
            this.setState({ marks : data.data })
    }

    showModalMark = (mark= null) => {
        this.setState({
            isMark : false,
        });
        if(mark == 1)
            this.getMarKs()
    }


    storeVehicule = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })
        
        var data = {
                markId : this.state.markId,
                groupe : this.state.groupe,
                plaque : this.state.plaque,
                description : this.state.description,
        }

        var request = this.state.id ? axios.put('vehicles/'+this.state.id, data) : axios.post('vehicles', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn,
                markId : null,
                groupe : null,
                plaque : null,
                description : null
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(1)
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
        this.getMarks()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            groupe: this.props.formData ? this.props.formData.groupe : "",
            description: this.props.formData ? this.props.formData.description : "",
            plaque: this.props.formData ? this.props.formData.plaque : null,
            markId: this.props.formData ? this.props.formData?.mark?.id : null,
            title : this.props.formData ? "Modifier le vehicule" : "Ajouter le vehicule",
            error : "",
        })
    }

    render(){
        
        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <form onSubmit={this.storeVehicule}>
                <Modal.Body className="row">
                    <Modal.Title>{ this.state.title}</Modal.Title>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-md-6 mt-2   mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Plaque<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="plaque"  value={this.state.plaque} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">description</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="description"  value={this.state.description} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>


                    <div className={"col-md-6 mt-2 mt-2 " } >
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Marque<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <select id="markId" name="markId" required={true}  value={this.state.markId} onChange={(e) =>{
                                    this.setState({
                                        [e.target.name]: e.target.value == "null"  ? null : e.target.value,
                                        isMark : e.target.value == "null" ? true : false
                                    });
                                }} className="form-select">
                                    <option key={"mark1"} value="">...</option>
                                    <option key={"mark2"} value={"null"}>Nouvelle marque</option>
                                    {
                                        this.state.marks.map((mark) => {
                                            return(
                                                <option key={ mark.id } value={ mark.id}>{ mark.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-6 mt-2 mt-2 "}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Groupe<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <select id="groupe" name="groupe" required={true}  value={this.state.groupe} onChange={this.handleInput} className="form-select">
                                    <option value="" key={"groupe"}>...</option>
                                    <option value="Grand vehicule" key={"Grand vehicule"}>Grand vehicule</option>
                                    <option value="Petit vehicule" key={"Petit vehicule"}>Petit vehicule</option>
                                </select>
                            </div>
                        </div>
                    </div>
 

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} type="button" className="btn btn-outline-secondary btn-sm"><i className="bi bi-corner-down-left"></i> Fermer</button>
                    <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="bi bi-check"></i> Sauvegarder</button>
                </Modal.Footer>
                </form>
            </Modal>

            <MarkForm
                isModal={this.state.isMark}
                showModal={this.showModalMark}
            />
        </div>
        )
    }
}
export default VehicleForm;
