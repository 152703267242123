import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   SubdepenseForm extends Component{

    state = {
        id : null,
        libele : "",
        mount : "",
        depense : null,
        error : "",
        title : "",
        depenses : []
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getDepenses(){
        var request = await axios('depenses/$typedepenseId$/' + this.props.typedepenseId);
        if(request.status == 200)
            this.setState({ depenses : request.data  })
    }

    storeSubdepense = () =>{
        var data = {
                libele : this.state.libele,
                mount : this.state.mount,
                depenseId : this.state.depenseId,
                typedepenseId : this.props.typedepenseId
            }
        var request = this.state.id ? axios.put('subdepenses/'+this.state.id, data) : axios.post('subdepenses', data);
        request.then(async(response) =>{
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(1);
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    destroySubdepense = () =>{
        axios.delete('subdepenses/'+this.state.id).then(async(response) =>{
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(1);
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
        this.getDepenses()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            libele: this.props.formData ? this.props.formData.libele : "",
            mount: this.props.formData ? this.props.formData.mount : "",
            error : ""
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

        return(
            <div>
            <Modal
                className="bg-dark"
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header >
                    <Modal.Title  className="nom-id-icon">{ this.state.id ? "Annuler" : "Ajouter" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0">
                    <div className="form">
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        <label htmlFor="h6 font-monospace">Vehicule</label>
                        <select name="depenseId" className="form-control form-control-sm" value={ this.state.depenseId}  onChange={ this.handleInput}>
                            <option value="">......</option>
                            {
                                this.state.depenses?.filter(val => val.rest > 0)?.map((depense) => {
                                    return(
                                        <option value={ depense.id}>{ depense?.manifeste?.ref_manifeste } { depense?.rest }$</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-md-7 mt-2 form">
                        <div className="form-group ">
                            <label htmlFor="h6 font-monospace">Libele</label>
                            <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="libele"  value={this.state.libele} id="nom-id-icon" />
                        </div>
                    </div>

                    <div className="col-md-5 mt-2 form">
                        
                        <div className="form-group ">
                            <label htmlFor="h6 font-monospace">Montant</label>
                            <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="mount"  value={this.state.mount} id="nom-id-icon" />
                        </div>
                        <Modal.Footer>
                            <button onClick={() => this.props.showModal(null)} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                            { this.state.id ? (<button onClick={() => this.storeSubdepense()} className="btn btn-sm btn-outline-danger"><i className="ti ti-trash"></i> Supprimer</button>) :
                            <button onClick={() => this.storeSubdepense()} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Sauvegarder</button> }
                        </Modal.Footer>
                    </div>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default SubdepenseForm;
