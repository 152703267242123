import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class Reportsortiemensuel extends Component{

    state = {
        depenses : [],
        typedepenses : [],
        value : null,
        branches : [],
        depenseCount : [],
        manifestes : [],
        brancheId : null,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getDepenses();
        this.getTypedepenses();
        this.getBranches();
        this.getManifestes();
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            brancheId : brancheId?.userprofile.brancheId
        })
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    getManifestes = async () => {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD 00:00');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');

        let date =  moment.range(startOfMonth, endOfMonth)

        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        
        let request = await axios.get('/manifestes/status/' + start + '/' + end + '/null');
        if(request.status === 200)
            this.setState({manifestes : request.data, loading : false})
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getDepenses();
    };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getTypedepenses(){
        let request = await axios.get("/typedepenses")
        if(request.status == 200)
            this.setState({ typedepenses : request.data?.sort((a, b) => a.type > b.type ? 1 : -1).filter(a => a.ismonth == 0)})
    }

    async getDepenses(val = null){
        this.setState({
            depenses : [],
            loading : true
        })
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD 00:00');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');

        let date =  moment.range(startOfMonth, endOfMonth)
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        
        
        let request = await axios.get('/manifestes/$depenses.typedepense.ismonth$/' + start + '/' + end + '/1');
        if(request.status === 200)
            this.setState({depenses : request.data, loading : false})
        
    }

    render(){
        
        let price = 0, due = 0, litige = 0, depense = 0, comission = 0
        let manifestes =
        this.state.manifestes.map((manifeste, count) => {
            price = 0
            due = 0
            depense = 0
            comission = 0
            litige = 0
            manifeste.depenses?.filter(dep => dep.typedepense?.ismanifeste == 1).map( depese => comission += depese.mount)
            manifeste.depenses?.filter(dep => dep.typedepense?.ismonth == 0 && dep.brancheId != process.env.REACT_APP_BRANCHE_KMPL).map( depese => {
                depense += depese.mount
            })
            manifeste.manifestecolis?.map((manifestecoli) => {
               
                due += (manifestecoli?.invoices?.length == 0) ? manifestecoli.price : 0
                manifestecoli?.invoices.map((invoice) =>{
                    litige += invoice.litige || 0
                    due += (invoice.due > 0 && invoice.isDue == 1) ? invoice.due : 0
                })
                price += manifestecoli.price
            })

            let totalKmpl = 0;
            manifeste.budgets.map((budge) => {
                if(budge.brancheId == process.env.REACT_APP_BRANCHE_KMPL)
                    totalKmpl = budge.mount
            })

            return({
                id : manifeste.id,
                mount : price - comission - depense - litige - totalKmpl - due
            })
        })

        let somme = 0, sommemanifeste = 0
        return(
            <div className=" bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             {/* { status } */}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1 d-none">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_depense">par ref depense</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="typedepense.type">par type depense</option>
                                        <option value="beneficer.name">par beneficiaire</option>
                                        <option value="user.name">par responsable</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getDepenses({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)} className="rotated">
                        <div className=" text-center text-primary">
                        <HeadDoc title={"Sorties mensuelles effectuees" } />
                        </div><div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                                <table className="table_css -border-bottom col-12 p-0">
                                    <thead>
                                        <tr style={{ backgroundColor : "rgb(0, 0, 150)", fontWeight : "800", color : "rgb(250, 250, 250)"}}>
                                            <td colSpan={4} align="center">PERIODE  {this.renderSelectionValue()} </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td align="center" colSpan={2} style={{ color : "rgb(240, 100, 60)", fontWeight : "800"}}>INTERET OU PERTE</td>
                                            <td align="center" colSpan={2} style={{ color : "rgb(240, 100, 60)", fontWeight : "800"}}>DEPENSE MENSUELLE</td>
                                        </tr>

                                        <tr>
                                            <td colSpan={2} style={{ verticalAlign : "top"}}>
                                                <table className="table_css -border-bottom col-12">
                                                    <tbody>
                                                        <tr>
                                                            <th className="border ms-4">VEHICULE</th>
                                                            <th className="border ms-4"  align="right" style={{ paddingRight : 5}}>SOMME</th>
                                                        </tr>
                                                    {
                                                        this.state.depenses?.map((manifeste) =>{
                                                            sommemanifeste += (manifestes.filter(val => val.id == manifeste.id)[0]?.mount || 0)
                                                            return(<>
                                                                <tr>
                                                                    <td className="border ms-4" style={{ color : "rgb(240, 100, 60)", fontWeight : "800"}}>{ manifeste?.ref_manifeste }</td>
                                                                    <td className="border ms-4" align="right" style={{ paddingRight : 5}}>{ manifestes.filter(val => val.id == manifeste.id)[0]?.mount }</td>
                                                                </tr>
                                                            </>)
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td colSpan={2}>
                                            <table className="table_css -border-bottom col-12">
                                                    <tbody>
                                                        <tr>
                                                            <th className="border ms-4">LIBELLE</th>
                                                            <th className="border ms-4" align="right" style={{ paddingRight : 5}}>SOMME</th>
                                                        </tr>
                                                        {
                                                            this.state.depenses?.map((manifeste) =>{
                                                                
                                                                return(
                                                                    manifeste.depenses?.filter(val => val.typedepense?.ismanifeste == 0)?.map((depense) =>{
                                                                        somme += depense.mount
                                                                        return(
                                                                            <tr>
                                                                                <td className="border ms-4">{ depense.typedepense?.type }</td>
                                                                                <td className="border ms-4" align="right" style={{ paddingRight : 5}} >{ depense.mount }</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>  
                                        <tr>
                                            <td><strong>TOTAL</strong></td>
                                            <td align="right" style={{ paddingRight : 5}}><strong>{ sommemanifeste }</strong></td>
                                            <td><strong>TOTAL</strong></td>
                                            <td  align="right" style={{ paddingRight : 5}}><strong>{somme }</strong></td>
                                        </tr> 
                                        <tr>
                                            <th colSpan={2}>SOLDE GENERAL</th>
                                            <td colSpan={2} align="right" style={{ paddingRight : 5}}><strong>{sommemanifeste- somme }</strong></td>
                                        </tr>   
                                    </tbody>
                                </table>

                        
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default Reportsortiemensuel;
