import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import InvoiceDesign from "../components/InvoiceDesign";

class Facture extends Component{

    state = {
        invoices : [],
        formDataInvoice : null,
        isInvoiceModal : false
    }

    componentDidMount(){
        this.getInvoices()
    }

    async getInvoices(){
        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        let request = await axios.get('/invoices/$invoices.client_id$/' + user?.id);
            if(request.status === 200)
                this.setState({invoices : request.data })
    }

    showModalInvoice = (invoice) =>{
        
        let data = {}
        if(invoice && invoice != 1)
        {
            data = {
                id : invoice.id,
                invoiceId : invoice?.id,
                ref_number : invoice?.ref_invoice,
                due : invoice?.due,
                column : "$invoices.id$",
                type : "FACTURE"
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
    }

    render(){
        
        let invoices = this.state.invoices?.map((invoice) => {

            let sum = 0, coliD = null
            invoice.colis.map(coli => { coliD = coli; sum += coli.quantity }) 
           
            return(
                <div className="col-lg-4 col-sm-6 p-1 m-0">
                    <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                        <div className="d-flex justify-center">
                            { invoice.colis.map(coli => { return <Link to={"#"} onClick={ () => this.showModalInvoice(invoice)}>{ coli.manifeste?.ref_manifeste } <small className={ coli.manifeste?.status == "EnCours" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.manifeste?.status == "Cloture" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> { coli.manifeste?.status }</small> ,</Link> }) }
                        </div>

                        <div className="text-center mt-30">
                            <h4 className="text-18 fw-500"><Link to={"#"} onClick={ () => this.showModalInvoice(invoice)}>{ invoice.ref_invoice } </Link></h4>
                            <p className="text-15 mt-10">{ sum }article{sum > 1 ? "s" : ""} à { invoice.type == "Dette" ? <span><span className="text-danger">{ invoice.due  }</span>/{invoice.price}</span> : invoice.price }$.</p>
                        </div>
                        <p className="text-end"><small>{ new Date(invoice.createdAt).toLocaleDateString("en-GB") }</small></p>
                    </div>
                </div>
            )
        })
        return(
            <div className="dashboard__content bg-light-2">
                
                <div className="container">
                    <div className=" items-end">
                        <div className="col-auto">
                            <h1 className="text-25 lh-14 fw-500 text-primary">Mes factures  </h1>
                        </div>
                    </div>
                    <div className="row y-gap-40 justify-between pt-50">
                        { invoices }
                    </div>
                    <div className="py-30 px-30 rounded-4 shadow-3">
                        <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
                    </div>
                </div>
                     
            </div>
        )
    }
}
export default Facture