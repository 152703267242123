import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class ReportGestioncaisse extends Component{

    state = {
        depenses : [],
        manifestes : [],
        value : null,
        branches : [],
        depenseCount : [],
        brancheId : null,
        currentYear : null,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getDepenses();
        this.getManifestes();
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            currentYear : new Date().getFullYear(),
            brancheId : brancheId?.userprofile.brancheId
        })
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getDepenses();
    };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }


    handleInput = (e) => {
        this.setState({
            currentYear: e.target?.value,
            manifestes : []
        });
        this.getManifestes(e.target?.value)
        this.getDepenses(e.target?.value)
    }
    async getManifestes(val = null){
        let currentYear = val ? val :  this.state.currentYear ? this.state.currentYear : new Date().getFullYear()
        let request = await axios.get('/manifestes/createdAt/' + currentYear + '-01-01/' + currentYear + '-12-31/null')
        if(request.status == 200)
            this.setState({ manifestes : request.data?.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)})
    }

    async getDepenses(val = null){
        this.setState({
            depenses : [],
            loading : true
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("MM-DD") : date.start?.format("MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("MM-DD") : date.end?.format("MM-DD")
        
        let currentYear = val ? val :  this.state.currentYear ? this.state.currentYear : new Date().getFullYear()
        let request = await axios.get('/manifestes/$depenses.typedepense.ismonth$/' + currentYear+"-"+start + '/' + currentYear+"-"+end + '/3');
        if(request.status === 200)
            this.setState({depenses : request.data, loading : false})
        
    }

    render(){
        let years = [], current = new Date();
        for (let index = 0; index < 5; index++) {
            years.push(current.getFullYear() - index)
        }
        let sommeman=0, sommemanT=0, sommedep =0, months = [
            { id  : 0, label  : 'JANVIER' },
            { id  : 1, label  : 'FEVRIER' },
            { id  : 2, label  : 'MARS' },
            { id  : 3, label  : 'AVRIL' },
            { id  : 4, label  : 'MAI' },
            { id  : 5, label  : 'JUIN' },
            { id  : 6, label  : 'JUILLET' },
            { id  : 7, label  : 'AOUT' },
            { id  : 8, label  : 'SEPTEMBRE' },
            { id  : 9, label  : 'OCTOMBRE' },
            { id  : 10, label  : 'NOVEMBRE' },
            { id  : 11, label  : 'DECEMBRE' }
        ]
        return(
            <div className=" bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             {/* { status } */}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1 d-none">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_depense">par ref depense</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="typedepense.type">par type depense</option>
                                        <option value="beneficer.name">par beneficiaire</option>
                                        <option value="user.name">par responsable</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getDepenses({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)} className="rotated">
                        <div className=" text-center text-primary">
                        <HeadDoc title={"Gestion de la caisse " + this.state.currentYear } />
                        </div>
                            <div className="text-center">
                                <div className="row y-gap-20 justify-center items-end  table_action">
                                    <div className="text-center col-auto d-none">
                                        <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button>
                                    </div>
                                    <div className={"col-auto m-0 p-0 ms-1 border border-primary"}>
                                        <select name="column" placeholder="Année" value={ this.state.currentYear} onChange={ this.handleInput} className="form form-control form-control-sm border border-dark" >
                                            <option>selectionner l'année</option>
                                            {
                                                years.map((year) => {
                                                    return(
                                                        <option key={ year} value={ year}>{ year }</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1 row">
                                    <div className="col-md-5 pe-1">
                                        <table className="table_css -border-bottom col-12">
                                            <tbody>
                                                <tr>
                                                    <td align="center" style={{ fontWeight : "800"}}>Mois / { this.state.currentYear }</td>
                                                    <td align="center" style={{ fontWeight : "800"}}>Interet</td>
                                                </tr>

                                                {
                                                    months?.map((month) =>{
                                                        sommeman = 0
                                                        let colis = 0, deps = 0, coliInvoice = 0, price = 0, due = 0, litige = 0, budget = 0, depense = 0, comission = 0, tBudget = 0, tLitige = 0, tColis = 0, tColiInvoice = 0, tPrice = 0, tdue = 0, tdepense = 0
                                                    
                                                        this.state.manifestes.filter(val => new Date(val.createdAt).getMonth() == month.id && val.status != "EnCours").map((manifeste, count) => {
                                                            colis = 0
                                                            deps = 0
                                                            price = 0
                                                            coliInvoice = 0
                                                            due = 0
                                                            depense = 0
                                                            comission = 0
                                                            litige = 0
                                                            budget = 0
                                                            manifeste.depenses?.filter(dep => dep.typedepense?.ismanifeste == 1).map( depese => comission += depese.mount)
                                                            manifeste.depenses?.filter(dep => dep.typedepense?.ismonth == 0 && dep.brancheId != process.env.REACT_APP_BRANCHE_KMPL).map( depese => {
                                                                depense += depese.mount
                                                            })

                                                            manifeste.depenses.filter(val => val.typedepense?.ismonth == 1 && val.typedepense?.ismanifeste == 0).map( depese => {
                                                                deps += depese.mount
                                                             })
                                                            

                                                            manifeste.manifestecolis?.map((manifestecoli) => {
                                                                
                                                                due += (manifestecoli?.invoices?.length == 0) ? manifestecoli.price : 0
                                                                manifestecoli?.invoices.map((invoice) =>{
                                                                    litige += invoice.litige || 0
                                                                    due += (invoice.due > 0 && invoice.isDue == 1) ? invoice.due : 0
                                                                })
                                                                price += manifestecoli.price
                                                            })

                                                            let totalKmpl = 0;
                                                            manifeste.budgets.map((budge) => {
                                                                if(budge.brancheId == process.env.REACT_APP_BRANCHE_KMPL)
                                                                    totalKmpl = budge.mount
                                                            })
                                                            

                                                            sommeman += price - comission - depense - litige - totalKmpl - due - deps
                                                            sommemanT += price - comission - depense - litige - totalKmpl - due - deps
                                                        })


                                                        return(<>
                                                            <tr>
                                                                <td align="center" style={{ fontWeight : "600"}}>{ month?.label }</td>
                                                                <td align="center">{ sommeman != 0 ? sommeman : "" }</td>
                                                            </tr>
                                                        </>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-7 ps-1">
                                        <table className="table_css -border-bottom col-12">
                                            <tbody>
                                                <tr>
                                                    <td align="center" style={{ fontWeight : "800"}}>Sortie</td>
                                                    <td align="center" style={{ fontWeight : "800"}}>Montant</td>
                                                </tr>

                                                {
                                                    this.state.depenses?.map((manifeste) =>{
                                                        return(
                                                            manifeste.depenses?.map((depense) => {
                                                                sommedep += depense.mount
                                                                return(
                                                                    <tr>
                                                                        <td className="ms-5">{ depense.typedepense?.type }</td>
                                                                        <td align="right">{ depense.mount }</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                    
                                    <div className="col-md-5 pe-1">
                                        <table className="table_css -border-bottom col-12">
                                            <tbody>
                                                <tr>
                                                    <td  colSpan={2} style={{ fontWeight : "100"}}>*</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" style={{ fontWeight : "800"}}>Total</td>
                                                    <td align="center" style={{ fontWeight : "800"}}>{ sommemanT }</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" colSpan={2} style={{ fontWeight : "800"}}>Situation relle caisse</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-7 ">
                                        <table className="table_css -border-bottom col-12">
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} style={{ fontWeight : "100"}}>*</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontWeight : "100"}}>*</td>
                                                    <td align="right" style={{ fontWeight : "800"}}>{ sommedep }</td>
                                                </tr>
                                                <tr>
                                                    <td align="right" colSpan={2} style={{ fontWeight : "800"}}>{ sommemanT - sommedep}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default ReportGestioncaisse;
