import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class   BudgetForm extends Component{

    state = {
        id : null,
        brancheId : null,
        manifesteId : null,
        mount : "",
        description : "",
        error : "",
        showform : false,

        title : "",
        branches : [],
        budgets : [],
        btn  : false
    }

    componentDidMount(){
        if(this.props.formData)
        this.showForm(this.props.formData)
        this.getBudgets()
        this.getBranches()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getBudgets(){
        let request = await axios.get('/budgets/manifesteId/' + this.props.formData?.manifesteId);
        if(request.status === 200)
            this.setState({budgets : request.data })
    }

    async getBranches(){
        let data = await axios.get('/branches')
        if(data.status == 200)
            this.setState({ branches : data.data })
    }

    storeBudget = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })
        

        var data = {
            manifesteId : this.props.formData?.manifesteId,
            mount : this.state.mount,
            description : this.state.description,
            brancheId : this.state.brancheId
        }

        var request = this.state.id ? axios.put('budgets/'+this.state.id, data) : axios.post('budgets', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn,
                mount : "",
                description : "",
                brancheId : null
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.setState({ showform : false })
            this.getBudgets()
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    showForm=(formData)=>{
        this.setState({
            showform : !this.state.showform,
            id: formData?.id ,
            manifesteId: formData?.manifesteId ,
            description: formData?.description ,
            mount: formData?.mount ,
            brancheId: formData?.brancheId ,
            title : formData?.id ? "Modifier le buget" : "Alouer un budget",
            error : "",
        })
    }

    deleteBudget = () => {
        axios.delete("/budgets/" + this.state.id).then((res) => {
            toast(res.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.setState({ showform : false })
            this.getBudgets()
        }).catch((err) => {
            this.setState({
                error : err.response?.data?.msg
            })
        })
    }
    
    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }
    
    render(){

        let optionBranches = []
        this.state.branches.map((branche) => {
            optionBranches.push({
                label : branche.region + " " + branche.city,
                value : branche.id
            })
        })


        let budgets = 
        this.state.budgets.map((budget, count) => {
            return(
                <tr>
                    <td><Link to="#" onClick={ () => this.showForm(budget)}>{ count+1 }</Link></td>
                    <td><Link to="#" onClick={ () => this.showForm(budget)}>{ budget.branch?.region } { budget.branch?.city }</Link></td>
                    <td><Link to="#"  onClick={ () => this.showForm(budget)}> { budget.mount > 0 ? budget.mount : "" }$</Link></td>
                    <td>{ budget.manifeste?.ref_manifeste }</td>
                    <td>{ budget.description } </td>
                    <td>
                        <small>{ new Date(budget.createdAt).toLocaleDateString("en-GB") }</small>
                        
                    </td>
                 </tr>
            )
        })

        return(
            <div>
                <div>{ this.state.title}</div>
                <form onSubmit={this.storeBudget} className={ (this.handleCheck("alouer budget") == true && !this.state.showform) ? "row" : "d-none"}>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className={"col-md-12 mt-2 mt-2 "}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Branche <span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionBranches.filter(option => 
                                            this.state.brancheId != null && this.state.brancheId === option.value)
                                    }
                                    required={true} 
                                    defaultValue={ this.state.brancheId }
                                    placeholder={ "Selectionner*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                brancheId : select.value,
                                            })
                                        }
                                    }
                                    options={optionBranches}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 mt-2   mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Montant<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="mount"  value={this.state.mount} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Description</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="description"  value={this.state.description} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>
 
                    <div className="d-end">
                        {/* <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => this.deleteBudget()} disabled={ this.state.btn }><i className="bi bi-trash"></i> Supprimer</button> */}
                        <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="bi bi-check"></i> Sauvegarder</button>
                    </div>
                </form>

                <div className={"tabs__content pt-30 js-tabs-content "}>
                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                        <div className="overflow-scroll scroll-bar-1">
                        <table className="table_css -border-bottom col-12">
                            <thead className="bg-light-2">
                                <tr  style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                    <th>#</th>
                                    <th>Branche</th>
                                    <th>Montant</th>
                                    <th>Manifeste</th>
                                    <th>Description</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                { budgets }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                
        </div>
        )
    }
}
export default BudgetForm;
