import axios from "axios";
import React, {Component} from "react";
import Loading from "../components/Loading";
import SocietyForm from "../components/SocietyForm";
import { Link } from "react-router-dom";
import Head from "../partials/Head";
import Profil from "./Profil";
import { toast } from "react-toastify";
import Budgetvehicle from "./Budgetvehicle";

class Society extends Component{

    state = {
        isModal : false,
        isModalArchive : false,
        loading : false,
        formData : {},
        create : false,
        branches : [],
        society : {},
    }

    componentDidMount(){
        this.getBranches();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getBranches(){
        let request = await axios.get("/societies")
        if(request.status == 200)
            this.setState({ branches : request.data, loading : true})
    }

    showModal = (branche =  null) => {
        if(!this.state.isModal)
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        this.setState({
            isModal : !this.state.isModal,
            formData : branche,
        })
        if(branche == 1)
        this.getBranches()
    }

    delete = (branche) =>{
        axios.delete("branches/" + branche).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getBranches()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        return(
            <div className="dashboard__content bg-light-2">
                           
                <div className="breadcrumbs">
                    <Head title={'Configuration des branches'} url={'/settings/'} logo={'/logo192.png'} />
                    <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                        <div className="col-auto">

                            <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Configuration des branches'}</h1>
                            <div className="text-15 text-light-1">{ "Branche de l'agence" }</div>

                        </div>
                    </div>
                </div>
                <div className="container">
                        { 
                            !this.state.loading && (<Loading /> )
                        }
                    <div className="row">
                        <div className="col-md-5">
                            <ddiv className="row ms-1">
                            {
                                this.state.branches.map((branche) => {
                                    return (
                                        <div className="card  shadow bg-white" style={{  backgroundColor : "#fff" }}>
                                            <div className="card-header">
                                                <h4 className="card-titl">{ branche.country}</h4>
                                                <p className="text-muted mb-0">{ branche.city } { branche.region } { branche.city } </p>
                                            </div>{/*end card-heade*/}
                                            <div className="card-body bg-white">
                                            <div className="row justify-between items-end ">
                                                <div className="col-auto">
                                                    <img src={ branche.logo}  className="rounded-circle img-fluid rounded rounded-4"  width={( branche.logo) && ("200px")} />
                                                </div>

                                                <div className="col-auto">
                                                    <p>{ branche.tel } { branche.email }</p>
                                                    <p>{ branche.municipality }</p>
                                                </div>
                                            </div>
                                            
                                            <div className="text-end">
                                                <Link to="#" onClick={ () => this.showModal(branche)} className="border p-1">Suivant</Link>
                                            </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="col-auto d-end text-end mb-5">  
                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-15"></i> Nouvelle branche</button>
                                </div>
                            </div>
                            </ddiv>
                        </div>
                        <div className="col-md-7">
                        {
                            (this.state.loading == true && this.state.isModal) && (
                                <SocietyForm  formData={ this.state.formData} showModal={this.showModal}  />
                            )
                        }
                        {
                             this.handleCheck("afficher profil") == true && (
                                <div>
                                    <div className="card  shadow bg-white">
                                        <div className="card-body"><Profil /></div>
                                    </div>
                                    <div className="card  shadow bg-white">
                                        <div className="card-body"><Budgetvehicle /></div>
                                    </div>
                                </div>
                             )
                        }
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        )
    }
}
export default Society;