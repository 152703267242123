import axios from "axios";
import React, {Component} from "react";
import ColiForm from "../components/ColiForm";
import Loading from "../components/Loading";
import Head from "../partials/Head";
import { Link } from "react-router-dom";
import InvoiceDesign from "../components/InvoiceDesign";
import { toast } from "react-toastify";

class Coli extends Component{

    state = {
        isModal : false,
        formData : null,
        clientId : null,
        manifesteId : null,
        colis : [],
        colis_ : null,
        manifestes : [],
        colisCount : [],
        clients : [],
        colisInvoice : [],
        formDataInvoice : null,
        due : 0,
        ref_invoice : 0,
        priceColi : 0,
        type : "Cash",
        statusColi : "EnStock",
        colForm : 'd-none',
        currentPage : 1,
        column : "",
        value : "",
        error : "",
        colTable : '',
        loading : false,
        isClient : false,
        isManifeste : false,
        isRecherche : false,
        isInvoiceModal : false
    }

    componentDidMount(){
        this.getColis();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getColis(status = null,page = 1){
        this.setState({
            loading : true
        })
        if(this.state.column)
        {
            let request = await axios.get('/colis/$' + this.state.column + '$/' + this.state.value+"/" + page);
            if(request.status === 200)
                this.setState({colis : request.data, isModal : false, loading : false})
            // request = await axios.get('/colisq/$' + this.state.column + '$/' + this.state.value+"/");
            // if(request.status === 200)
            //     this.setState({colis_ : request.data})
        }
        else{
            status = status ? status : "EnStock"
            let request = await axios.get('/colis/status/' + status+"/" + page);
            if(request.status === 200)
                this.setState({colis : request.data, isModal : false, loading : false})
            request = await axios.get('/colisq/status/' + status+"/");
            if(request.status === 200)
                this.setState({colis_ : request.data })

            
            request = await axios.get('/colis/count/status');
            if(request.status === 200)
                this.setState({colisCount : request.data})
        }
    }

    showModal = (coli = null, update = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : coli == 1 ? { } :  coli,
        })
        if(coli && coli?.id && update == null){
            this.showModalInvoice(coli, "BORDEREAU")
            this.getColis()
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    delete = (coli) =>{
        // if (window.confirm("Cette action est irreversible!") == true) {
            axios.delete("colis/" + coli).then((response) => {
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
                this.getColis()
            }).catch((error)=>{})
        //   }
    }

    changeStatus = (status)  => {
        this.getColis(status)
        this.setState({
            currentPage : 1,
            statusColi : status,
            isRecherche : false,
            column : ""
        })
    }

    showModalInvoice = (val, type, column="$colis.id$") =>{
        let data = {}
        if(val && val != 1)
        {
            data = {
                id : val.id,
                ref_number : type == "FACTURE" ? val?.ref_invoice : val.ref_coli,
                type : type,
                column : column
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        if(val == 1)
            this.getColis()
    }


    showModalInvoic = (val, type, column) =>{
        
        let data = null
        if(val && val != 1)
        {
            data = {
                id : val?.id,
                manifestecoliId : val?.manifestecolis[0]?.id ,
                invoiceId :  null,
                ref_number : val?.ref_invoice,
                due : val?.due,
                column : column,
                type : type
            }
            this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        }
        if(val == 1)
                this.getColis()
    }

    changePage = (page) =>{
        this.setState({ currentPage : parseInt(page)})
        this.getColis(this.state.statusColi, parseInt(page))
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        const PAGINATION = parseInt(process.env.REACT_APP_PAGINATE)
        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        let userId = user?.id
        let detailColi = this.state.statusColi == "EnStock" ? " en attente dans le stock" : this.state.statusColi == "Paye" ? "  payés enregisté sur facture" : " enregistés sur manifeste a credit"

        let activate = "", countColis = 0;
        let status = 
        [{ val :  'EnStock', title : "Coli en attente dans le stock"}, { val : "Manifeste", title : "Coli enregisté sur manifeste a credit"},  { val : 'Paye', title : "Coli payé enregisté sur facture"}].map((statut, count) => {
            activate = ""
            if(this.state.statusColi == statut.val) activate = " is-tab-el-active";
            countColis = this.state.colisCount.filter(val => val.status == statut.val)
            countColis = countColis.length== 1 ? countColis[0].count : 0
            return(
                <div className="col-auto" key={ statut.val}>
                    <button onClick={ () => this.changeStatus(statut.val)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)} title={statut.title}> { statut.val } ({ countColis })</button>
                </div>
            )
        })

        let buttons = [], elements = [], count = 0;
        this.state.colisCount.filter(val => val.status == this.state.statusColi).map((val, valcount) => {
            for (let index = 0; index <= val.count; index += PAGINATION) {
                count += 1
                elements.push(count)
            }
        })
        
        buttons = 
        elements.map((element) => {
            return(
                <div className="col-auto">
                    <div type="button" onClick={ () => this.changePage( element  ) } className={ element  == this.state.currentPage ? "size-30 flex-center rounded-full bg-dark-2 text-white" : "size-30 flex-center rounded-full bg-light-2"}>{ element }</div>
                </div>
            )
        })

        let coliTotal = 0, priceTotal = 0
        let colis =
        this.state.colis.map((coli, count) => {
            coliTotal += parseInt(coli.stock) + parseInt(coli.expedie)
            priceTotal += parseFloat(coli.price)
            let price = 0, quantity = 0
            coli.manifestecolis.map((val) => {
                quantity += val.quantity
                price += this.state.statusColi == "EnStock" ? coli.price - val.price : val.price 
            })
            // if(quantity == 0)
            // quantity = coli.quantity - quantity
            return(
                <tr key={ coli.id }>
                    <td>{ (count + (this.state.currentPage * PAGINATION)+1)-PAGINATION} </td>
                    <td><Link to={"/profile/" + coli.client?.id}>{ coli.client?.name }</Link></td>
                    { (<td><Link to="#" onClick={ () => this.showModalInvoice(coli, "BORDEREAU", "$colis.id$")}>{ coli.ref_coli } <small className={ coli.destination?.id == process.env.REACT_APP_ID_BK ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-red-1-05 text-red-1" : coli.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"} style={{ fontSize : 8}}>{/* coli.status */} { coli.destination?.city } </small></Link></td>) }
                    { this.state.statusColi == "Paye"  && (<td>
                        {
                            coli.invoices?.sort((a, b) => a.ref_invoice > b.ref_invoice ? 1 : -1).map((invoice) => {
                                 return(
                                    <Link onClick={ () => invoice.due > 0 ? this.showModalInvoic(coli, "FACTURE", "$colis.id$") : this.showModalInvoice(invoice, "FACTURE", "$invoices.id$")}>{ invoice?.ref_invoice } <small className={ coli.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> <span className={ invoice?.type == "Dette" && ("text-danger")}> { invoice?.type } { invoice?.due > 0 ? invoice?.due +"$" : "" } </span> </small></Link>
                                )
                            })
                        }
                    </td> )}
                    {/* { this.state.statusColi == "EnStock" && (<td><span type="button" onClick={ () => this.showModalInvoice(coli, "BORDEREAU", "$colis.id$")}>{ quantity > 0 ? quantity + coli.stock :  coli.expedie + coli.stock }</span></td>)} */}
                    {
                        coli.manifestecolis?.length > 0 ?
                                <>
                                    { this.state.statusColi == "Manifeste"  &&  (<td>{ coli.manifestecolis.map((val) => { return <label htmlFor={count}><Link to={"/manifestes/" + val?.manifesteId }>{ val?.manifeste?.ref_manifeste } <small className={ coli.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}>{ val?.manifeste?.status} </small></Link>, </label>})} </td>)}
                                    { this.state.statusColi != "EnStock" && (<td><label htmlFor={count}>{ price?.toLocaleString() }</label></td>)}
                                    <td><label htmlFor={count}>{ coli.expedie } </label></td>
                                </>
                        : <>
                            { this.state.statusColi != "EnStock" && (<td><label htmlFor={count}>{ coli.price?.toLocaleString()  }</label></td>)}
                            { this.state.statusColi != "EnStock" && (<td><label htmlFor={count}>{ coli.expedie } {  coli.expedie > 0 && (<small>.{ coli?.destination?.country } { coli?.destination?.city }</small>)}</label></td>)}
                        </>
                    }
                    { this.state.statusColi == "EnStock"  && (<td><label htmlFor={count}>{ coli.stock }</label></td>)}
                    <td><label htmlFor={count}>{ coli.colidetails.map((colidetail) => { return (<span>{ colidetail.quantity}{ colidetail.unite?.name} { colidetail.article?.name}, </span>)  }) }</label></td>
                    <td>
                        <div className={"dropdown js-dropdown js-actions-" + count + "-active"}>
                            <div className="dropdown__button d-flex items-center rounded-4 text-blue-1 bg-blue-1-05 text-14 px-15 py-5" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="js-dropdown-title">Actions</span>
                                <i className="fa fa-chevron-down text-7 ml-10"></i>
                            </div>
 
                            <ul className="dropdown-menu">
                            {
                                coli.invoices?.map((invoice) => {
                                    return(
                                        <li><Link onClick={ () => this.showModalInvoice(invoice, "FACTURE", "$invoices.id$")}>Reçu { invoice?.ref_invoice } <small className={ coli.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> <span className={ invoice?.type == "Dette" && ("text-danger")}> { invoice?.type } { invoice?.due > 0 ? invoice?.due +"$" : "" } </span> </small></Link></li>
                                    )
                                })
                            }
                                <li><a className="dropdown-item" href="#" onClick={ () => this.showModalInvoice(coli, "BORDEREAU", "$colis.id$")}>Bordereau</a></li>
                                {((coli.coli_count == 1) && this.handleCheck("modifier coli") == true && coli.status == "EnStock" && coli.ref_user == userId ) && (<li><a className="dropdown-item" href="#" onClick={() => this.showModal(coli, 1)}>Editer</a></li>)}
                                {((coli.coli_count == 1) &&  this.handleCheck("supprimer coli") == true && coli.status == "EnStock" && coli.ref_user == userId  || (coli.coli_count == 1) &&  this.handleCheck("supprimer coli") == true && this.handleCheck("admin") == true && coli.status == "EnStock" ) && (<li><a className="dropdown-item text-danger" onClick={ () => this.delete(coli.id)} href="#">Supprimer</a></li>)}
                            </ul>
                        </div>
                    </td>
                </tr>
            )
        })
        
        return(
         
                    
                    <div className="dashboard__content bg-light-2">
                           
                            <div className="breadcrumbs">
                                <Head title={'Liste colis'} url={'/colis/'} logo={'/logo192.png'} />
                                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                                    <div className="col-auto">

                                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Colis ' + this.state.statusColi }</h1>
                                        <div className="text-15 text-light-1">{ 'Liste des colis ' + detailColi }</div>

                                    </div>
                                    
                                    <div className="col-auto">
                                        {
                                            this.handleCheck("ajouter coli") == true && (
                                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white bg-sidebar" onClick={() => this.showModal(null)}><i className="fa fa-plus text-15"></i> Nouveau coli</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                                
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs row">
                                <div className="row y-gap-20 justify-between items-end">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            { status }
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className=" text-primary">{ coliTotal?.toLocaleString() }qté / { priceTotal?.toLocaleString() }$ <small className="ms-5" style={{ fontSize : 12+"px"}}>Total ( { this.state.colis_ ? ((this.state.colis_?._stock + this.state.colis_?._expedie)?.toLocaleString())+"qté / " + (this.state.colis_?._price?.toLocaleString())+"$" : "" })</small> </div>
                                    </div>
                                    <div className="col-auto">
                                        <form className="row form">
                                            <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                    <option></option>
                                                    <option value="ref_coli">par ref coli</option>
                                                    <option value="client.name">par client</option>
                                                    {/* <option value="manifeste.ref_manifeste">par ref manifeste</option> */}
                                                </select>
                                            </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <input type="text" name="value" value={ this.state.value} onChange={(select) => { this.setState({ value : select.target.value }) }} />
                                            </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <button type="button" className="btn btn-sm btn-primary" onClick={ () => { this.getColis()}}>Recherche</button>
                                            </div>
                                        </form>
                                    </div>

                                    {/* <div className={ this.state.statusColi == "EnStock" ? "col-auto" : "d-none" }>
                                        <Link to="#" onClick={ () => this.showFacture()} ><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-plus"></i> Recu client</span></Link>
                                    </div> */}
                                    {/* <div className={ (this.state.statusColi == "Manifeste" && this.handleCheck("ajouter manifeste") == true) ? "col-auto" : "d-none" }>
                                        <Link to="#" onClick={ () => this.showModalManifeste()} ><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-plus"></i> Manifeste</span></Link>
                                    </div> */}

                                </div>
                            
                                <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                        <div className="overflow-scroll scroll-bar-1">
                                        <table className="table_css -border-bottom col-12" style={{ marginBottom : "150px"}}>
                                            <thead className="bg-light-2">
                                                <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                                    <th>#</th>
                                                    <th>Client</th>
                                                    { (<th>Réf Bordereau</th>) }
                                                    { (this.state.statusColi == "Paye" || this.state.isRecherche) && (<th>Réf fac</th>) }
                                                    { this.state.statusColi == "Manifeste" && (<th>Réf man</th>) }
                                                    { this.state.statusColi != "EnStock" && (<th>Montant($)</th>)}
                                                    {/* { this.state.statusColi == "EnStock" && (<th>Qté.T</th>)} */}
                                                    { this.state.statusColi != "EnStock" && (<th>Expédiés</th>)}
                                                    { this.state.statusColi == "EnStock" && (<th>Stock</th>)}
                                                    <th>Contenus</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { colis }
                                            </tbody>
                                        </table>
                                        </div>

                                        <div className="" style={{ marginTop : "-120px" }}>
                                            <div className="row justify-between">
                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage > 2 ? this.state.currentPage - 1 : 1)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-left text-12"></i>
                                                    </button>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="row x-gap-20 y-gap-20 items-center">

                                                    { buttons.length> 0 ?  buttons : ""}

                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage < buttons.length ? this.state.currentPage + 1 : buttons.length)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-right text-12"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                  

                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                        <ColiForm
                            isModal={this.state.isModal}
                            formData={this.state.formData}
                            showModal={this.showModal}
                            getColis={this.getColis}
                        />

                        

                    <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />

                        </div>

      
                


        )
    }
}
export default Coli;
