import axios from "axios";
import React, {Component} from "react";
import StaffForm from "../components/StaffForm";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import Head from "../partials/Head";
import { toast } from "react-toastify";

class Client extends Component{

    state = {
        isModal : false,
        formData : null,
        staffs : [],
        profileId : null,
        isRecherche : false,
        staffcount : null,
        currentPage : 1,
        loading : false
    }

    componentDidMount(){
        this.setState({ profileId : process.env.REACT_APP_ID_CLIENT})
        this.getStaffs();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getStaffs(page = 1 ){
        this.setState({
            loading : true
        })
        if(this.state.column)
        {
            let request = await axios.get('/staffs/$' + this.state.column + '$/' + this.state.value + "/"+ page);
            if(request.status === 200)
                this.setState({staffs : request.data, loading : false})
        }
        else{
            let id = process.env.REACT_APP_ID_CLIENT
            var staffs = await axios.get('/staffs/$userprofile.profileId$/' + id + "/"+ page);
            if(staffs.status === 200)
                this.setState({staffs : staffs.data, loading : false})
            staffs = await axios.get('/staffscount/' + id)
            if(staffs.status === 200)
                this.setState({ staffcount : staffs.data[0] })

        }
    }

    showModal = (user = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : user,
        })
        if(user && !user.id)
            this.getStaffs()
    }

    changePage = (page) =>{
        this.setState({ currentPage : parseInt(page)})
        this.getStaffs( parseInt(page))
    }

    delete = (user) =>{
        axios.delete("users/" + user).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getStaffs()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        
        const PAGINATION = parseInt(process.env.REACT_APP_PAGINATE)
        let buttons = [], elements = [], count = 0;
        for (let index = 0; index <= this.state.staffcount?.count; index += PAGINATION) {
            count += 1
            elements.push(count)
            
        }
        
        buttons = 
        elements.map((element) => {
            return(
                <div className="col-auto">
                    <div type="button" onClick={ () => this.changePage( element  ) } className={ element  == this.state.currentPage ? "size-30 flex-center rounded-full bg-dark-2 text-white" : "size-30 flex-center rounded-full bg-light-2"}>{ element }</div>
                </div>
            )
        })

        var staffs =
        this.state.staffs.sort((a, b) => a.name > b.name ? 1 : -1).map((user, count) => {
            if(this.state.profileId == process.env.REACT_APP_ID_CLIENT){
                return(
                    <tr>
                        <td>{ count+1}</td>
                        <td><Link className="dropdown-item" to={"/profile/" + user.id}>{ user.name }</Link></td>
                        <td><a  href={ 'tel:'+user.tel }>{ user.tel }</a> <a  href={ 'mail:'+user.email }>{ user.email }</a> </td>
                        <td>{ user.adress }</td>
                        <td>
                            <div className={"dropdown js-dropdown js-actions-" + count + "-active"}>
                                <div className="dropdown__button d-flex items-center rounded-4 text-blue-1 bg-blue-1-05 text-14 px-15 py-5" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="js-dropdown-title">Actions</span>
                                    <i className="fa fa-chevron-down text-7 ml-10"></i>
                                </div>
    
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to={"/profile/" + user.id}>Profil</Link></li>
                                    { (this.handleCheck("modifier client") == true ) && (<li><a className="dropdown-item" href="#" onClick={() => this.showModal(user)}>Editer</a></li>)}
                                    { (this.handleCheck("supprimer client") == true ) && (<li><a className="dropdown-item text-danger" onClick={ () => this.delete(user.id)} href="#">Supprimer</a></li>)}
                                </ul>
                            </div>
                            
                        </td>
                    </tr>
                )
            }
        })
        return(
         
                    
                    <div className="dashboard__content bg-light-2">
                           
                            <div className="breadcrumbs">
                                <Head title={'Liste des clients'} url={'/clients/'} logo={'/logo192.png'} />
                                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                                    <div className="col-auto">

                                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Clients'}</h1>
                                        <div className="text-15 text-light-1">{ 'Liste des clients' }</div>

                                    </div>
                               
                                    <div className="col-auto">
                                        {
                                            (this.handleCheck("ajouter client") == true) && (
                                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-15"></i> Nouveau client</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                                
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs">
                                <div className="row y-gap-20 justify-between items-end ">
                                    <div className="col-auto">
                                        
                                    </div>

                                    <div className="col-auto">
                                        {/* { this.handleCheck("afficher profil") == true && (<Link to="/profiles"><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-user"></i> Profile</span></Link>)} */}
                                        <div className="col-auto">
                                            <form className="row form">
                                                <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                        <option></option>
                                                        <option value="name">par nom</option>
                                                        <option value="tel">par telephone</option>
                                                        <option value="country">par pays</option>
                                                        <option value="city">par ville</option>
                                                        <option value="adress">par adresse</option>
                                                    </select>
                                                </div>
                                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                    <input type="text" name="value" onChange={(select) => {  (this.setState({ value : select.target.value })) }} />
                                                </div>
                                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={ () => { this.getStaffs()}}>Recherche</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="tabs__content pt-30 js-tabs-content">
                                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                        <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-3 -border-bottom col-12"  style={{ marginBottom : "150px"}}>
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>Num</th>
                                                    <th>Nom</th>
                                                    <th>Contact</th>
                                                    <th>Adresse</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { staffs }
                                            </tbody>
                                        </table>
                                        </div>

                                        <div className="" style={{ marginTop : "-120px" }}>
                                            <div className="row justify-between">
                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage > 2 ? this.state.currentPage - 1 : 1)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-left text-12"></i>
                                                    </button>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="row x-gap-20 y-gap-20 items-center">

                                                    { buttons.length> 0 ?  buttons : ""}

                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage < buttons.length ? this.state.currentPage + 1 : buttons.length)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-right text-12"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                        

                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                        <StaffForm
                            isModal={this.state.isModal}
                            formData={this.state.formData}
                            profileId={this.state.profileId}
                            profile={"Client"}
                            showModal={this.showModal}
                        />

                        </div>

      
                


        )
    }
}
export default Client;
