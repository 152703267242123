import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   BudgetvehicleForm extends Component{

    state = {
        id : null,
        mount : "",
        error : "",
        vehicles : [],
        vehicleId : null,
        isAll : false,
        title : "",
        branches : [],
        brancheId : null,
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    async getBranches () {
        var branches = await axios.get('branches');
        if(branches.status == 200){
            this.setState({
                branches : branches.data
            })
        }
    };
    async getVehicless () {
        var vehicles = await axios.get('vehicles');
        if(vehicles.status == 200){
            this.setState({
                vehicles : vehicles.data
            })
        }
    };

    storeProfile = () =>{
        this.setState({
            btn : !this.state.btn
        })
        var data = {
            isAll : this.state.isAll,
            mount : this.state.mount,
            brancheId : this.state.brancheId,
            vehicleId : this.state.vehicleId,
        }

        var request = this.state.id ? axios.put('budgetvehicles/'+this.state.id, data) : axios.post('budgetvehicles', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn,
                isAll : false,
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(1)
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form();
        this.getBranches()
        this.getVehicless()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            mount: this.props.formData ? this.props.formData.mount : "",
            vehicleId: this.props.formData ? this.props.formData.vehicleId : "",
            brancheId: this.props.formData ? this.props.formData.brancheId : "",
            title : this.props.formData ? "Modifier budget expédition " : "Configurer budget",
            error : "",
        })
    }

    delete = (profile) =>{
        if(window.confirm("cette action est irreversible") == true){
            axios.delete("budgetvehicles/" + profile).then((response) => {
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.props.showModal(1)
            }).catch((error)=>{})
        }
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val == item.name)
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size=""
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Body>
                    <div>{ this.state.title}</div>
                    <form>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className={ this.state.id ? "d-none" : "mt-2" }>
                        <label htmlFor="isAll" className="h6 font-monospace">Pour tous les vehicules<input type="checkbox" id="isAll" placeholder=""  onChange={ () => { this.setState({ isAll : !this.state.isAll})} } name="isAll"  value={this.state.isAll} /></label>
                    </div>
                    <div className="col-12">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Montant</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="mount"  value={this.state.mount} id="nom-id-icon" />
                            </div>
                        </div>

                        <div className={ this.state.isAll ? "d-none" : "form-group has-icon-left" }>
                            <label htmlFor="nom-id-icon">Vehicule</label>
                            <div className="position-relative mt-1">
                                <select className="form-control form-sm" placeholder=""  onChange={this.handleInput} name="vehicleId"  value={this.state.vehicleId}>
                                    <option key="1">.................</option>
                                    {
                                        this.state.vehicles.map((vehicle) => {
                                            return <option value={vehicle.id} key={vehicle.id}>{ vehicle.plaque }</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Branche</label>
                            <div className="position-relative mt-1">
                                <select className="form-control form-sm" placeholder=""  onChange={this.handleInput} name="brancheId"  value={this.state.brancheId}>
                                    <option key="1">.................</option>
                                    {
                                        this.state.branches.map((branche) => {
                                            return <option value={branche.id} key={branche.id}>{ branche.city }</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {
                    (this.handleCheck("supprimer profil") == true && this.state.id) && (
                        <a href="#" onClick={() => this.delete(this.state.id)} className="button py-5 px-5 -dark-1 bg-red-1 text-white"><i className="fa fa-trash font-15"></i> Supprimer</a>
                    )}
                    <button onClick={this.props.showModal} className="button py-5 px-5 -red-1 bg-blue-1 text-white"><i className="fa fa-corner-down-left"></i> Fermer</button>
                    {
                    (this.handleCheck("ajouter profil") == true || this.handleCheck("modifier profil") == true  && this.state.id) && (
                        <button onClick={() => this.storeProfile()} disabled={ this.state.btn } className="button py-5 px-5 py-5 -dark-1 bg-blue-1 text-white"><i className="fa fa-check"></i> Sauvegarder</button>
                    )}
                </Modal.Footer>
            </Modal>


        </div>
        )
    }
}
export default BudgetvehicleForm;
