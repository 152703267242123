import axios from "axios"
import GoogleLogin from "react-google-login"

function LoginGoogle(){
    
    const onSuccess = (res) => {
        axios.post('loginGoogle', res.profileObj).then((login) => {
            localStorage.setItem("user", JSON.stringify(login.data.data));
            localStorage.setItem("society", JSON.stringify(login.data.society ? login.data.society : {}) );
            localStorage.setItem("permissions", JSON.stringify(login.data.permissions ? login.data.permissions : []) );
            window.location.replace(process.env.REACT_APP_EVIVEMANIFESTE);

        }).catch((error) => {
            var description = localStorage.getItem("description");
            window.localStorage.clear();
            localStorage.setItem("description",  description);
        });
    }

    const onFailure = (res) => {
        console.log(res)
    }

    return(
        <div id="signInButton">
            <GoogleLogin
                clientId={process.env.REACT_APP_CLIENTID}
                redirectUri ="/"
                buttonText="Connexion"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
            />
        </div>
    )
}
export default LoginGoogle