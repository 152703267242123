export const TRANSLATIONS_EN = {
    navbar :{
        home: "Home",
        about: "About",
        service: "Service",
        portfolio: "Portfolio",
        team: "Team",
        blog : "Recent",
        contact: "Contact",
        information : "Information",
        article : "Article",
        staff : "Staff",
        documentation : "Documentation",
        logout : "Logout"
    },
    home1 : {
        welcome:"Welcome {{ name }}",
        rdc : "-<strong style='font-size : 25px'>R</strong>esearch -<strong style='font-size : 25px'>D</strong>evelopment -<strong style='font-size : 25px'>C</strong>ommunication ",
        welcomeText: "The fidelity of your idea and your project, we will follow you from the design phase of your project",
        getstarted: "Start",
        watchvideo: "Watch video",
        conception: "Project design",
        analyse: "Needs analysis",
        realisation: "Project realization",
        accompagnement: "Follow-up and accompaniment",
 
    },
    categorie : {
        categories : "Categories",
        rechercher : "Search",
        recent : "Recent",
        motcle : "Keywords",
    },
    recherche : {
        rechercher : "Search",
        resultat_de_recherche : "Search result"
    },
    about: {
        apropos_de_nous: "About us",
        apropos_de_nous_description: "Agency to support and carry out your various construction, transport, energy, infrastructure, technological projects and any other tailor-made request",
        la_conception_et_realisation: "The design and realization of innovative projects",
        la_conception_et_realisation_legende1: "We follow our customers from the first phase of the analysis of needs in their sector of activity by providing them with more clarification of how to respond to them and finally to inculcate them with more ideas to allow quality designs of the intended objective.",
        la_conception_et_realisation_legende2: "Our presence in its realization reassures the efficiency and the concretization of the design and the analyzes bring; This is how we also make ourselves available to our customers after the realization phase of the project to accompany them and follow up on the proper functioning and the proper resolution of needs in the sector concerned",
        notre_culture: "Solving your conceptual limit of your current or innovative projects is our job all the time with great satisfaction",
        equipement_personnel: "We are active in the central and eastern regions of Africa.",
        temps_repondre : "We respond to any request working in our production sector.",
        solution_proposer: "Our achievements are tailored to the demand and we respond to any request working in our production sector.",
        force_equipe: "The achievements of the projects of our clients and partners are due to our strategic team, balanced in multiculturalism, ready to face any kind of request with great and brave courage."
    },
    customerHappy: {
        satisfied_customer: "Satisfied customers",
        client_testimonial: "Any client notices our aptitude",
        projets: "Projects",
        project_testimony: "The experience of our achievements in exercises",
        hour_existence: "Realization time",
        existence_temoignage: "Time of realization of the project"
    },
    preuve : {
        title: "Concretization",
        description : "The projects carried out prove better what we provide to our customers, the quality results is our main ambition and the answer to the needs of the demand is our only pation in our daily activities.",
    },
    temoignage : {
        title : "Testimony",
        description : "Our customers trust us. Whether their campaign is over, or still in progress, our customers share their best testimonial with us, we now know our customers better than ever",
    },
    portefeuille : {
        title : "Portfolio",
        description : "Our approach to portfolio construction ... representative in Africa, The first part of the article shows the construction of portfolios as an act of proactive management"
    },
    team : {
        title : "Get to know our team",
        description : "At Securities, we know that our team is what makes us successful. But you don't have to take our word for it. Instead, read our team profiles and testimonials to get an overview of what it's like to work with us."
    },
    price : {
        title : "Our trend",
        description : "Make your project live up to its expectation is our priority in various sectors",
        petiterealisation : "Small realization",
        moyenprojet : "Medium project",
        extra : "Extra",
        mois : "month",
        etudedumilieu : "Study of the environment",
        analyseduprojet : "Project analysis",
        conceptionduprojet : "Project design",
        passassiondumarche : "Awarding of the contract",
        ideesurleprojet : "Idea on the project",
        guideetconseil : "Guide and advice",
        realisationduprojet : "Realization of the project",
        testduprojet : "Project test",
        suivispartiel : "Partial tracking",
        suivistotalduprojet : "Total project monitoring",
        analysedebesoin : "Needs analysis",
        suivisetaccompagnement : "Follow-up and accompaniment",
        grandrendezvous : "Grand rendez-vous",
        suivisduprojet : "Passassion of the market"
    },
    blog : {
        title : "Recent achievements",
        description : "We are present in various sectors and our achievements demonstrate the quality services we provide to the general public"
    },
    contact : {
        title : "Contact",
        description : "Trust us and ask us any concerns",
        localisation : "Location",
        heure : "Time",
        lundisamedi : "Monday-Saturday 8 a.m.-4 p.m.",
        envoyermessage : "Send Message"
    },
    footer :{
        description : "evivemanifeste agency for consultation, information, documentation,communication, production and intermediation, investment support, research, innovation, development and implementation of projects.",
        lieu : "Location",
        contactnous : "Contact us"
    }
};