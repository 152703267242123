import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import ArticleForm from "./ArticleForm";
import UniteForm from "./UniteForm";
import StaffForm from "./StaffForm";
import { toast } from "react-toastify";
import QuantityExpedition from "./QuantityExpedition";

class   ManifesteForm extends Component{

    state = {
        id : null,
        ref_manifeste : null,
        userId : null,
        driver_id : null,
        originId : null,
        destinationId : null,
        vehicleId : null,
        status : "EnCours",
        datesortie : null,
        coliIdex : 0,

        manifestecolis : [],
        vehicules : [],
        drivers : [],
        branches : [],
        colisTable : [],
        
        colis : [{
            id : null
        }],

        btn  : false,
        quantityColi : null,
        isQuantity : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    deleteManifesteColi = (manifestecoliId) => {
        if(manifestecoliId){
            axios.delete("manifestecolis/" + manifestecoliId).then((response) => {
                this.getColis()
                this.props.showModal(1)
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            }).catch(error => {
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }
        else
            toast("Actualisez d'abord toute la page svp!!", { theme : "light", style : { backgroundColor : "red", color : "white"}})
    }

    showQuantity = (coli = null, count) => {
        let listData = this.state.colisTable
        
        listData[count] = coli

        this.setState({
            isQuantity : !this.state.isQuantity,
            quantityColi : coli,
            colisTable : listData,
            coliIdex : count
        })
        this.addColi(coli, true)
    }

    addColi = (e, add = false) => {

        let data = this.state.colis,colisTable = this.state.colisTable
        this.setState({ colis : [], colisTable : []})

        var exist = data.filter(data => data.id == e.id).length;
        if(exist > 0){
            data = data.filter(data => data.id != e.id);
            if(add){
                data.push(e)
            }
        }
        else
            data.push(e);
        this.setState({ colis : data, colisTable : colisTable})
    }

    handleInputPermission = (e) => {
        var permissionId = this.state.permissionId;
        var permissions = this.state.permissions;
        this.setState({
            permissionId : [],
            permissions : []
        })
        var exist = permissionId.filter(data => data == e.target.name).length;
        if(exist > 0)
            permissionId = permissionId.filter(data => data != e.target.name);
        else
            permissionId.push(e.target.name);

        this.setState({
            permissionId : permissionId,
            permissions : permissions
        })
        
    }

    async getColis(){
        let request = await axios.get('/colis/status/EnStock');
        if(request.status === 200){
            let arr = [], colis = [], colisTable = this.state.status == "EnCours" ?  request.data?.sort((a, b) => a.ref_coli > b.ref_coli ? 1 : -1) : []

            this.state.manifestecolis?.map((manifestecoli) => {
                if(colisTable.filter(val => val.id  == manifestecoli.coli?.id).length == 0)
                    colisTable.push(manifestecoli.coli)
            })
            let arrayListe = (this.state.manifestecolis?.length > 0) ? colisTable : request.data
            this.state.manifestecolis?.map((manifestecoli) => {
                colis.push(manifestecoli.coli)
            })

            this.setState({  colis : colis, colisTable :(this.props.formData?.status == "EnCours" || !this.props.formData?.id) ?  arrayListe : colisTable  })
        }
    }

    async getVehicules(){
        let request = await axios.get('/vehicles');
        if(request.status === 200)
            this.setState({vehicules : request.data })
    }

    async getDriver(){
        let request = await axios.get('/staffs/$userprofile.profileId$/'+ process.env.REACT_APP_ID_DRIVER);
        if(request.status === 200)
            this.setState({ drivers : request.data })
    }

    async getBranche(){
        let request = await axios.get('/branches');
        if(request.status === 200)
            this.setState({branches : request.data })
    }

    storeManifeste = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })
        
        const data = {
            manifesteId : this.state.id,
            ref_manifeste : this.state.ref_manifeste,
            status : this.state.status,
            driver_id : this.state.driver_id ? this.state.driver_id : null,
            originId : this.state.originId ? this.state.originId : null,
            destinationId : this.state.destinationId ? this.state.destinationId : null,
            vehicleId : this.state.vehicleId ? this.state.vehicleId : null,
            datesortie : this.state.datesortie ? this.state.datesortie : null,
            // colis : this.state.colis
        }

       if(this.state.id ){
            axios.put('manifestes/'+this.state.id, data).then((response) =>{
                this.setState({
                    btn : !this.state.btn
                })
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
                this.props.showModal(1)
            }).catch((error) => {
                this.setState({
                    error : error.response?.data?.msg,
                    btn : !this.state.btn
                })
            })
        }else {
            axios.post('manifestes/', data).then((response) =>{
                this.setState({
                    btn : !this.state.btn
                })
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
                this.props.showModal(1)
            }).catch((error) => {
                this.setState({
                    error : error.response?.data?.msg,
                    btn : !this.state.btn
                })
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }

    }

    delete = () => {
        this.setState({ btn : true})
        axios.get("manifestes/" + this.state.id).then((response) => {
            if(response.data?.manifestecolis?.length == 0)
            {
                axios.delete('manifestes/'+this.state.id).then((response) => {
                    toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
                    this.props.showModal(1)
                }).catch((error)=>{
                    toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
                })
            }
            response.data.manifestecolis?.forEach(element => {
                this.deleteManifesteColi(element.id)
            });
            this.props.showModal(1)
        })
        this.setState({ btn : false})
    }
    edit=()=>  {
        this.form()
        this.getColis()
        this.getVehicules()
        this.getBranche()
        this.getDriver()
    }

    form= ()=>{
        let origin = localStorage.getItem('branche')
        origin = origin ? JSON.parse(origin) : {}
        
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            status: this.props.formData ? this.props.formData.status : null,
            ref_manifeste: this.props.formData ? this.props.formData.ref_manifeste : "COL-",
            driver_id: this.props.formData ? this.props.formData.driver_id : null,
            originId: this.props.formData ? this.props.formData.originId : origin.userprofile?.brancheId,
            destinationId: this.props.formData ? this.props.formData.destinationId : null,
            vehicleId: this.props.formData ? this.props.formData.vehicleId : null,
            manifestecolis : this.props.formData ? this.props.formData?.manifestecolis : [],
            title : this.props.formData ? "Modifier le manifeste" : "Ajouter le manifeste",
            error : "",
        })
    }
    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        let origin = "",
        optionDestinations = [],
        optionVehicles = [],
        optiondrivers = [];

        this.state.drivers.map((driver) => {
            optiondrivers.push({
                label : driver.name + ", "  + driver.tel,
                value : driver.id
            })
        })

        this.state.vehicules.map((vehicule) => {
            optionVehicles.push({
                label : vehicule.plaque + ", "  + vehicule.mark?.name,
                value : vehicule.id
            })
        })

        this.state.branches.map((branche) => {
            if(branche.id == this.state.originId)
                origin = branche.country + ", "  + branche.city
            else {
                optionDestinations.push({
                    label : branche.country + ", "  + branche.city,
                    value : branche.id
                })
            }
        })
        let enstock = 0, expedie = 0, prix = 0, enstockT = 0, expedieT = 0, prixT = 0;
        
        let colis =
        this.state.colisTable?.map((coli, count) => {
            let readonly = false, manifestecoliId = this.state.manifestecolis?.filter(man => man.coliId == coli.id)
            if(manifestecoliId?.length == 1){
                readonly = true
                manifestecoliId = manifestecoliId[0]?.id
            }
            else if(this.state.colis.filter(data => data.id == coli.id).length)
                readonly = true
            else
                manifestecoliId = false

                enstock = 0
                expedie = 0 
                prix = 0
                coli.colidetails.map(colidetail => {
                    if(colidetail.stock > 0)
                        prix += parseFloat((colidetail.price / colidetail.stock) * colidetail.expedie)
                    else prix += parseFloat(colidetail.price)
                        enstock += parseFloat(colidetail.stock)
                    expedie += parseFloat(colidetail.expedie)
                })
                enstockT += enstock
                expedieT += expedie
                prixT += prix
                return(
                <tr key={coli.id} className={ (enstock > 0 || readonly) ? "" : "d-none"}>
                    <td>{ count+1}<span > <input type="checkbox" onClick={()=>this.addColi(coli)} name={ coli.id } disabled={ true } checked={this.state.colis.filter(data => data.id == coli.id).length} id={count} className={ (this.props.formData?.status == "EnCours" || !this.props.formData?.id) ? "" : "d-none"}  /> </span> </td>
                    <td><label htmlFor={count}>{ coli.client?.name } </label></td>
                    <td><label htmlFor={count}>{ coli.ref_coli } </label></td>
                    <td><label htmlFor={count}>{ enstock }</label></td>
                    <td>
                        {
                            readonly ? 
                                <span>
                                    { expedie?.toFixed(1) }
                                    <span type="button" title="Retirer"  onClick={ () =>  this.deleteManifesteColi(manifestecoliId)}>
                                        <i className="fa fa-trash text-danger fs-4 ms-2"></i>
                                    </span>
                                </span>
                            :
                            <span type="button"  onClick={ () =>  this.showQuantity(coli, count)}>
                            { expedie?.toFixed(1) }
                            </span>
                        }
                    </td>
                    <td><label htmlFor={count}>{  prix?.toFixed(2)  }</label></td>
                    <td><label htmlFor={count}>{ coli.colidetails.map((colidetail) => { return (<span>{colidetail.expedie }/{ colidetail.quantity }{ colidetail.unite?.name} { colidetail.article?.name}, </span>)  }) }</label></td>
                </tr>
            )
        })
        let branche = localStorage.getItem("branche")
        branche = branche ? JSON.parse(branche) : null
        
        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size={this.props.formData?.count == 1 ? "sm" : this.state.colis.length > 0 ? "xl" : "lg"}
                onEntered={this.edit}
                backdrop={"static"}>
                <form onSubmit={this.storeManifeste}>
                <Modal.Body className="row">
                    <Modal.Title>{  this.props.formData?.count == 1 ? "Valider " : this.state.title} { origin } </Modal.Title>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>

                    <div className={ this.props.formData?.count != 1 ? this.state.id ? "col-md-4" : "col-md-4" : "d-none"}>
                        <label>Vehicule<span className="text-danger">*</span></label>
                        <Select
                            className="form-control form-control-sm p-0"
                            value = {
                                optionVehicles.filter(option => 
                                    this.state.vehicleId != null && this.state.vehicleId == option.value)
                            }
                            required={true} 
                            defaultValue={ this.state.vehicleId }
                            placeholder={ "Selectionner un vehicule"}
                            onChange={
                                (select) => {
                                    this.setState({
                                        vehicleId : select.value,
                                    })
                                }
                            }
                            options={optionVehicles}
                        />
                    </div>

                    <div className={ this.props.formData?.count != 1 ? this.state.id ? "col-md-4" : "col-md-4" : "d-none"} >
                        <label>Chauffeur<span className="text-danger">*</span></label>
                        <Select
                            className="form-control form-control-sm p-0"
                            value = {
                                optiondrivers.filter(option => 
                                    this.state.driver_id != null && this.state.driver_id == option.value)
                            }
                            defaultValue={ this.state.driver_id }
                            placeholder={ "Selectionner un chauffeur"}
                            onChange={
                                (select) => {
                                    this.setState({
                                        driver_id : select.value,
                                    })
                                }
                            }
                            options={optiondrivers}
                        />
                    </div>

                    <div className={ this.props.formData?.count != 1 ? this.state.id ? "col-md-4" : "col-md-4" : "d-none"} >
                        <label>Destination<span className="text-danger">*</span></label>
                        <Select
                            className="form-control form-control-sm p-0"
                            value = {
                                optionDestinations.filter(option => 
                                    this.state.destinationId != null && this.state.destinationId == option.value)
                            }
                            defaultValue={ this.state.destinationId }
                            placeholder={ "Selectionner la destination"}
                            onChange={
                                (select) => {
                                    this.setState({
                                        destinationId : select.value
                                    })
                                }
                            }
                            options={optionDestinations}
                        />
                    </div>

                    <div className={ (this.handleCheck("valider manifeste") == true && this.state.id) ? this.props.formData?.count == 1 ? "col-12" : "d-none" : "d-none"}>
                        <label>Etat de l'expédition<span className="text-danger">*</span></label>
                        <select name="status" onChange={ this.handleInput} value={ this.state.status} className="form-control form-control-small">
                            <option key="EnCours" value={"EnCours"} className={ branche?.userprofile?.branch?.id == this.props.formData?.originId ? "" : "d-none"}>EnCours</option>
                            <option key="Acredit" value={"Acredit"} className={ branche?.userprofile?.branch?.id == this.props.formData?.originId ? "" : "d-none"}>Acredit</option>
                            <option key="Arriver" value={"Arriver"} className={ branche?.userprofile?.branch?.id == this.props.formData?.destinationId ? "" : "d-none"}>Arriver</option>
                        </select>

                        {
                            (this.props.formData?.count == 1 && this.state.status == "Acredit") &&(
                                <div>
                                    <label>Date sortie</label>
                                    <input type="date" name="datesortie" value={ this.state.datesortie} onChange={ this.handleInput} className="form-control form-control-sm"/>
                                </div>
                            )
                        }
                    </div>

                    
                    <hr className="mt-1" />

                    
                    {/* <div className={ this.props.formData?.count != 1 ?"" : "d-none"}>
                        <div>Associer plus des colis <span className="text-primary">{ sommeColis}</span>colis à <span  className="text-primary">{ prixColis > 0 ? prixColis.toFixed(2) : prixColis }</span>$</div>
                    </div> */}

                    <div className={(this.props.formData?.count != 1 && this.state.id) ?"tabs__content pt-20 js-tabs-content " : "d-none"}>
                        <div className="tabs__pane -tab-item-1 is-tab-el-active row">
                            <div className={"col-md-12 overflow-scroll scroll-bar-1"}>
                                <table className="table_css -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr  style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                            <th>#<span className="text-danger">*</span></th>
                                            <th>Client</th>
                                            <th>Réf colis</th>
                                            <th title="Nombre des colis total">Stock</th>
                                            <th title="Nombre des colis expedies">A expédiés</th>
                                            <th>Prix($)</th>
                                            <th>Contenus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { colis}
                                        <tr>
                                            <td></td>
                                            <td colSpan={2}>A expedier</td>
                                            <td>{ enstockT }</td>
                                            <td>{ expedieT }</td>
                                            <td>{ prixT?.toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className={ this.state.colis.length > 0 ? ("d-none ") : "d-none"}>
                                <table className="table_css -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr>
                                            <th>Contenus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.colis?.map((col) => {
                                                return(
                                                    <tr>
                                                        <td><label>{ col.colidetails?.map((colidetai) => { return (<span>{colidetai.expedie }/{ colidetai.quantity }{ colidetai.unite?.name} { colidetai.article?.name}, </span>)  }) }</label></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div> */}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center text-danger text-small small"><small>{ this.state.error }</small></div>
                    <button onClick={this.props.showModal} type="button" className="btn btn-outline-secondary btn-sm"><i className="fa fa-corner-down-left"></i> Fermer</button>
                    { (this.props.formData?.status == "EnCours" && this.props.formData?.count != 1) && (<button type="button" onClick={ () => this.delete()} className="btn btn-sm btn-outline-danger" disabled={ this.state.btn }><i className="fa fa-trash"></i> { "Supprimer" }</button>)}
                    <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="fa fa-check"></i> { this.props.formData?.count != 1 ? "Sauvegarder" : "Valider" }</button>
                </Modal.Footer>
                </form>
            </Modal>

            <ArticleForm
                isModal={this.state.isArticle}
                showModal={this.showModalArticle}
            />

            <UniteForm
                isModal={this.state.isUnite}
                showModal={this.showModalUnite}
            />

            <QuantityExpedition
                isModal={this.state.isQuantity}
                showModal={this.showQuantity}
                formData={ this.state.quantityColi}
                coliIdex = { this.state.coliIdex}
                manifesteId = { this.state.id}
            />

            <StaffForm
                isModal={this.state.isClient}
                profile={"Client"}
                profileId={ process.env.REACT_APP_ID_CLIENT}
                showModal={this.showModalClient}
            />
            

        </div>
        )
    }
}
export default ManifesteForm;
