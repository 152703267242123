import React, {Component} from "react";
import axios from "axios";
import originalMoment from "moment";
import { Link } from "react-router-dom";
import { extendMoment } from "moment-range";
import InvoiceDesign from "../components/InvoiceDesign";
const moment = extendMoment(originalMoment);

class Dashboard extends Component{

    state = {
        invoices : [],
        branches : [],
        manifestes : [],
        formDataInvoice : null,
        isInvoiceModal : false
    }


    componentDidMount(){
        this.refreshToken()
        this.getBranches()
        this.getInvoices()
        this.getManifestes()
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
        if(!country.country)
            this.datacountryIp();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async datacountryIp(){
        try{
            var ip = await axios("https://httpbin.org/ip");
            if(ip.status === 200)
            {
                var country = await axios.get('country/' + ip.data.origin);
                if(country.status === 200)
                {
                    localStorage.setItem("country", JSON.stringify(country.data) );
                }
            }
        }catch(error){
            console.log(error);
        }
    }

    async refreshToken () {
        try{
          var token = await axios.get('token');
          if(token.status === 200)
          {
              var user = (token.data.data);
              localStorage.setItem("user", JSON.stringify(user));
          }
          else //if(token.status === 401)
          {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
          }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
        }
    }


    showModalInvoice = (invoice, colum) =>{
        
        let data = {}
        if(invoice && invoice != 1)
        {
            data = {
                id : invoice.id,
                invoiceId : invoice?.id,
                ref_number : invoice?.ref_invoice,
                due : invoice?.due,
                colum : colum,
                type : "FACTURE"
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        if(invoice == 1)
            this.getInvoices()
    }

    async getBranches(){
        const today = moment();
        let date =  moment.range(today.clone().subtract(0, "days"), today.clone())
        let start =  date.start?.format("YYYY-MM-DD")
        let end = date.end?.format("YYYY-MM-DD")

        let request = await axios.get('branches/' + start + '/' + end + '/')
        if(request.status == 200){
            this.setState({ branches : request.data })
        }
    }

    async getInvoices(){
        this.setState({
            invoices : []
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(0, "days"), today.clone())
        let start =  date.start?.format("YYYY-MM-DD")
        let end = date.end?.format("YYYY-MM-DD")
        
        let request = await axios.get('/invoicesreport/type/' + start + '/' + end + '/null');
        if(request.status === 200)
            this.setState({invoices : request.data})

    }
    

    async getManifestes(){
        this.setState({
            manifestes : [],
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(0, "days"), today.clone())
        let start =  date.start?.format("YYYY-MM-DD")
        let end = date.end?.format("YYYY-MM-DD")
        
        let request = await axios.get('/manifestes/status/' + start + '/' + end + '/null');
        if(request.status === 200)
            this.setState({manifestes : request.data })
    }

    render(){
        let mount = 0, price = 0, due = 0, prices = 0
        this.state.branches?.map((branche) => {
            branche.depenses?.map(depense => {
                mount += depense.mount || 0
            })

            branche.coli_origines?.map(coli => {
                due += coli.invoice?.due > 0 ? coli.invoice.due : 0
                price +=  coli.price ||  0
                prices += coli.price || 0
            })
        })

        let tColis_ = 0, tPrice_ = 0, tDue = 0, invoices =
        this.state.invoices.map((invoice, count) => {
             
            tColis_ += invoice.coli?.quantity
            tPrice_ += invoice.price
            tDue += invoice.due
            return(
                <tr>
                    <td>{ count+1} </td>
                    <td><Link to={"#"} onClick={ () => this.showModalInvoice( invoice, "$invoices.id$" )}>{ invoice.ref_invoice }</Link> </td>
                    <td>{ invoice.coli?.quantity }</td>
                    <td>{ invoice.price } <small className={ invoice?.due > 0 ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-red-3 text-red-2" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" }> { invoice?.type } { invoice?.due > 0 ? invoice.due : "" }</small> </td>
                    
                    <td><small>{ new Date(invoice.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })

        let colis = 0, coliInvoice = 0,  tColiInvoice = 0;
        let tColis = 0, tPrice = 0;
        let manifestes =
        this.state.manifestes.map((manifeste, count) => {
            colis = 0
            price = 0
            coliInvoice = 0

            manifeste.manifestecolis?.map((coli) => {
                colis += coli.coli.quantity
                price += coli.coli.price
                // coliInvoice +=  coli.invoice ? coli.quantity : 0
            })

            tColis += colis
            tPrice += price
            // tColiInvoice += coliInvoice
            return(
                <tr>
                    <td>{ count+1} </td>
                    <td><Link to={"/manifestes/" + manifeste?.id }>{ manifeste.ref_manifeste } </Link></td>
                    <td>{ manifeste.origine?.city } { manifeste.destination?.city }</td>
                    <td>{  colis }</td>
                    <td>{ price }</td>
                    <td><small>{ new Date(manifeste.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })

        return(
            <div className="dashboard__content bg-light-2">
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">Dashboard <i className="fa fa-truck"></i></h1>
                        <div className="text-15 text-light-1">Situation d'aujourdhui.</div>

                    </div>

                    <div className="col-auto">

                    </div>
                </div>


                <div className="row y-gap-30">

                <div className="col-xl-3 col-md-6">
                    <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto">
                        <div className="fw-500 lh-14">Caisse</div>
                        <div className="text-26 lh-16 fw-600 mt-5" title={"solde expedie " + prices + "$ - " + mount + "$ depense"}>{ prices - mount}$</div>
                        <div className="text-15 lh-14 text-light-1 mt-5">Total disponible</div>
                        </div>

                        <div className="col-auto">
                        <img src="img/dashboard/icons/1.svg" alt="icon" />
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6">
                    <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto">
                        <div className="fw-500 lh-14">Depense</div>
                        <div className="text-26 lh-16 fw-600 mt-5" title="depense">{ mount }$</div>
                        <div className="text-15 lh-14 text-light-1 mt-5">Total depenses</div>
                        </div>

                        <div className="col-auto">
                        <img src="img/dashboard/icons/2.svg" alt="icon" />
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6">
                    <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto">
                        <div className="fw-500 lh-14">Solde</div>
                        <div className="text-26 lh-16 fw-600 mt-5">{ prices }$</div>
                        <div className="text-15 lh-14 text-light-1 mt-5">Total</div>
                        </div>

                        <div className="col-auto">
                        <img src="img/dashboard/icons/3.svg" alt="icon" />
                        </div>
                    </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6">
                    <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto">
                        <div className="fw-500 lh-14">Dette</div>
                        <div className="text-26 lh-16 fw-600 mt-5">{ due }$</div>
                        <div className="text-15 lh-14 text-light-1 mt-5">Total dette</div>
                        </div>

                        <div className="col-auto">
                        <img src="img/dashboard/icons/4.svg" alt="icon" />
                        </div>
                    </div>
                    </div>
                </div>

                </div>

                <div className="row y-gap-30 pt-20 mb-50">
                
                    <div className="col-xl-6 col-md-7">
                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                        <div className="d-flex justify-between items-center">
                            <h2 className="text-18 lh-1 fw-500">Facture recente</h2>

                            <div className="">
                                <a href="/invoices" className="text-14 text-blue-1 fw-500 underline">Voir plus</a>
                            </div>
                        </div>

                        <div className="overflow-scroll scroll-bar-1 pt-30">
                                <table className="table-2 -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr>
                                            <th>#</th>
                                            <th>Réf fac</th>
                                            <th title="Nombre des colis">colis</th>
                                            <th>{  "Prix($)" }</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { invoices }
                                        <tr>
                                            <th></th>
                                            <th>Total</th>
                                            <th>{ tColis_ }</th>
                                            <th>{ tPrice_ } <small className={ tDue > 0 ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-red-3 text-red-2" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" }> { tDue }</small></th>
                                            <th></th>
                                        </tr>
                                    </tbody>
                                </table>
                                
                        </div>
                        </div>
                    </div>

                    <div className="col-xl-6 col-md-5">
                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                        <div className="d-flex justify-between items-center">
                            <h2 className="text-18 lh-1 fw-500">Recente expedition</h2>

                            <div className="">
                                <a href="/manifestes" className="text-14 text-blue-1 fw-500 underline">Voir plus</a>
                            </div>
                        </div>

                        <div className="overflow-scroll scroll-bar-1 pt-30">
                        <table className="table_css -border-bottom col-12">
                            <thead className="bg-light-2">
                                <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                    <th>#</th>
                                    <th>Manifeste</th>
                                    <th>Expédition</th>
                                    <th>Colis</th>
                                    <th>Montant($)</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                { manifestes}
                                <tr>
                                    <th></th>
                                    <th>Total</th>
                                    <th></th>
                                    <th>{ tColis }</th>
                                    <th>{ tPrice }</th>
                                    <th></th>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                    <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
                </div>
            </div>
        )
    }
}
export default Dashboard;