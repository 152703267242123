import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Head from "../partials/Head";
import InvoiceDesign from "../components/InvoiceDesign";

class Litige extends Component{

    state = {
        isModal : false,
        formDataInvoice : null,
        clientId : null,
        invoices : [],
        invoicesCount : [],
        clients : [],
        invoicesInvoice : [],
        currentPage : 1,
        due : 0,
        ref_invoice : 0,
        priceInvoice : 0,
        type : "Cash",
        typeInvoice : "Cash",
        error : "",
        colTable : '',
        loading : false,
        isRecherche : false,
        isClient : false
    }

    componentDidMount(){
        this.getInvoices();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getInvoices(status = null, page = 1){
        this.setState({
            loading : true
        })
        if(status?.column)
        {
            let request = await axios.get('/invoices/$' + status?.column + '$/' + status?.value+"/litige/"+page);
            if(request.status === 200)
                this.setState({invoices : request.data, loading : false})
        }
        else{
            status = status ? status : "Cash"
            let request = await axios.get('/invoices/type/litige/' + page);
            if(request.status === 200)
                this.setState({invoices : request.data, isModal : false, loading : false})

            request = await axios.get('/invoices/count/status/0');
            if(request.status === 200)
                this.setState({invoicesCount : request.data})
        }
    }

    showModalInvoice = ( invoice, manifestecoliId = false) =>{
        
        let data = {}
        if(invoice && invoice != 1)
        {
            data = {
                id : invoice.id,
                invoiceId : invoice?.id,
                ref_number : invoice?.ref_invoice,
                manifestecoliId : manifestecoliId,
                due : invoice?.due,
                column : "$invoices.id$",
                type : "FACTURE"
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        if(invoice == 1)
            this.getInvoices(this.state.typeInvoice)
    }

    changeType = (type)  => {
        this.getInvoices(type)
        this.setState({
            currentPage : 1,
            typeInvoice : type,
            isRecherche : false,
            column : ""
        })
    }

    changePage = (page) =>{
        this.setState({ currentPage : parseInt(page)})
        this.getInvoices(this.state.typeInvoice, parseInt(page))
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        const PAGINATION = parseInt(process.env.REACT_APP_PAGINATE)
        let activate = "", countinvoices = 0, countTotal = 0;
        let status = 
        ['Cash', 'Dette'].map((type, count) => {
            activate = ""
            if(this.state.typeInvoice == type) activate = " is-tab-el-active ";
            countinvoices = this.state.invoicesCount.filter(val => val.type == type)
            countinvoices = countinvoices.length == 1 ? countinvoices[0].count : 0
            countTotal += countinvoices
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeType(type)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)}> { type } ({ countinvoices })</button>
                </div>
            )
        })

        let buttons = [], elements = [], count = 0;
        this.state.invoicesCount.filter(val => val.type == this.state.typeInvoice).map((val, valcount) => {
            for (let index = 0; index <= val.count; index += PAGINATION) {
                count += 1
                elements.push(count)
            }
        })

        buttons = 
        elements.map((element) => {
            return(
                <div className="col-auto">
                    <div type="button" onClick={ () => this.changePage( element  ) } className={ element  == this.state.currentPage ? "size-30 flex-center rounded-full bg-dark-2 text-white" : "size-30 flex-center rounded-full bg-light-2"}>{ element }</div>
                </div>
            )
        })

        let invoices =
        this.state.invoices?.map((invoice, count) => {
            let sum = 0, coliD = null
            coliD = invoice.coli; 
            sum += invoice.coli.quantity
            return(
                <tr>
                    <td>{ (count + (this.state.currentPage * PAGINATION)+1)-PAGINATION}</td>
                    <td><Link to={"#"} onClick={ () => this.showModalInvoice(invoice)}>{ invoice.ref_invoice }</Link> </td>
                    <td>{ invoice.manifestecoli?.manifeste &&(<span><Link to={"/manifestes/" + invoice.manifestecoli?.manifeste?.id }>{ invoice.manifestecoli?.manifeste?.ref_manifeste } </Link><small type="button" onClick={ () => this.showModalInvoice(coliD.id, invoice, invoice.manifestecoli?.id)} className={ invoice.manifestecoli?.manifeste?.status == "EnCours" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : invoice.manifestecoli?.manifeste?.status == "Cloture" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> { invoice.manifestecoli?.manifeste?.status }</small>, </span>) }</td>
                    <td><Link to={"/profile/" + invoice.client?.id}>{ invoice?.client?.name }</Link></td>
                    {/* <td>{ sum }</td> */}
                    <td>{ this.state.typeInvoice == "Dette" ? <span><span className="text-danger">{ invoice.due  }</span>/ {invoice.price}</span> : invoice.price }</td>
                    <th>{ invoice.litige}</th>
                    <td>{ invoice.description}</td>
                    {/* <td>{ invoice.colis.map(coli => { return coli.colidetails.map(detail => { return <span>{ detail.quantity}{ detail.unite?.name} { detail.article?.name}, </span>}) }) }  </td> */}
                    <td><small>{ new Date(invoice.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })
        return(
         
                    
                    <div className="dashboard__content bg-light-2">
                           
                            <div className="breadcrumbs">
                                <Head title={'Liste des litiges'} url={'/litiges/'} logo={'/logo192.png'} />
                                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                                    <div className="col-auto">

                                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Remboursement sur facture'} ({countTotal})</h1>
                                        <div className="text-15 text-light-1">{ 'Liste des litiges'}</div>

                                    </div>
                                </div>
                            </div>
                                
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs row">
                                <div className="row y-gap-20 justify-between items-end">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            {/* { status } */}
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <form className="row form">
                                            <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => { this.setState({ isRecherche : !this.state.isRecherche, column : "" }); if(this.state.isRecherche){ this.getInvoices()} }}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                    <option></option>
                                                    <option value="ref_invoice">par ref facture</option>
                                                    <option value="colis.manifestecolis.manifeste.ref_manifeste">par ref manifeste</option>
                                                    <option value="ref_coli">par ref coli</option>
                                                    <option value="client.name">par client</option>
                                                </select>
                                            </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getInvoices({ column : this.state.column, value : select.target.value }))) }} />
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            
                                <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                        <div className="overflow-scroll scroll-bar-1">
                                        <table className="table_css -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                                    <th>#</th>
                                                    <th>Réf fac</th>
                                                    <th>Réf Man</th>
                                                    <th>Client</th>
                                                    {/* <th title="Nombre des invoices">Nbre coli</th> */}
                                                    <th>{ this.state.typeInvoice == "Dette" ? "Dette($)" : "Montant payé($)" }</th>
                                                    <th>Litige($)</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { invoices}
                                            </tbody>
                                        </table>
                                        </div>

                                        <div className="">
                                            <div className="row justify-between">
                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage > 2 ? this.state.currentPage - 1 : 1)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-left text-12"></i>
                                                    </button>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="row x-gap-20 y-gap-20 items-center">

                                                    { buttons.length> 0 ?  buttons : ""}

                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage < buttons.length ? this.state.currentPage + 1 : buttons.length)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-right text-12"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {
                                    this.state.loading && (<Loading /> )
                                }

                            </div>

                    <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
                             
                        </div>

                        </div>

      
                


        )
    }
}
export default Litige;
