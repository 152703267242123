import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import TypedepenseForm from "./TypedepenseForm";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StaffForm from "./StaffForm";

class   DepenseListeForm extends Component{

    state = {
        id : null,
        typedepenseId : null,
        beneficerId : null,
        manifesteId : null,
        status : null,
        coliId : null,
        mount : "",
        mountT : 0,
        description : "",
        error : "",

        title : "",
        isTypedepense : false,
        ismanifeste : false,
        isUser : false,
        typedepenses : [],
        manifestes : [],
        users : [],
        depenses : [],
        btn  : false
    }

    componentDidMount(){
        this.form()
        this.getDepenses()
        this.getTypedepenses()
        this.getUsers()
        this.getManifestes()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getManifestes(){
        let request = await axios.get('/manifestes/status/EnCours');
        if(request.status === 200)
            this.setState({manifestes : request.data })
    }

    async getDepenses(){
        let request = await axios.get('/depenses/' + this.props.formData?.column + '/' + this.props.formData?.value);
        if(request.status === 200)
            this.setState({depenses : request.data })
    }

    async getTypedepenses(){
        let data = await axios.get('/typedepenses')
        if(data.status == 200)
            this.setState({ typedepenses : data.data })
    }

    async getUsers(){
        let data = await axios.get('/users')
        if(data.status == 200)
            this.setState({ users : data.data })
    }

    showModalTypedepense = (typedepense= null) => {
        this.setState({
            isTypedepense : false,
        });
        if(typedepense == 1)
            this.getTypedepenses()
    }
    
    showModalUser = (val= null) => {
        this.setState({
            isUser : false,
        });
        if(val != null && !val?.id){
            this.setState({ beneficerId : val})
            this.getUsers()
        }
    }

    storeTypedepense = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })
        
        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}

        if((this.state.mountT - this.state.mount ) >= 0 || this.state.id)
        {
            var data = {
                typedepenseId : this.state.typedepenseId,
                beneficerId : this.state.beneficerId ,
                coliId : this.state.coliId ,
                mount : this.state.mount,
                description : this.state.description,
                brancheId : branche.userprofile?.brancheId,
                manifesteId : this.state.manifesteId
            }

            var request = this.state.id ? axios.put('depenses/'+this.state.id, data) : axios.post('depenses', data);
            request.then((response) =>{
                this.setState({
                    btn : !this.state.btn,
                    mountT : this.state.id ? 0 : this.state.mountT - this.state.mount,
                    beneficerId : null
                })
                this.editDepense()
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
                this.getDepenses()
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }else
        return this.setState({ btn : false, error : "La commission est inferieur au montant du bénéficiaire" })
    }

    form=()=>{
        let montant = 0
        this.state.depenses.map((depense, count) => {
            montant += depense.mount
        })
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            beneficerId: this.props.formData ? this.props.formData.beneficerId : null,
            status: this.props.formData ? this.props.formData.status : null,
            manifesteId: this.props.formData ? this.props.formData.manifesteId : null,
            description: this.props.formData ? this.props.formData.description : "",
            mount: this.props.formData ? this.props.formData.mount : null,
            mountT : montant,
            typedepenseId: this.props.formData ? this.props.formData.typedepenseId : null,
            error : "",
        })
    }

    editDepense = (depense = null) => {
        if(this.state.status == "EnCours" || this.handleCheck("admin")){
            this.setState({
                id: depense ? depense.id : null,
                coliId: depense ? depense.coliId : null,
                beneficerId: depense ? depense.beneficerId : null,
                manifesteId: depense ? depense.manifesteId : null,
                description: depense ? depense.description : "",
                mount: depense ? depense.mount : 0,
                typedepenseId: depense ? depense.typedepense?.id : null,
                error : "",
            })
        }
    }

    deleteDepense = (depense) => {
        if(this.state.status == "EnCours" || this.handleCheck("admin")){
            axios.delete("/depenses/" + depense).then((res) => {
                this.getDepenses()
                toast(res.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
                let montant = 0
                this.state.depenses.map((depense, count) => {
                    montant += depense.mount
                })
                this.setState({ mountT : this.state.id ? 0 : montant })
                this.editDepense()
            }).catch((err) => {
                console.log("error")
            })
        }
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }
    
    render(){

        let optionUsers = [{ value : null, label : "Nouveau bénéficiaire"}]
        this.state.users.filter(user => user.userprofile?.profileId != process.env.REACT_APP_ID_CLIENT && user.userprofile?.profileId != process.env.REACT_APP_ID_SUPER_USER).map((user) => {
            optionUsers.push({
                label : user.name + " (" + user.userprofile?.profile?.name+")",
                value : user.id
            })
        })

        let optioncolis = []
        this.props.formData?.manifestecolis?.map((manifestecoli) => {
            optioncolis.push({
                label : manifestecoli?.coli?.ref_coli + " (" + manifestecoli?.coli?.client?.name+")",
                value : manifestecoli?.coli?.id
            })
        })

        let optionManifestes = []
        this.state.manifestes.map((manifeste) => {
            optionManifestes.push({
                label : manifeste.ref_manifeste + ", " + manifeste.vehicle?.plaque + " " + manifeste.vehicle?.mark?.name + ", " + manifeste?.destination?.country + " " + manifeste?.destination?.city +", " + manifeste?.driver?.name,
                value : manifeste.id
            })
        })

        let optionTypedepenses = [{ value : null, label : "Nouveau type de depense"}]
        this.state.typedepenses.filter(val => val.ismanifeste ).map((typedepense) => {
            optionTypedepenses.push({
                label : typedepense.ismanifeste ? typedepense.type + " (manifeste)" : typedepense.type,
                value : typedepense.id,
                ismanifeste : typedepense.ismanifeste
            })
        })

        let depenses = 
        this.state.depenses.map((depense, count) => {
            return(
                <tr>
                    <td>{ this.handleCheck("modifier depense") == true ? <button type="button" onClick={() => this.editDepense(depense) }>{ count+1 }</button> :  count+1 }</td>
                    <td><Link to={"#"}>{ depense.coli?.ref_coli } { depense.coli?.client?.name }</Link></td>
                    <td>{ this.handleCheck("modifier depense") == true ? <button type="button" onClick={() => this.editDepense(depense) }>{ depense.mount > 0 ? depense.mount : "" }</button> : depense.mount > 0 ? depense.mount : "" }</td>
                    <td>{ depense.typedepense?.type }</td>
                    <td>{ depense.beneficer?.name }</td>
                    <td>{ depense.description } { 
                        <span>
                            <Link to={"/manifestes/" + depense.manifeste?.id}>{ depense.manifeste?.ref_manifeste }</Link> 
                            <Link to={"/invoices/" + depense.invoice?.id}>{ depense.invoice?.ref_invoice }</Link>
                            <small><Link to={"/profile/" + depense.invoice?.client?.id}>{ depense.invoice?.client?.name }</Link></small> 
                            <Link to={"/depenses/" + depense.depense?.id}>{ depense.depense?.ref_depense }</Link> 
                            <small><Link to={"/profile/" + depense.depense?.beneficer?.id}>{ depense.depense?.beneficer?.name }</Link></small>
                        </span> }</td>
                    <td>
                        <small>{ new Date(depense.createdAt).toLocaleDateString("en-GB") }</small>
                        {
                            (this.state.depenses.length == count + 1 && this.handleCheck("supprimer depense") == true) && (
                                <button type="button" onClick={ () => this.deleteDepense(depense.id)} className="btn btn-danger btn-sm">Annuler</button>
                            )
                        }
                    </td>
                 </tr>
            )
        })


        return(
            <div>
                <form onSubmit={this.storeTypedepense} className={ (this.props.formData?.brancheId && this.handleCheck("ajouter depense") == true)? "row" : "d-none"}>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
             
                    <div className="col-md-6">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Montant commission<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="mountT"  value={this.state.mountT} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-6"}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Type<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionTypedepenses.filter(option => 
                                            this.state.typedepenseId != null && this.state.typedepenseId === option.value)
                                    }
                                    required={true} 
                                    defaultValue={ this.state.typedepenseId }
                                    placeholder={ "Selectionner commission*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                isTypedepense : select.value == null ? true : false,
                                                typedepenseId : select.value,
                                                ismanifeste : select.ismanifeste
                                            })
                                        }
                                    }
                                    options={optionTypedepenses}
                                />
                            </div>
                        </div>
                    </div>

                    

                    <div className={"col-md-4 "}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Bordereau<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optioncolis.filter(option => 
                                            this.state.coliId != null && this.state.coliId === option.value)
                                    }
                                    required={true} 
                                    defaultValue={ this.state.coliId }
                                    placeholder={ "Selectionner bordereau*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                coliId : select.value
                                            })
                                        }
                                    }
                                    options={optioncolis}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-4"}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Bénéficiaire<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionUsers.filter(option => 
                                            this.state.beneficerId != null && this.state.beneficerId === option.value)
                                    }
                                    required={true} 
                                    defaultValue={ this.state.beneficerId }
                                    placeholder={ "Selectionner un beneficiaire*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                isUser : select.value == null ? true : false,
                                                beneficerId : select.value,
                                            })
                                        }
                                    }
                                    options={optionUsers}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Montant<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="mount"  value={this.state.mount} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className={(!this.props.formData?.manifesteId && this.state.ismanifeste) ?"col-md-12 mt-2 mt-2 " :"d-none"}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Manifeste encours<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionManifestes.filter(option => 
                                            this.state.manifesteId != null && this.state.manifesteId === option.value)
                                    }
                                    defaultValue={ this.state.manifesteId }
                                    placeholder={ "Selectionner le manifeste*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                manifesteId : select.value
                                            })
                                        }
                                    }
                                    options={optionManifestes}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Description</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="description"  value={this.state.description} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>
 
                    <div className="text-end">
                        {
                            (this.state.id) && (
                                <button type="button" onClick={ () => this.deleteDepense()} className="btn btn-sm btn-outline-primary mx-1">Vider</button>
                            )
                        }
                        {
                            (this.state.id && this.handleCheck("supprimer depense") == true) && (
                                <button type="button" onClick={ () => this.deleteDepense(this.state.id)} className="btn btn-danger btn-sm mx-1">Supprimer</button>
                            )
                        }
                        <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="fa fa-plus"></i> { this.state.id ? "Modifier" : "Ajouter" }</button>
                    </div>
                </form>

                <div className={"tabs__content pt-30 js-tabs-content "}>
                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                        <div className="overflow-scroll scroll-bar-1">
                        <table className="table_css -border-bottom col-12">
                            <thead className="bg-light-2">
                                <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                    <th>Num</th>
                                    <th>Bordereau</th>
                                    <th>Montant($)</th>
                                    <th>Type</th>
                                    <th>Beneficiaire</th>
                                    <th>Description</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                { depenses }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                
            <TypedepenseForm
                isModal={this.state.isTypedepense}
                showModal={this.showModalTypedepense}
            />
            <StaffForm
                isModal={this.state.isUser}
                profile={"Personne externe"}
                profileId={ process.env.REACT_APP_ID_EXTERNE}
                showModal={this.showModalUser}
            />
        </div>
        )
    }
}
export default DepenseListeForm;
