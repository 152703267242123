import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Head from "../partials/Head";
import { toast } from "react-toastify";

class Budget extends Component{

    state = {
        brancheId : null,
        budgets : [],
        soldeCount : [],
        currentPage : 1,
        branches : [],
        isRecherche : false,
        loading : false
    }

    componentDidMount(){
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            brancheId : brancheId?.userprofile.brancheId
        })
        this.getBudgets(brancheId.userprofile?.brancheId);
        this.getBranches();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getBudgets(val, page=1){
        this.setState({
            loading : true
        })
        if(val?.column)
        {
            let request = await axios.get('/budgets/$' + val?.column + '$/' + val?.value  + '/' + page);
            if(request.status === 200)
                this.setState({budgets : request.data, loading : false})
        }
        else{
            let brancheId = localStorage.getItem('branche')
            brancheId = brancheId ? JSON.parse(brancheId) : {}
            if(!val)
                val = brancheId.userprofile?.brancheId    

            var request = await axios.get('budgets/brancheId/' + val  + '/' + page);
            if(request.status === 200)
                this.setState({budgets : request.data, loading : false})
             var request = await axios.get('budgets/count/brancheId');
            if(request.status === 200)
                this.setState({soldeCount : request.data, loading : false})
        }
    }

    changeBranche = (branche = null)  => {
        this.getBudgets(branche)
        this.setState({
            currentPage : 1,
            brancheId : branche,
            isRecherche : false,
            column : ""
        })
    }

    deleteBudget = (budget) => {
        axios.delete("/budgets/" + budget).then((res) => {
            toast(res.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getBudgets()
        }).catch((err) => {
            console.log("error")
        })
    }
    
    changePage = (page) =>{
        this.setState({ currentPage : parseInt(page)})
        this.getBudgets(this.state.brancheId, parseInt(page))
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        const PAGINATION = parseInt(process.env.REACT_APP_PAGINATE)
        let brancheId, user = localStorage.getItem('user')
        user = user ?  JSON.parse(user):{}
        
        let arrayListe = this.state.branches

        let countbudgets = 0 ,  activate = "", branches = 
        arrayListe.map((branche, count) => {
            activate = ""
            if(this.state.brancheId == branche.id || branche.id == null && this.state.brancheId == null) activate = " is-tab-el-active";
            countbudgets = this.state.soldeCount.filter(val => val.brancheId == branche.id)
            countbudgets = countbudgets.length == 1 ? countbudgets[0].count : 0
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeBranche(branche.id)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + count}>{ branche.city } ({ countbudgets })</button>
                </div>
            )
        })

        let buttons = [], elements = [], count = 0;
        this.state.soldeCount.filter(val => val.brancheId == this.state.brancheId).map((val, valcount) => {
            for (let index = 0; index <= val.count; index += PAGINATION) {
                count += 1
                elements.push(count)
            }
        })

        buttons = 
        elements.map((element) => {
            return(
                <div className="col-auto">
                    <div type="button" onClick={ () => this.changePage( element  ) } className={ element  == this.state.currentPage ? "size-30 flex-center rounded-full bg-dark-2 text-white" : "size-30 flex-center rounded-full bg-light-2"}>{ element }</div>
                </div>
            )
        })


        let budgets = 
        this.state.budgets.map((budget, count) => {
            brancheId = false
            if(user && user.id == budget.userId) brancheId = true

            return(
                <tr>
                    <td>{ (count + (this.state.currentPage * PAGINATION)+1)-PAGINATION}</td>
                    <td><Link to={"#"}>{ budget.branch?.city }</Link></td>
                    <td>{ budget.mount > 0 ? budget.mount : "" }</td>
                    <td><Link to={"/manifestes/" + budget.manifeste?.id}> {budget.manifeste?.ref_manifeste } </Link> </td>
                    <td>{ budget.manifeste?.vehicle?.plaque }</td>
                    <td>{ budget.description } </td>
                    <td>
                        <small>{ new Date(budget.createdAt).toLocaleString() }</small>
                    </td>
                 </tr>
            )
        })
        
        return(
            <div className="dashboard__content bg-light-2">
                           
            <div className="breadcrumbs">
                <Head title={'Recapitulatif budgets'} url={'/budgets/'} logo={'/logo192.png'} />
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Mouvement budgets'}</h1>
                        <div className="text-15 text-light-1">{ 'Budget aloué par expédition' }</div>
                    </div>
                    <div className="col-auto">
                    
                </div>
                </div>
            </div>

            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    
                    <div className="row y-gap-20 justify-between items-end ">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                { this.handleCheck("afficher branche") != true && ( branches )}
                            </div>
                        </div>
                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })} className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="manifeste.vehicle.plaque">par plaque</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getBudgets({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>
                        

                        {/* <div className="col-auto">
                        { this.handleCheck("ajouter type de depense") == true && (<Link to="#" onClick={ () => this.showModalTypedepense()} ><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-plus"></i> Type depense</span></Link>)}
                        </div> */}
                    </div>

                    <div className={"tabs__content pt-30 js-tabs-content "}>
                        <div className="tabs__pane -tab-item-1 is-tab-el-active">
                            <div className="overflow-scroll scroll-bar-1">
                            <table className="table-3 -border-bottom col-12">
                                <thead className="bg-light-2">
                                    <tr>
                                        <th>#</th>
                                        <th>Branche</th>
                                        <th>Montant($)</th>
                                        <th>Manifeste</th>
                                        <th>Véhicule</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { budgets }
                                </tbody>
                            </table>
                            </div>

                            <div className="">
                                <div className="row justify-between">
                                    <div className="col-auto">
                                        <button onClick={() => this.changePage(1)} className="button -blue-1 size-40 rounded-full border-light">
                                            <i className="fa fa-chevron-left text-12"></i>
                                        </button>
                                    </div>

                                    <div className="col-auto">
                                        <div className="row x-gap-20 y-gap-20 items-center">

                                            { buttons.length> 0 ?  buttons : ""}

                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <button onClick={() => this.changePage( this.state.budgets.length > 0 ? (this.state.budgets.length / PAGINATION) + 1 : PAGINATION)} className="button -blue-1 size-40 rounded-full border-light">
                                            <i className="fa fa-chevron-right text-12"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
            {
                this.state.loading && (<Loading /> )
            }
      </div>
        )
    }
}
export default Budget;
