import React from "react";
import { Helmet } from 'react-helmet-async';

export default function Head(MetaData){
     const { title, logo, url } = MetaData
    return (
    <Helmet>
            <meta name="description" content= { title }  />
            <title>{ title } | evivemanifeste</title>
            <link rel="icon" type="image/png" sizes="32x32" href={ logo } />
            <link rel="apple-touch-icon" sizes="180x180" href={ logo } />
            <link rel="icon" type="image/png" sizes="32x32" href={ logo } />
            <link rel="icon" type="image/png" sizes="16x16" href={ logo } />
            <link rel="shortcut icon" type="image/x-icon" href={ logo } />
            <link rel="canonical" href={ url} />
            <meta name="msapplication-TileImage" content={ logo } />
            <meta name="description" content={ title +", evivemanifeste, Source de l'information et de la documentation ainsi que la Recherche du developpement de la communication"} />
            <link rel="url" href={ process.env.REACT_APP_API_EVIVEMANIFESTE + url } />
            <meta property="og:title" content="EviveGet" />
            <meta property="og:url" content={ process.env.REACT_APP_API_EVIVEMANIFESTE + url } />
            <meta property="og:site_name" content={ title} />
            <meta property="og:image" content={  logo  } />
            <meta property="og:description" content={ title +", evivemanifeste, Source de l'information et de la documentation ainsi que la Recherche du developpement de la communication"} />
        </Helmet>
    );
}