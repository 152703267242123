import axios from "axios";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import { useEffect, useState } from "react";

const getNotifications = async () => {
  var user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : false;

  var chats = await axios.get("notifications");
  if (chats.status == 200) {
    this.setState({
      chats: chats.data,
      loading: true,
    });
  }
};

const logout = (e) => {
  axios
    .post("logout")
    .then((re) => {
      console.log("disconnect");
    })
    .catch((erro) => {
      console.log("error");
    });
  window.localStorage.clear();
  window.location.replace(process.env.REACT_APP_EVIVEMANIFESTE);
};

export const Footer = () => {
  const [datafetch, datafetchSet] = useState([])

  useEffect(() => {
    async function fetchBranches() {
      let response = await fetch( process.env.REACT_APP_API_EVIVEMANIFESTE + '/branches')
      response = await response.json()
      datafetchSet(response)
    }

    fetchBranches()
  }, [])

  const { t, i18n } = useTranslation();

  var user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : false;

    let branches = [], brancheDate = datafetch ? datafetch[0] : {}
    
    let dataListe = datafetch?.map((data) => {
      branches.push(<div><a href="#">{ data.city } { data.region }</a> <small><a href={"tel:" + data.tel}>{ data.tel }</a></small></div>)
      // if(brancheDate.id != data.id )
      // {
        return(
          <div key={data.id}>
            <div className="text-16 fw-500">{ data.city } { data.region }</div>

              <a href={"tel:" + data?.tel} className="text-18 fw-500 text-blue-1">{ data?.tel}, </a>
            
              <a href={"mailto:" + data?.email} className="text-18 fw-500 text-blue-1">{ data?.email }</a>
          </div>
        )
      // }
    })
  return (
    <footer className="footer -type-1">
    <div className="container">
      <div className="pt-60 pb-60">
        <div className="row y-gap-40 justify-between xl:justify-start">
          <div className="col-xl-2 col-lg-4 col-sm-6">
            <h5 className="text-16 fw-500 mb-30">Contactez nous</h5>

            <div className="mt-30">
              <div className="text-14 mt-30">Téléphone</div>
              <a href={"tel:" + brancheDate?.tel} className="text-18 fw-500 text-blue-1 mt-5">{ brancheDate?.tel}</a><br />
              <a href={"tel:" + brancheDate?.tel1} className="text-18 fw-500 text-blue-1 mt-5">{ brancheDate?.tel1}</a><br />
              <a href={"tel:" + brancheDate?.tel2} className="text-18 fw-500 text-blue-1 mt-5">{ brancheDate?.tel2}</a><br />
            </div>

            <div className="mt-0">
              <div className="text-14 mt-0">EMail</div>
              <a href={"mailto:" + brancheDate?.email} className="text-18 fw-500 text-blue-1 mt-0">{ brancheDate?.email }</a><br />
            </div>
            
          </div>

          <div className="col-xl-2 col-lg-4 col-sm-6">
            <h5 className="text-16 fw-500 mb-30">Pages</h5>
            <div className="d-flex y-gap-10 flex-column">
              <a href="/">Accueil</a>
              <a href="/about">Apropos</a>
              <a href="/contacts">Contact</a>
            </div>
          </div>

          {/* <div className="col-xl-2 col-lg-4 col-sm-6">
            <h5 className="text-16 fw-500 mb-30">Trafic</h5>
            <div className="d-flex y-gap-10 flex-column">
              { branches }
            </div>
          </div> */}

          <div className="col-xl-2 col-lg-4 col-sm-6">
            <h5 className="text-16 fw-500 mb-30">Trafic</h5>
            <div className="">
              { dataListe}
            </div>
          </div>

        </div>
      </div>

      <div className="py-20 border-top-light">
        <div className="row justify-between items-center y-gap-10">
          <div className="col-auto">
            <div className="row x-gap-30 y-gap-10">
              <div className="col-auto">
                <div className="d-flex items-center">
                  ©{ new Date().getFullYear() } La victoire Express All rights reserved.
                </div>
              </div>

              <div className="col-auto">
                <div className="d-flex x-gap-15">
                  <a href="http://evivedigital.net" className="text-primary">EviveDigital</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="row y-gap-10 items-center">

              <div className="col-auto">
                <div className="d-flex x-gap-20 items-center">
                  <a href="#"><i className="fa fa-facebook text-14 text-blue"></i></a>
                  <a href="#"><i className="fa fa-youtube text-14 text-danger"></i></a>
                  <a href="#"><i className="fa fa-twitter text-14 text-blue-1"></i></a>
                  <a href="#"><i className="fa fa-instagram text-14 text-danger"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  );
};
