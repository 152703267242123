import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import InvoiceDesign from "../components/InvoiceDesign";

class Recu extends Component{

    state = {
        colis : [],
        formDataInvoice : null,
        isInvoiceModal : false
    }

    componentDidMount(){
        this.getColis()
    }

    async getColis(){
        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        let request = await axios.get('/colis/$colis.client_id$/' + user?.id);
            if(request.status === 200)
                this.setState({colis : request.data })
    }

    showModalInvoice = (val, type) =>{
        let data = {}
        if(val && val != 1)
        {
            data = {
                id : val.id,
                ref_number : val.ref_coli,
                due : val?.invoice?.due,
                type : type
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
    }

    render(){
        
        let colis = this.state.colis?.map((coli) => {
            let border =  (coli.status == "Manifeste") ? "primary" : coli.status == "EnStock" ? "danger" : "success"
            return(
                <div className="col-lg-4 col-sm-6 p-1 m-0">
                    <div className={"featureIcon -type-1 -hover-shadow  bg-white p-1 border border-1 border-" + border}>
                        <div className="d-flex justify-center">
                            { <Link to={"#"} onClick={ () => this.showModalInvoice(coli, "FACTURE")}>{ coli.invoice?.ref_invoice } </Link>} 
                        </div>

                        <div className="text-center mt-30">
                            <h4 className="text-18 fw-500"><Link to={"#"} onClick={ () => this.showModalInvoice(coli, "REÇU")}>{ coli.ref_coli } </Link></h4>
                            <p className="text-15 mt-10">{ coli.quantity }article{coli.quantity > 1 ? "s" : ""} à { coli.price }$ <span className={ coli.invoice?.type == "Dette" && ("text-danger")}> { coli.invoice?.type } { coli.invoice?.due > 0 ? coli.invoice?.due +"$" : "" } </span></p>
                        </div>
                        <p className="text-end"><small>{ new Date(coli.createdAt).toLocaleDateString("en-GB") }</small></p>
                    </div>
                </div>
            )
        })
        return(
            <div className="dashboard__content bg-light-2">
                
                <div className="container">
                    <div className=" items-end">
                        <div className="col-auto">
                            <h1 className="text-25 lh-14 fw-500 text-primary">Mes reçus</h1>
                        </div>
                    </div>
                    <div className="row y-gap-40 justify-between pt-50">
                        { colis }
                    </div>
                    <div className="py-30 px-30 rounded-4 shadow-3">
                        <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
                    </div>
                </div>
                     
            </div>
        )
    }
}
export default Recu