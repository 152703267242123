import axios from "axios";
import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import  FrenchNumbersToWords  from "french-numbers-to-words"
import "../assets/css/invoice.css" 
import { toast } from "react-toastify";
import EmpruntForm from "./EmpruntForm";
import TotalColiForm from "./TotalColiForm";

class   InvoiceDesign extends Component{

    state = {
        id : null,
        coli : null,
        branches : [],
        price : 0,
        value : "",
        due : 0,
        litige : 0,
        manifestecoliId : null,
        litigeRequest : 0,
        error : "",
        description : "",
        bordereau : false,
        isInvoiceModal : false,
        formDataInvoice : null,
        numberInvoice : [1],

        isEmprunt : false,
        isTotal_coli : false,
        formData : null,

        title : "",
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getBranches(){
        let request = await axios.get("/branches")
        if(request.status == 200){
            this.setState({ branches : request.data })
        }
    }

    async getColi(id = null){
        this.setState({ error : ""})
        let colum = id ? this.props.formData?.column : "$colis.ref_coli$"
        id = id ? id : this.state.value
        
        if(id){
            try{
                let request = await axios.get('/coli/' + colum +"/" + encodeURIComponent(id))
                if(request.status == 200){
                    this.setState({ description : request.data?.invoices[0]?.description, due : request.data.price - request.data?.invoice?.due || 0, ref_number : request.ref_coli, coli : request.data })// litige : request.data?.invoices[0]?.litige || 0, litigeRequest : request.data?.invoices[0]?.litige || 0 })
                    // this.setState({ id: request.data.id, ref_number : request.ref_coli, coli : request.data, litige : request.data?.invoice?.litige || 0 })
                }
            }catch(error){
                this.setState({ error : error.response?.data?.msg})
            }
        }
    }

    storeInvoice = (val = null) =>{

        this.setState({
            btn : !this.state.btn,
            error : "",
            bordereau : val == 1 ? true : this.state.bordereau
        })

        let totalPrix = 0
        this.state.coli?.invoices?.map(invoice => {
            totalPrix += invoice.price + invoice.litige
        })

        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}

        var data = {
            coliId : this.state.coli.id,
            client_id : this.state.coli?.client_id,
            due : (this.state.price - totalPrix - parseFloat(this.state.due) - parseFloat(this.state.litige || 0)) || 0,
            manifestecoliId : this.props.formData?.manifestecoliId ? this.props.formData?.manifestecoliId : this.state.manifestecoliId,
            brancheId : branche.userprofile?.brancheId,
            description : this.state.description,
            price : parseFloat(this.state.due)// this.state.price > 0 &&( this.state.price)
        }
        if(data.due <= 0)
            data.due = 0

        if(data.manifestecoliId)
        {
            if((this.state.litige - this.state.litigeRequest) > 0 && this.props.formData?.manifestecoliId)
            {
                data.litige = (this.state.litige - this.state.litigeRequest)
                axios.put("/manifestecolis/" + this.props.formData?.manifestecoliId, { litige : this.state.litige}).then(res => {}).catch(error => {})
            }

            // if(data.litige > 0)
            //     data.price = parseFloat(data.price) - parseFloat(data.litige)
            
            let request = this.state.id ? axios.put('invoices/'+this.state.id, data) : axios.post('invoices', data);
            request.then((response) =>{
                this.props.formData.ref_number = response.data?.ref_invoice
                this.setState({
                    btn : !this.state.btn
                })
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
                
                this.props.showModal(1)
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
                toast(error.response.data.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        }
        else{
            toast("Une erreur s'est produite! \nActualiser de nouveau la page svp", { theme : "light", style : { backgroundColor : "red", color : "white"}})
               
        }
    }

    edit=()=>  {
        this.setState({ 
            numberInvoice : this.props.formData?.type == "FACTURE" ? [1] : [1], 
            id : this.props.formData?.invoiceId, 
            coli : null,
            litige : 0,
            due : this.props.formData?.due || 0,
            error : ""
        })
        this.getColi(this.props.formData?.id)
        this.getBranches()
    }

    loadingInvoice = () => {
        this.setState({ bordereau : false, manifestecoliId : this.state.coli?.manifestecolis[0]?.id})
        this.props.formData.type = "FACTURE"
        this.props.formData.ref_number = null
    }

    showModalInvoice = (val = 1) =>{
        
        let data = null;
        if(val == 1){
            data = {
                id : this.state.coli.id,
                ref_number : this.state.coli.ref_coli,
                type : "BORDEREAU",
                colum : "$colis.id$"
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
    }

    showModalEmprunt = (val = null) => {
        let formdata = {
            id : this.state.coli?.id,
            emprunt : this.state.coli?.emprunt
        }
        this.setState({
            formData : val ? null : formdata,
            isEmprunt : !this.state.isEmprunt
        })
        if(val == 1)
            this.getColi(this.props.formData?.id)
    }

    showModalTotal = (val = null) => {
        let formdata = {
            id : this.state.coli?.id,
            total_coli : this.state.coli?.total_coli
        }
        this.setState({
            formData : val ? null : formdata,
            isTotal_coli : !this.state.isTotal_coli
        })
        if(val == 1)
            this.getColi(this.props.formData?.id)
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        
        let user = localStorage.getItem("user")
        user = user ? JSON.parse(user) : null
        let rows = []

        for (let index = 0; index < (5 - this.state.coli?.colidetails?.length); index++) {
            rows.push(<tr><td>*</td><td></td><td></td><td>-</td></tr>)
        }
        let totalQuantity = 0, totalPrice = 0, totalDue = 0, totalPrix = 0, totalPrixB = 0, totalDueB = 0;
        let branche = this.state.branches ? this.state.branches[0] : {}
        let brancheId = false, brancheDate = localStorage.getItem('branche')
        brancheDate = brancheDate ?  JSON.parse(brancheDate):{}
        
        if(brancheDate && brancheDate.userprofile?.brancheId == this.state.coli?.origine?.id || brancheDate && brancheDate.userprofile?.brancheId == this.state.coli?.destination?.id) 
            brancheId = true
 
        let datetime = this.props.formData?.type == "BORDEREAU" ? this.state.coli?.createdAt :  this.state.coli?.invoices ? this.state.coli?.invoices[0]?.createdAt ? this.state.coli?.invoices[0]?.createdAt : new Date() : new Date()
        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                
                onEntered={this.edit}
                backdrop={"static"}>
                <form >
                <Modal.Body>
                    <div  className="row invoice_print" ref={el => (this.componentRef = el)}>
                {
                    this.state.numberInvoice?.map( val => {
                        totalPrice = 0 /// add to refresh mount
                        totalPrixB = 0
                        return(
                        <div className=" mb-5 pb-5">
                            <div className=" border border-dark rounded-4 p-1"  style={{ fontSize : 12, borderRadius : 16 }}>
                                <h5 className="text-center"><small>AGENCE </small> { branche?.name }</h5>
                                <div className=" d-flex mt-2" style={{ fontSize : 12}}>
                                    <div className="flex-shrink-1">
                                        <div className="col-12 ms-1">
                                            <strong>{ this.state.coli?.origine?.city }</strong>
                                            {/* <div>{ this.state.coli?.origine?.region } { this.state.coli?.origine?.municipality } { this.state.coli?.origine?.neighborhood } { this.state.coli?.origine?.avenue } { this.state.coli?.origine?.other_adress }</div> */}
                                            <div style={{ fontSize : 9}}>+{ this.state.coli?.origine?.tel }</div>
                                            <div  style={{ fontSize : 9}}>+{ this.state.coli?.origine?.tel1 }</div>
                                            <div  style={{ fontSize : 9}}>+{ this.state.coli?.origine?.tel2 }</div>
                                            <div  style={{ fontSize : 9}}>{ this.state.coli?.origine?.email }</div>
                                        </div>
                                    </div>
                                    <div className="w-100 text-">
                                        <strong>
                                            <div>
                                                {/* <small>NUMERO { this.state.coli?.origine?.social_reason } { this.state.coli?.origine?.trade_registry } { this.state.coli?.origine?.nif } { this.state.coli?.origine?.activity_sector } { this.state.coli?.origine?.tax_center }</small> */}
                                                <small style={{ fontSize : 13}}>NUMERO { this.state.coli?.origine?.social_reason }{ this.state.coli?.origine?.trade_registry }{ this.state.coli?.origine?.nif } </small>
                                                {/* <div className={this.props.formData?.type == "BORDEREAU" ?"" : "d-none"}>{ "BON D'ENTREE CAISSE" || this.props.formData?.type}</div> */}
                                            </div>
                                        </strong>
                                        <div className=" text-center"> 
                                            <strong className="fs-5 border border-dark rounded-4 "  style={{ fontSize : 11}}>{ this.state.bordereau ? "BON D'ENTREE CAISSE" :this.props.formData?.type || "FACTURE"}</strong>
                                            <div className={ this.state.bordereau ? "d-none" : "" }  style={{ fontSize : 11}}><strong>N°<span className="text-primary">{ this.props.formData?.ref_number }</span> </strong></div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-1">
                                        <div className="col-4">
                                            <div className="text-center"> <strong  style={{ fontSize : 10}}> { this.state.coli?.destination?.city }</strong></div>
                                            {/* <div>{ this.state.coli?.destination?.region } { this.state.coli?.destination?.municipality } { this.state.coli?.destination?.neighborhood } { this.state.coli?.destination?.avenue } { this.state.coli?.destination?.other_adress }</div> */}
                                            <div style={{ fontSize : 9}}>+{ this.state.coli?.destination?.tel }</div>
                                            <div style={{ fontSize : 9}}>+{ this.state.coli?.destination?.tel1 }</div>
                                            <div style={{ fontSize : 9}}>+{ this.state.coli?.destination?.tel2 }</div>
                                            {/* <div> { this.state.coli?.destination?.email }</div> */}
                                        </div>
                                    </div>
                                </div> 


                                <div className={ (!this.state.bordereau) ?"" : "d-none"}>
                                    

                                    <div className="row y-gap-20 justify-between items-end">
                                        <div className="col-auto">
                                        <span className={ this.state.coli ? "fw-500 " : "d-none"}><span className={ this.state.coli ? "fw-500 " : "d-none"}>Client :</span>  <abbr className="" ><span className="text-primary">{ this.state.coli?.client?.name }</span> <small style={{ fontSize : 9+"px"}}>{ this.state.coli?.client?.gender?.slice(0, 3) }</small> <small style={{ fontSize : 11 +"px"}}>({ this.state.coli?.ref_coli })</small> <abbr>  { this.state.coli?.destination?.city } <span className="text-secondary">.</span></abbr></abbr></span>
                                            <div className={ this.props.formData?.id ? "d-none" : "row justify-end mb-2  ps-3 table_action"}>
                                                <div className="col-auto p-0">
                                                    <input type="text" name="value" placeholder="Num. bordereau" onChange={(select) => { select.target.value?.length >= 2 && (this.setState({ value : select.target.value })) }} />
                                                </div>
                                                <div className="col-auto p-0">
                                                    <Link to="#" className="btn btn-sm m-0 rounded-100 px-2 text-center text-12 fw-500 bg-blue-1-05 text-blue-1" type="button" onClick={() => this.getColi()}>Rechercher</Link> <span className="text-danger">{ this.state.error}</span>
                                                </div>
                                            </div>
                                            {/* <div className="padding-100">...........................................................</div> */}
                                        </div>
                                        <div className="col-auto">
                                            <small className="">{ new Date(this.state.coli?.createdAt).toLocaleDateString("en-GB") }</small>
                                        </div>
                                    </div>
                                    <div className="">
                                        <table className="table_css_invoice">
                                            <thead>
                                                <tr>
                                                    <th style={{ width : 20+"%"}}>Qté</th>
                                                    <th style={{ width : 50+"%"}}>Articles</th>
                                                    <th style={{ width : 15+"%"}}>Prix.U</th>
                                                    <th style={{ width : 15+"%"}}>Prix.T</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.coli?.colidetails?.map((colidetail) => {
                                                        totalQuantity += (parseFloat(colidetail.stock) + parseFloat(colidetail.expedie))
                                                        totalPrice += colidetail.price
                                                        return(
                                                            <tr className="padd-0" key={colidetail.id}>
                                                                <td className="padd-0">{ (parseFloat(colidetail.stock) + parseFloat(colidetail.expedie)) }{ colidetail.unite?.name }</td>
                                                                <td className="padd-0">{ colidetail.article?.name }</td>
                                                                <td className="padd-0">{ (parseFloat(colidetail.price) / (parseFloat(colidetail.stock) + parseFloat(colidetail.expedie))).toFixed(2) }$</td>
                                                                <td className="padd-0">{ colidetail.price?.toFixed(2) }$</td>
                                                            </tr>
                                                        )
                                                    })
                                                }{
                                                    rows
                                                }

                                                <tr><td colSpan={4}>.</td></tr>
                                                
                                                <tr>
                                                    <td>{ totalQuantity }</td>
                                                    <td>{ 
                                                        this.state.coli?.manifestecolis?.map((manifestecoli) => {
                                                            let somme = 0
                                                            this.state.coli?.invoices?.filter(val => val.manifestecoliId == manifestecoli.id).map((invoice) =>{
                                                                somme += invoice.price + invoice.litige
                                                            })
                                                            return <span key={ manifestecoli.id}>
                                                                { manifestecoli.quantity + " colis (" + somme +"$) #"} 
                                                                <Link to={ this.handleCheck("afficher manifeste") == true ? "/manifestes/" + manifestecoli.manifeste?.id : "#" }>{ manifestecoli.manifeste?.ref_manifeste }</Link>, 
                                                            </span> }) 
                                                        }
                                                        {
                                                            this.state.coli?.invoices?.map(invoice => {
                                                                totalDue += invoice.due
                                                                totalPrix += invoice.price + invoice.litige
                                                                // totalPrice -= invoice.price
                                                            })
                                                        }
                                                    </td>
                                                    <td></td>
                                                    <td><strong class>{ totalPrice?.toFixed(2) }$</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>.</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>


                                                <tr className={ this.props.formData?.type == "BORDEREAU" ? "d-none" : "" } >
                                                    <td>Paiement</td>
                                                    <td colSpan={2}>
                                                        {
                                                            (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                            this.state.coli?.invoices?.map((invoice) => {
                                                                return(<span key={ invoice.id+"1"}>{ new FrenchNumbersToWords(invoice.price, 'fr')?.result?.fullText } dollars, { invoice.due == 0 ? <strong>Cash</strong> :<span></span> } </span>)
                                                            })
                                                            :
                                                            <span> {new FrenchNumbersToWords(this.state.due, 'fr')?.result?.fullText } dollars, { (totalPrice - totalPrix) - parseFloat(this.state.litige || 0) > 0 ? <span></span> : <strong>Cash</strong>}</span>
                                                        } 
                                                    </td>
                                                    <td>{
                                                            (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                            this.state.coli?.invoices?.map((invoice) => {
                                                                return(<span key={invoice.id +"2"}>{invoice.price } </span>)
                                                            })
                                                            :
                                                            <input type="number" value={this.state.due} onChange={ (e) => { this.setState({ price : totalPrice, due : e.target.value <= (totalPrice) ? e.target.value : 0 }) }} className="borderInput" />
                                                        }
                                                    </td>
                                                </tr>
                                                
                                                <tr className={ this.props.formData?.type == "BORDEREAU" ? "d-none" : "" } >
                                                    <td>Dette</td>
                                                    <td>{ 
                                                    (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                    this.state.coli?.invoices?.map((invoice) => {
                                                        return(<span key={invoice.id +"3"}>{ invoice.due > 0 ? <strong>Dette</strong> :<span></span> } { new FrenchNumbersToWords(invoice.due, 'fr')?.result?.fullText } dollars </span>)
                                                    })
                                                    :
                                                    totalPrice - this.state.due - totalPrix  > 0 ? <span><strong>Dette</strong> { new FrenchNumbersToWords((totalPrice - this.state.due - totalPrix ) - parseFloat(this.state.litige || 0), 'fr')?.result?.fullText } dollars</span> : <span></span> }</td>
                                                    <td></td>
                                                    <td>
                                                    {
                                                            (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                            this.state.coli?.invoices?.map((invoice) => {
                                                                return(<strong key={invoice.id+"4"}>{invoice.due }</strong>)
                                                            })
                                                            :
                                                            <strong>{ (totalPrice - this.state.due - totalPrix ) - parseFloat(this.state.litige || 0) != 0 ? (totalPrice - this.state.due - totalPrix ) - parseFloat(this.state.litige || 0) : "" }$</strong>
                                                    }
                                                    </td>
                                                </tr>
                                                <tr className={ this.props.formData?.type == "BORDEREAU" ? "d-none" : "" } >
                                                    <td>Litige</td>
                                                    <td>{
                                                            (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                            this.state.coli?.invoices?.filter(a => a.litige > 0)?.map((invoice) => {
                                                                return(<span key={invoice.id+"5"}>{ " Remise de " + new FrenchNumbersToWords(invoice.litige, 'fr')?.result?.fullText + " dollars" }</span>)
                                                            })
                                                            :
                                                            (this.state.litige - this.state.litigeRequest) > 0 ? " Remise de " + new FrenchNumbersToWords(this.state.litige, 'fr')?.result?.fullText + " dollars" : "" 
                                                        }
                                                        </td>
                                                    <td></td>
                                                    <td>{
                                                        brancheDate && brancheDate.userprofile?.brancheId == process.env.REACT_APP_BRANCHE_KMPL  ?
                                                        <span>{(this.state.litige - this.state.litigeRequest)}</span>
                                                        :
                                                        (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                            this.state.coli?.invoices?.map((invoice) => {
                                                                return(<span key={ invoice.id+"6"}>{invoice.litige } </span>)
                                                            })
                                                            :<input type="number" value={(this.state.litige - this.state.litigeRequest)} onChange={ (e) => { this.setState({ litige : e.target.value <= (totalPrice - this.state.due) ? e.target.value : 0 }) }} className="borderInput" />
                                                     }
                                                    </td>
                                                </tr>
                                                <tr className={(this.state.litige > 0 || this.state.description?.length > 0) ? "flex table_action" : "d-none table_action"}>
                                                    <td>Motif litige</td>
                                                     <td colSpan={3}><input type="text" placeholder="description litige ici..." value={this.state.description} onChange={ (e) => this.setState({ description : e.target.value })} className="borderInput" /></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"fw-600 text-dark " + (this.props.formData?.type=="BORDEREAU" ? "" : "d-none") }>Information suplementaire</div>
                                    <div className={  + (this.props.formData?.type=="BORDEREAU" ? "" : "d-none") } style={{ overflow : "hidden"}}><small className="">Contact client :  <abbr>{ this.state.coli?.client?.tel } { this.state.coli?.client?.email } { this.state.coli?.client?.country } { this.state.coli?.client?.city } { this.state.coli?.client?.region } { this.state.coli?.client?.adress }</abbr>, </small> <span className="ps-5">Signature :<abbr><span className="text-secondary">...</span></abbr></span></div>
                                    <div className={"small fs-11 "  + (this.props.formData?.type=="BORDEREAU" ? "" : "d-none") }><span className="fw-500">NB :</span>La maison décline toute responabilité en cas de tout objet fragile<br /> (miroire, verre ...) & emballage insiffisant ne seront pas payés<br/> en cas de perte ou casse. (<small><Link to={ "/profile/"+this.state.coli?.user?.name }>{ this.state.coli?.user?.name }</Link> </small>) <span className={ this.state.coli?.coli_count == 1 ? "small text-primary" : "d-none"} type="button" onClick={ () => this.showModalEmprunt()}>{ this.state.coli?.emprunt > 0 ? this.state.coli?.emprunt + '$ emprunté' : <span className="table_action"> {this.state.coli?.status == "EnStock" ? "Ajouter emprunt" : "" }</span>}</span>   <span className={"small text-info ms-3"} type="button" onClick={ () => this.showModalTotal()}>{ this.state.coli?.total_coli > 0 ? this.state.coli?.total_coli + ' colis total' : <span className="table_action"> {this.state.coli?.status == "EnStock" ? "Ajouter montant colis total" : "" }</span>}</span> </div>
                                </div>

                                <div className={ (this.state.bordereau) ?"" : "d-none"}>
                                     
                                {
                                    this.state.coli?.invoices?.map(invoice => {
                                        totalDueB += invoice.due
                                        totalPrixB += invoice.price + invoice.litige
                                    })
                                }
                                
                                
                                    <div>
                                    
                                    {/* <div className="row y-gap-20 justify-end">
                                        <div className="col-auto pe-5 me-5">{ this.state.coli?.destination?.region } { this.state.coli?.destination?.municipality } { this.state.coli?.destination?.neighborhood } { this.state.coli?.destination?.avenue } { this.state.coli?.destination?.other_adress }</div>
                                    </div> */}

                                        <div className="row y-gap-20 justify-between items-end mt-5 text-dark">
                                            <div className="col-9">Reçu de Mr, Mme <abbr><span className="text-primary">{ this.state.coli?.client?.name }</span><span className="text-secondary">...</span></abbr></div>
                                            <div className="col-3  ">N°.Bon <abbr><strong><span className="text-primary">{ this.props.formData?.ref_number }</span></strong><span className="text-secondary"></span></abbr></div>
                                        </div>
                                        <div>Montant(en toute lettre): <abbr>
                                            {
                                                (this.props.formData?.type != "BORDEREAU" && this.props.formData?.ref_number)?
                                                this.state.coli?.invoices?.filter(val => val.ref_invoice == this.props.formData?.ref_number)?.map((invoice) => {
                                                    return(<span key={ invoice.id+"7"}>
                                                        { invoice.price +"$ ("+ new FrenchNumbersToWords(invoice.price, 'fr')?.result?.fullText +" dollars)"  } 
                                                        { invoice.due > 0 ? " avec une dette de "+ invoice.due +"$ (" + new FrenchNumbersToWords(invoice.due, 'fr')?.result?.fullText +" dollars)" : ""}
                                                        { invoice.litige > 0 ? " et une remise de "+ invoice.litige +"$ (" + new FrenchNumbersToWords(invoice.litige, 'fr')?.result?.fullText +" dollars)" : ""}
                                                    </span>)
                                                }):
                                                <span>
                                                    <span>{ this.state.due +"$("+ new FrenchNumbersToWords(this.state.due, 'fr')?.result?.fullText +" dollars) "  }</span>
                                                    <span>{ (totalPrice - this.state.due - totalPrixB - this.state.litige) > 0 ? <span> avec une dette de {(totalPrice - this.state.due - totalPrixB) - parseFloat(this.state.litige || 0)}$ ({ new FrenchNumbersToWords((totalPrice - this.state.due - totalPrixB) - parseFloat(this.state.litige || 0), 'fr')?.result?.fullText } dollars)</span> : <span></span> }</span>
                                                    <span>{ (this.state.litige - this.state.litigeRequest) > 0 ? " et remise de " + this.state.litige+"$ (" + new FrenchNumbersToWords(this.state.litige, 'fr')?.result?.fullText + " dollars)" : ""  }</span>
                                                </span>
                                            }

                                            </abbr>
                                            <span className="text-secondary">.....</span>
                                            </div>
                                        <div><abbr>
                                            <div className="d-none">
                                               {
                                                // totalPrice = 0
                                                totalQuantity = 0
                                               } 
                                            </div>
                                            {
                                                this.state.coli?.colidetails?.map((colidetail) => {
                                                    totalQuantity += colidetail.quantity
                                                })
                                            }
                                            

                                            {
                                                (this.state.coli?.invoices?.filter(val => val.ref_invoice == this.props.formData?.ref_number)?.length == 0 && brancheDate.userprofile?.brancheId != process.env.REACT_APP_BRANCHE_KMPL) ?
                                                <span>{ (totalPrice - this.state.due - totalPrixB - this.state.litige) > 0 ? <span> Avance sur bordereau { this.state.coli?.ref_coli}, ({ totalQuantity }) colis</span> : <span>Solde du bordereau {  this.state.coli?.ref_coli }</span>}</span>
                                                :
                                                <span>{ (totalPrice - this.state.due - totalPrixB - this.state.litige) > 0 ? <span> Avance sur bordereau { this.state.coli?.ref_coli}</span> : <span> Solde du bordereau {  this.state.coli?.ref_coli } </span>}</span>

                                            }

{/* { this.state.coli?.invoices?.length > 0 ?  this.state.coli?.invoices[0]?.branch?.city : brancheDate && brancheDate.userprofile?.brancheId == process.env.REACT_APP_BRANCHE_KMPL ? this.state.coli?.origine?.city  : this.state.coli?.destination?.city } */}
                                            
                                            {/* Refernce bordereau <span onClick={ () => this.showModalInvoice(1)}>{ this.state.coli?.ref_coli }</span> */}
                                            <span className="text-secondary">.....</span>
                                            </abbr>
                                        </div>
                                        <div className="row y-gap-20 justify-center items-end">
                                            <div className="col-auto">
                                                Fait à { this.state.coli?.invoices?.filter(val => val.ref_invoice == this.props.formData?.ref_number)?.length > 0 ?  this.state.coli?.invoices?.filter(val => val.ref_invoice == this.props.formData?.ref_number)[0]?.branch?.city : brancheDate && brancheDate.userprofile?.brancheId == process.env.REACT_APP_BRANCHE_KMPL ? this.state.coli?.origine?.city  : this.state.coli?.destination?.city } le <small className="">{ new Date(datetime).toLocaleDateString("en-Gb")}</small>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="row y-gap-20 justify-between items-end">
                                            <div className="col-auto">Partie versante</div>
                                            <div className="col-auto pe-5 me-5">La caisse : { this.state.coli?.invoices?.filter(val => val.ref_invoice == this.props.formData?.ref_number)?.length> 0 ? this.state.coli?.invoices?.filter(val => val.ref_invoice == this.props.formData?.ref_number)[0]?.user?.name : user?.name }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
                </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        ( this.state.coli?.manifestecolis?.length > 0 && !this.props.formData?.ref_number && this.props.formData?.type != "FACTURE" && brancheDate && brancheDate.userprofile?.brancheId == process.env.REACT_APP_BRANCHE_KMPL) ? <div><span className="text-primary" type="button" onClick={() => this.loadingInvoice() }>payé à kampala</span> </div> : <div></div>
                    }
                    <div className={ this.props.formData?.type == "FACTURE" || this.state.bordereau ? "" :"d-none"}>
                    <span className="text-danger">{ this.state.error}</span>
                        <span className="" type="button" onClick={ () => this.setState({ bordereau : !this.state.bordereau, numberInvoice : this.state.bordereau ? [1] : [1, 2] })}>{ this.state.bordereau ? "FACTURE" : "BON D'ENTREE"}</span>
                    </div>
                    <button onClick={this.props.showModal} type="button" className="btn btn-outline-secondary btn-sm"><i className="fa fa-hidde"></i> Fermer</button>
                    {
                        (this.handleCheck("afficher coli") == true || this.handleCheck("afficher facture") == true ) && (
                            <ReactToPrint
                                trigger={() => <button type="button"  className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="fa fa-print"></i> imprimer</button>}
                                content={() =>  this.componentRef}
                                pageStyle="@page { size : 2.5in 4in }" //reagister selon le model d'impression
                                // onBeforePrint={() => this.setState({ numberInvoice : [1, 2]})}
                                // onAfterPrint={() => this.setState({ numberInvoice : [1]})}
                                onBeforePrint={ () => { this.setState({ bordereau : this.props.formData?.type == "FACTURE" ? true : false }); } }
                                documentTitle={ this.props.formData?.type + " " + this.props.formData?.ref_number + " " + this.state.coli?.client?.name  + " de " + totalPrice + "$" + new Date().toUTCString()}
                            />
                        )
                    }
                    
                    {
                        (this.handleCheck("ajouter facture") ==true  && this.props.formData?.type == "FACTURE" && brancheId && !this.props.formData?.ref_number   || this.handleCheck("ajouter facture") && this.props.formData?.ref_number && this.props.formData?.due > 0 && brancheId && this.props.formData?.manifestecoliId) &&(
                            this.state.numberInvoice?.length == 2 ?
                                <ReactToPrint
                                    pageStyle="@page { size : 2.5in 4in }"
                                    trigger={() => <button type="button"  className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="fa fa-check"></i> Sauvegarder la facture</button>}
                                    content={() =>  this.componentRef}
                                    documentTitle={ this.props.formData?.type + " " + this.props.formData?.ref_number + " " + this.state.coli?.client?.name  + " de " + totalPrice + "$" + new Date().toUTCString()}
                                    onBeforePrint={ () => { this.storeInvoice(1) } }
                                    // onAfterPrint={() => this.setState({ numberInvoice : [1, 2]})}
                                /> : 
                                <span className="" type="button" onClick={ () => this.setState({ bordereau : !this.state.bordereau, numberInvoice : [1, 2] })}>{  "Sauvegarder la facture"}</span>
                        )
                    }
                </Modal.Footer>
                </form>
            </Modal>

            <EmpruntForm showModal={ this.showModalEmprunt} formData={ this.state.formData} isModal={ this.state.isEmprunt} />
            <TotalColiForm showModal={ this.showModalTotal} formData={ this.state.formData} isModal={ this.state.isTotal_coli} />

        </div>
        )
    }
}

export default InvoiceDesign;