import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
import SubdepenseForm from "../components/SubdepenseForm";
const moment = extendMoment(originalMoment);

class ReportReserve extends Component{

    state = {
        depenses : [],
        typedepenses : [],
        value : null,
        branches : [],
        depenseCount : [],
        brancheId : null,
        isRecherche : false,
        loading : false,
        isDateModal : false,
        isubDepense : false,
        formData : null
    }

    componentDidMount(){
        this.getDepenses();
        this.getTypedepenses();
        this.getBranches();
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            brancheId : brancheId?.userprofile.brancheId
        })
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getDepenses();
    };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getTypedepenses(){
        let request = await axios.get("/typedepenses")
        if(request.status == 200)
            this.setState({ typedepenses : request.data?.sort((a, b) => a.type > b.type ? 1 : -1).filter(a => a.ismonth == 0)})
    }

    async getDepenses(val = null){
        this.setState({
            depenses : [],
            loading : true
        })
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD 00:00');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');

        let date =  moment.range(startOfMonth, endOfMonth)
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        if(val?.column)
        {
            let request = await axios.get('/depenses/$' +  val?.column +"$/" + start + '/' + end + '/' + val?.value);
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.brancheId
            let request = await axios.get('/depenses/brancheId/' + start + '/' + end + '/' + status);
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})

            request = await axios.get('/depenses/count/' + start + '/' + end );
            if(request.status === 200)
                this.setState({depenseCount : request.data})
        }
    }

    showModalSubDepense = (val = null) => {
        this.setState({
            isubDepense : !this.state.isubDepense,
        })
        if(val == 1){
            this.getDepenses()
        }
        else if(val != null){
            this.setState({ formData : val })
        }
    }

    changeStatus = (branche)  => {
        this.getDepenses(branche)
        this.setState({
            brancheId : branche,
            isRecherche : false,
            column : ""
        })
    }

    render(){

        let manifestes = [], mountManifeste = 0, mountManifesteT = 0, depenseT = 0
        this.state.depenses?.filter(type =>  type.typedepense?.id == this.props.typedepenseId)?.map((depense, count) => {
            if(depense.manifeste?.id && manifestes.filter(val => val?.id == depense.manifeste?.id)?.length == 0)
                manifestes.push(depense.manifeste)
             
        })

        return(
            <div className=" bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             {/* { status } */}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1 d-none">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1 d-none" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_depense">par ref depense</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="typedepense.type">par type depense</option>
                                        <option value="beneficer.name">par beneficiaire</option>
                                        <option value="user.name">par responsable</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getDepenses({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)}>
                        <div className=" text-center text-primary">
                            <HeadDoc title={ this.props.title} />
                        </div><div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                               
                                    <table className="table_css -border-bottom col-12 p-0">
                                        <thead>
                                            <tr style={{ backgroundColor : "rgb(0, 0, 150)", fontWeight : "800", color : "rgb(250, 250, 250)"}}>
                                                <td colSpan={4} align="center">PERIODE  { this.renderSelectionValue() } </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td align="center" colSpan={2} style={{ color : "rgb(240, 100, 60)", fontWeight : "800"}}>{ this.props.title }</td>
                                                <td align="center" colSpan={2} style={{ color : "rgb(240, 100, 60)", fontWeight : "800"}}>DEPENSES EFFECTUEES <span className="text-primary table_action" type="button" onClick={ () => this.showModalSubDepense()}>Nouveau</span> </td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2} style={{ verticalAlign : "top"}}>
                                                    <table className="table_css -border-bottom col-12">
                                                        <tbody>
                                                            <tr>
                                                                <th className="border ms-4">VEHICULE</th>
                                                                <th className="border ms-4"  align="right" style={{ paddingRight : 5}}>MONTANT</th>
                                                            </tr>
                                                        
                                                            {
                                                                manifestes?.map((manifest) => {
                                                                    mountManifeste = 0
                                                                    this.state.depenses.filter(type =>  type.typedepense?.id == this.props.typedepenseId && type.manifeste?.id == manifest.id)?.map((depens) => {
                                                                            mountManifeste = depens.mount
                                                                            mountManifesteT += depens.mount
                                                                        })
                                                                    return(
                                                                        <tr>
                                                                            <td className="border fw-400">{ manifest.ref_manifeste }</td>
                                                                            <td className="border" align="right">{ mountManifeste  }</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td colSpan={2}>
                                                    <table className="table_css -border-bottom col-12">
                                                        <tbody>
                                                            <tr>
                                                                <th className="border ms-4">LIBELLE</th>
                                                                <th className="border ms-4" align="right" style={{ paddingRight : 5}}>MONTANT</th>
                                                            </tr>
                                                            {
                                                                this.state.depenses?.filter(type =>  type.typedepense?.id == this.props.typedepenseId)?.map((manifeste) =>{
                                                                    return(
                                                                        manifeste.subdepenses?.map((depense) =>{
                                                                            depenseT += depense.mount
                                                                            return(
                                                                                <tr>
                                                                                    <td className="border ms-4" onClick={() => this.showModalSubDepense(depense)}>{ depense.libele }</td>
                                                                                    <td className="border ms-4" align="right" style={{ paddingRight : 5}} >{ depense.mount }</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><strong>TOTAL</strong></td>
                                                <td align="right" style={{ paddingRight : 5}}><strong>{ mountManifesteT }</strong></td>
                                                <td><strong>TOTAL</strong></td>
                                                <td  align="right" style={{ paddingRight : 5}}><strong>{ depenseT }</strong></td>
                                            </tr> 
                                            <tr>
                                                <th colSpan={2}>SOLDE GENERAL</th>
                                                <td colSpan={2} align="right" style={{ paddingRight : 5}}><strong>{ mountManifesteT - depenseT }</strong></td>
                                            </tr> 

                                        </tbody>
                                    </table>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            
            <SubdepenseForm isModal={ this.state.isubDepense} showModal={ this.showModalSubDepense} typedepenseId={ this.props.typedepenseId } formData={ this.state.formData } />
            
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default ReportReserve;
