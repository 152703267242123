export const TRANSLATIONS_FR = {
    navbar :{
        home : "Acceuil",
        about : "Apropos",
        service : "Service",
        portfolio : "Porte feuille",
        team : "Equipe",
        blog : "Recent",
        contact : "Contact",
        information : "Information",
        article : "Article",
        staff : "Staff",
        documentation : "Documentation",
        logout : "Déconnexion"
    },
    home1 : {
        welcome:"Bienvenue {{ name }}",
        rdc : "-<strong style='font-size : 25px'>R</strong>echerche -<strong style='font-size : 25px'>D</strong>éveloppement -<strong style='font-size : 25px'>C</strong>ommunication ",
        welcomeText : "La facilité  de votre idée et votre projet, nous vous suivrons dès la phase conceptionnelle de votre projet",
        getstarted : "Commencer",
        watchvideo : "Voir la vidéo",
        conception : "Conception du projet",
        analyse : "Analyse des besoins",
        realisation : "Réalisation du projet",
        accompagnement : "Suivis et accompagnement",
    },
    categorie : {
        categories : "Catégories",
        rechercher : "Rechercher",
        recent : "Récents",
        motcle : "Mots clés",
    },
    recherche : {
        rechercher : "Recherche",
        resultat_de_recherche : "Resultat de recherche"
    },
    about : {
        apropos_de_nous : "Apropos de nous",
        apropos_de_nous_description : "Agence d'accompagnement et réalisation de vos divers projets de construction, transport, énergie, infracture, technologique et tout autre demande sur mesure",
        la_conception_et_realisation : "La conception et la réalisation des projets innovavants",
        la_conception_et_realisation_legende1 : "Nous suivons nos clients dès la première phase de l'analyse des besoins dans son secteur d'activité en lui fournissant plus d'éclaircissement de façon à y répondre enfin de l'inculquer plus des idées pour permettre une conception de qualité de l'objectif qu'il vise.",
        la_conception_et_realisation_legende2 : "Notre présence dans sa réalisation rassure l'efficacité et la concrétisation de la conception et des annalyses apportées; C'est ainsi que nous nous mettons aussi à la disponibilité de nos client après la phase de la réalisation du projet pour lui accompagner et faire le suivis du bon fonctionnement et de la bonne résolution des besoins dans le secteur visé",
        notre_culture : "Resoudre votre limite conceptuele de vos projets courrants ou innovants est notre travail de tout le temps avec grande satisfaction",
        equipement_personnel : "Nous sommes actif dans les regions centrale et Est de l'Afrique.",
        temps_repondre : "Nous repondons à toute demande oeuvrant dans notre secteur de réalisation.",
        solution_proposer : "Nos réalisations se basent sur mesure de la demande et nous repondons à toute demande oeuvrant dans notre secteur d'activité.",
        force_equipe : "Les réalisations des projets de nos clients et partenaires sont dû à notre équipe stratégique équilibrée dans multiculture près à affronter tout genre de demande avec grand et brave courage."
    },
    customerHappy : {
        client_satisfait : "Clients satisfaits",
        client_temoignage : "Tout client remarque notre aptitude",
        projets : "Projets",
        projet_temoignage : "L'expérence de nos réalisations en exercices",
        heure_existance : "Temps de réalisation",
        existance_temoignage : "Temps de réalisation du par projet"
    },
    preuve : {
        title : "Concrétisation",
        description : "Les projets réalisés prouvent mieux de quoi nous fournissons à nos clients, le résultat de qualité est notre principale ambition et la réponse aux besoins de la demande est notre  passion dans nos activités de tout le jour.",
    },
    temoignage : {
        title : "Témoignage",
        description : "Nos clients nous font confiance. Que leur campagne soit terminé, ou encore en cours nos clients nous font part de leur plus beau témoignage, nous connaissons maintenant nos clients mieux que jamais",
    },
    portefeuille : {
        title : "Portefeuille",
        description : "Notre approche de la construction de portefeuille ... représentant en Afrique, La première partie de l'article montre la construction des portefeuilles comme un acte de management volontariste"
    },
    team : {
        title : "Faites connaissance avec notre équipe",
        description : "À valeurs mobilières, nous savons que c’est à notre équipe que nous devons notre succès. Mais vous n’avez pas besoin de nous croire sur parole. Lisez plutôt les profils et les témoignages sur notre équipe pour avoir un aperçu de ce que c’est travailler avec nous."
    },
    price : {
        title : "Notre tendance",
        description : "Rendez votre projet sur le plan de son attente est notre priorité dans divers secteur",
        petiterealisation : "Petite réalisation",
        moyenprojet : "Moyen projet",
        extra : "Extra",
        mois : "mois",
        etudedumilieu : "Etude du milieu",
        analyseduprojet : "Analyse du projet",
        conceptionduprojet : "Conception du projet",
        passassiondumarche : "Passassion du marché",
        ideesurleprojet : "Idée sur le projet",
        guideetconseil : "Guide et conseil",
        realisationduprojet : "Réalisation du projet",
        testduprojet : "Test du projet",
        suivispartiel : "Suivi partiel",
        suivistotalduprojet : "Suivi total du projet",
        analysedebesoin : "Analyse des besoins",
        suivisetaccompagnement : "Suivis et accompagnement",
        grandrendezvous : "Grand rendez-vous",
        suivisduprojet : "suivis du projet"
    },
    blog : {
        title : "Récentes réalisations",
        description : "Nous sommes present dans divers secteurs et nos réalisations font preuve des services des qualités que nous rendons au grand publique"
    },
    contact : {
        title : "Contact",
        description : "Faites nous confiance et demandez nous toute préoccupation",
        localisation : "Localisation",
        heure : "Heure",
        lundisamedi : "Lundi-samedi 8h-16",
        envoyermessage : "Envoyer Message"
    },
    footer :{
        description : "evivemanifeste agence de consultation, information, documentation, communication, production et intermédiation, accompagnement d'investissement, recherches, innovation,  élaboration et réalisation des projets.",
        lieu : "Lieu",
        contactnous : "Contact nous"
    }
};