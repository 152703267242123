import axios from "axios";
import React, {Component} from "react";
import ProfilForm from "../components/ProfilForm";
import Loading from "../components/Loading";
import Head from "../partials/Head";

class Profil extends Component{

    state = {
        isModal : false,
        formData : null,
        profiles : [],
        loading : false
    }

    componentDidMount(){
        this.getProfiles();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getProfiles(){
        this.setState({
            loading : true
        })
        var request = await axios.get('profiles');
        
        if(request.status === 200)
        {
            this.setState({profiles : request.data, loading : false})
        }
    }

    showModal = (profile = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : profile,
        })
        this.getProfiles()
    }


    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        var count = 1, profiles =
        this.state.profiles.filter(val => val.id != process.env.REACT_APP_ID_SUPER_USER).map((profile) => {
            return(
                <li className="mb-2" onClick={ () => this.handleCheck("modifier profil") == true && this.showModal(profile)}>
                    <span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-user"></i> { profile.name }</span>
                    <span className="text-danger ms-1">({ profile.userprofiles.length })</span>
                    <hr className="hr-dashed mt-4" />
                </li>
            )
        })
        return(
            <div className="dashboard__content bg-light-2">
                           
            <div className="breadcrumbs">
                <Head title={'Liste profil des utilisateurs'} url={'/profiles/'} logo={'/logo192.png'} />
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Profils des utilisateurs'}</h1>
                        <div className="text-15 text-light-1">{ 'Liste des profils utilisateurs' }</div>

                    </div>

                    <div className="col-auto">
                        {
                            this.handleCheck("ajouter profil") == true && (
                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-14"></i> Nouveau profil</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                this.state.loading && (<Loading /> )
            }

            <ul className="pb-5">
                { profiles}
            </ul>

            <ProfilForm
              isModal={this.state.isModal}
              formData={this.state.formData}
              showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Profil;
