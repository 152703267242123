import axios from "axios";
import React, {Component} from "react";
import BudgetvehicleForm from "../components/BudgetvehicleForm";
import Loading from "../components/Loading";
import Head from "../partials/Head";

class Budgetvehicle extends Component{

    state = {
        isModal : false,
        formData : null,
        budgetvehicles : [],
        loading : false
    }

    componentDidMount(){
        this.getBudgetvehicles();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getBudgetvehicles(){
        this.setState({
            loading : true
        })
        var request = await axios.get('/budgetvehicles');
        
        if(request.status === 200)
        {
            this.setState({budgetvehicles : request.data, loading : false})
        }
    }

    showModal = (budgetvehicle = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : budgetvehicle,
        })
        if(budgetvehicle == 1)
            this.getBudgetvehicles()
    }


    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        const budgetvehicles =
        this.state.budgetvehicles.sort((a, b) => a.vehicle?.plaque > b.vehicle?.plaque ? 1 : -1).map((budgetvehicle) => {
            return(
                <li className="mb-2" key={budgetvehicle.id} type={ "button"} onClick={ () => this.handleCheck("modifier profil") == true && this.showModal(budgetvehicle)}>
                    <div className="row flex justify-between ">
                        <span className="col-4 rounded-100 py-4 px-10 text-14 fw-500 bg-blue-1-2 text-blue-1"> <i className="fa fa-truck"></i> { budgetvehicle.vehicle?.plaque }</span>
                        <span className="col-3 text-danger ">({ budgetvehicle.mount })$</span>
                        <span className="col-5 rounded-100 py-4 px-10 text-14 fw-500 bg-blue-1-1 text-blue-1"> <i className="fa fa-home"></i> { budgetvehicle.branch?.city }  { budgetvehicle.branch?.region }</span>
                    </div>
                    <hr className="hr-dashed mt-4" />
                </li>
            )
        })
        return(
            <div className="dashboard__content bg-light-2">
                           
            <div className="breadcrumbs">
                <Head title={'Liste profil des utilisateurs'} url={'/budgetvehicles/'} logo={'/logo192.png'} />
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Budget par expedition'}</h1>
                        <div className="text-15 text-light-1">{ 'Budget aloué par vehicle' }</div>

                    </div>

                    <div className="col-auto">
                        {
                            this.handleCheck("ajouter profil") == true && (
                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-14"></i> Alouer budget</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                this.state.loading && (<Loading /> )
            }

            <ul className="pb-5">
                { budgetvehicles}
            </ul>

            <BudgetvehicleForm
              isModal={this.state.isModal}
              formData={this.state.formData}
              showModal={this.showModal}
            />

      </div>
        )
    }
}
export default Budgetvehicle;
