import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";

class   ManifesteDue extends Component{

    state = {
        id : null,
        error : "",
        manifeste : null,
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getManifeste(){
        var request = await axios('manifestes/' + this.props.formData);
        if(request.status == 200)
            this.setState({ manifeste : request.data  })
    }

    edit=()=>  {
        this.getManifeste()
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

       let dette = 0
        let colis = 
        this.state.manifeste?.manifestecolis?.sort((a, b) => (a.coli.destination?.city > b.coli.destination?.city) ? 1 : -1).map((manifestecoli, count) => {
            
            return(
                <tr>
                    <td>{ count+1} </td>
                    <td><label htmlFor={count}>{ manifestecoli.coli?.client?.name } </label></td>
                    <td>{ manifestecoli.coli?.ref_coli }</td>
                    { (this.state.manifeste.status == "Arriver" || this.state.manifeste.status == "Payer" ) && (
                        <td>
                            {
                                manifestecoli.coli?.invoices?.sort((a, b) => a.ref_invoice > b.ref_invoice ? 1 : -1)?.map((invoice) => {
                                dette = invoice?.type == "Dette" ? (invoice.due || 0) : 0
                                return(
                                        <span>{ invoice?.ref_invoice || "Facture" } <small className={ manifestecoli.coli?.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1 table_action" : manifestecoli.coli?.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3 table_action" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green table_action"}> <span className={ invoice?.type == "Dette" && ("text-danger")}> { invoice?.type } { invoice?.due > 0 ? invoice?.due +"$" : "" } </span> </small></span>
                                    )
                                })
                            }
                        </td>)
                    }
                    <td><label htmlFor={count}>{ manifestecoli.price?.toLocaleString() }</label></td>
                    <td><label htmlFor={count} className="text-danger">{ manifestecoli.coli?.invoices?.length > 0 ? dette :  manifestecoli.price?.toLocaleString() }</label></td>
                    <td><label htmlFor={count}>{ manifestecoli.coli?.destination?.city } { manifestecoli.coli?.destination?.region }</label></td>
                </tr>
            )
        })

        return(
            <div>
            <Modal
                className="p-4"
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header >
                    <Modal.Title  className="nom-id-icon">{ "Manifeste " + this.state.manifeste?.ref_manifeste }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0">
                   
                    <div className="tabs__content pt-30 js-tabs-content">
                        <div className="tabs__pane -tab-item-1 is-tab-el-active">
                            <div className="overflow-scroll scroll-bar-1">
                                <table className="table_css -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr>
                                            <td>#</td>
                                            <td>Client</td>
                                            <td>Coli</td>
                                            <td>Facture</td>
                                            <td><label>Montant</label></td>
                                            <td>Dette</td>
                                            <td><label>Destination</label></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { colis }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 form">
                       
                        <Modal.Footer>
                            <button onClick={() => this.props.showModal(null)} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                       </Modal.Footer>
                    </div>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default ManifesteDue;
