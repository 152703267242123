import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   TypedepenseForm extends Component{

    state = {
        id : null,
        name : "",
        error : "",
        typedepenses : [],
        isModal : false,
        ismanifeste : 0,
        month : 0,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async typedepenses(){
        var request = await axios('typedepenses');
        if(request.status == 200)
            this.setState({ typedepenses : request.data  })
    }

    storeTypedepense = () =>{
        var data = {
                type : this.state.name,
                ismanifeste : (this.state.month == 2) ? 1 : 0,
                ismonth : this.state.month 
            }
        var request = this.state.id ? axios.put('typedepenses/'+this.state.id, data) : axios.post('typedepenses', data);
        request.then(async(response) =>{
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(response.data?.data?.id);
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
        this.typedepenses()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            name: this.props.formData ? this.props.formData.type : "",
            ismanifeste: this.props.formData ? this.props.formData.ismanifeste : 0,
            month: this.props.formData ? this.props.formData.ismonth : 0,
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

        return(
            <div>
            <Modal
                className="bg-dark"
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header >
                    <Modal.Title  className="nom-id-icon">{ this.state.id ? "Modifier" : "Type de depense" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0">
                    <div className="col-md-6 mt-2">
                        {
                            this.state.typedepenses.filter(val => val.ismanifeste == 1 && this.state.month == 2  || val.ismanifeste == 0 && val.ismonth == this.state.month && (val.id != process.env.REACT_APP_ID_DEPENSE_COMPTE_VEHICULE &&  val.id != process.env.REACT_APP_ID_DEPENSE_RESERVE) ).map((typedepense) => {
                                return(
                                    <label className="border border-1 rounded-3 px-2 mx-1 mb-2" onClick={() => this.setState({ id : typedepense.id, name : typedepense.type}) }>{ typedepense.type }{ typedepense.ismanifeste ? (<small className="text-info" style={{ fontSize : 9 }}>(Commission)</small>) : typedepense.ismonth == 1 ? (<small className="text-success" style={{ fontSize : 9 }}>(Mensuelle)</small>) : typedepense.ismonth == 3 ? (<small className="text-primary" style={{ fontSize : 9 }}>(Anuelle)</small>) : (<small className="text-warning" style={{ fontSize : 9 }}>(Expédition)</small>) }</label>
                                )
                            })
                        }
                    </div>

                    <div className="col-md-6 mt-2 form">
                        
                        <div className=" text-danger text-small small">{ this.state.error }</div>
                        <div className=" ">
                        <label htmlFor="ismanifeste" className="h6 font-monospace">Commission<input type="radio" id="ismanifeste" placeholder=""  onChange={ () => this.setState({ month : 2 }) } name="month"  value={this.state.month} /></label>
                        <label htmlFor="ismonth_" className="h6 font-monospace">Depense mensuelle<input type="radio" id="ismonth_" placeholder=""  onChange={ () => this.setState({ month : 1 }) } name="month"  value={this.state.month} /></label>
                        <label htmlFor="isanuel" className="h6 font-monospace">Depense anuelle<input type="radio" id="isanuel" placeholder=""  onChange={() => this.setState({ month : 3 })} name="month"  value={this.state.month} /></label>
                        <label htmlFor="ismonth__" className="h6 font-monospace">Depense par expedition<input type="radio" id="ismonth__" placeholder=""  onChange={() => this.setState({ month : 0 })} name="month"  value={this.state.month} /></label>
                        </div>
                        <div className="form-group ">
                            <label className="h6 font-monospace">Type de dépense</label>
                            <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                        </div>
                        <Modal.Footer>
                            <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                            <button onClick={() => this.storeTypedepense()} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Sauvegarder</button>
                        </Modal.Footer>
                    </div>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default TypedepenseForm;
