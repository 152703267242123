import React, {Component} from "react";
import axios from "axios";

class Home extends Component{

    state = {
        teams : [],
        articles : [],
        categories : [],
    }

    async getArticles (){
        let articles = await axios.get('articles')
        if(articles.status == 200){
            this.setState({ articles : articles.data })
        }
    }

    async getTeams (){
        let users = await axios.get('/teams')
        if(users.status == 200){
            this.setState({ teams : users.data })
        }
    }

    async getCategories (){
        let categories = await axios.get('categories')
        if(categories.status == 200){
            this.setState({ categories : categories.data })
        }
    }

    componentDidMount(){
        this.refreshToken()
        var country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
       
        
        if(!country.country)
            this.datacountryIp();

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async refreshToken () {
        try{
          var token = await axios.get('token');
          if(token.status === 200)
          {
              var user = (token.data.data);
              localStorage.setItem("user", JSON.stringify(user));
          }
          else if(token.status === 401)
          {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
          }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
        }
    }

    async datacountryIp(){
        try{
            var ip = await axios("https://httpbin.org/ip");
            if(ip.status === 200)
            {
                var country = await axios.get('country/' + ip.data.origin);
                if(country.status === 200)
                {
                    localStorage.setItem("country", JSON.stringify(country.data) );
                }
            }
        }catch(error){
            console.log(error);
        }
    }
    

    render(){
        
        return(
            <div className="dashboard__content bg-light-2">
                <section className=" layout-pb-md">
                    <div style={{ marginLeft : 100, marginBottom : 50 }}>
                        <h1 className="text-25 lh-14 fw-700 text-primary">LA VICTOIRE EXPRESS <i className="fa fa-truck"></i></h1>
                        <div className="text-15 text-light-1">Agence de transport</div>
                    </div>

                    <div data-anim-wrap className="container">
                        <div className="row y-gap-40 sm:y-gap-10 justify-between">

                        <div className="col-lg-4 col-sm-6">

                            <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                                <div className="d-flex justify-center">
                                    <img src="/img/featurelcons/1/1.svg" alt="image" className="js-lazy" />
                                </div>

                                <div className="text-center mt-30">
                                    <h4 className="text-18 fw-500">Meilleur prix abordable</h4>
                                    <p className="text-15 mt-10">Notre tarif est juste et considerable par la majorité des clients potentiels.</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-sm-6">

                            <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                            <div className="d-flex justify-center">
                                <img src="/img/featurelcons/1/2.svg" alt="image" className="js-lazy" />
                            </div>

                            <div className="text-center mt-30">
                                <h4 className="text-18 fw-500">Facile & Rapide</h4>
                                <p className="text-15 mt-10">Nous mettons à votre disposition une gamme de service de transport adapté à tous vos colis.</p>
                            </div>
                            </div>

                        </div>

                        <div className="col-lg-4 col-sm-6">

                            <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                            <div className="d-flex justify-center">
                                <img src="/img/featurelcons/1/3.svg" alt="image" className="js-lazy" />
                            </div>

                            <div className="text-center mt-30">
                                <h4 className="text-18 fw-500">24/7 Au service du client</h4>
                                <p className="text-15 mt-10">Les horaires des voyageurs et des marchandises sont respectés en permanence.</p>
                            </div>
                            </div>

                        </div>

                        </div>
                    </div>
                </section>

                <section className="layout-pt-lg layout-pb-lg bg-blue-2">
                    <div className="container">
                        <div className="row y-gap-60">
                        <div className="col-xl-5 col-lg-6">
                            <h2 className="text-30 ">Ce que nos client disent<br /> de nous?</h2>
                            <p className=" mt-20">Les clients apprécient les transporteurs qui respectent les horaires annoncés.<br />
                            La qualité des véhicules, l'espace disponible,  la propreté sont autant d'éléments qui influencent le confort de nos clients.<br />
                            Nos clients accordent une grande importance à la sécurité du trajet..</p>

                             
                        </div>

                        <div className="col-xl-4 offset-xl-2 col-lg-5 offset-lg-1 col-md-10">


                            <div className="testimonials-slider-2 js-testimonials-slider-2">
                                <div className="swiper-wrapper">

                                    <div className="swiper-slide">
                                        <div className="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40 shadow-2">
                                            <div className="">
                                                <h4 className="text-16 fw-500 text-blue-1 mb-20">La victoire service de transport</h4>
                                                <p className="testimonials__text lh-18 fw-500 text-dark-1">&quot;Notre service de transport est une activité essentielle qui expédie les marchandises dans differentes destination surtout Kampala et Congo Kinshasa..&quot;</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        </div>
                        
                    </div>
                </section>


            </div>
        )
    }
}
export default Home;