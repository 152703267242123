import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Expedition extends Component{

    state = {
        invoices : [],
    }

    componentDidMount(){
        this.getInvoices()
    }

    async getInvoices(){
        let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
        let request = await axios.get('/manifestes/$driver_id$/' + user?.id);
            if(request.status === 200)
                this.setState({manifestes : request.data})
    }

    render(){
        
        let manifestes = this.state.manifestes?.map((manifeste) => {
            return(
                <div className="col-lg-4 col-sm-6">
                    <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                        <div className="d-flex justify-center">
                            <Link to={"/expeditions/" + manifeste.id} className="pt-3 fw-500">{ manifeste.ref_manifeste } <small className={ manifeste.status == "EnCours" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : (manifeste.status == "Cloture" || manifeste.status == "Payer") ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> { manifeste.status }</small></Link>
                        </div>

                        <div className="text-center mt-30">
                            <h4 className="text-18 fw-500"><Link to={"/expeditions/" + manifeste.id}>{ manifeste.origine?.country } { manifeste.origine?.city } { manifeste.origine?.region } <i className="fa fa-road text-primary"></i> { manifeste.destination?.country } { manifeste.destination?.city } { manifeste.destination?.region }</Link></h4>
                            <p className="text-15 mt-10">{ manifeste.vehicle?.plaque } { manifeste.vehicle?.mark?.name }.</p>
                        </div>
                        <p className="text-end"><small>{ new Date(manifeste.createdAt).toLocaleDateString("en-GB") }</small></p>
                    </div>
                </div>
            )
        })
        return(
            <div className="dashboard__content bg-light-2">
                
                <div className="container">
                    <div className=" items-end">
                        <div className="col-auto">
                            <h1 className="text-25 lh-14 fw-500 text-primary">Expéditions  </h1>
                        </div>
                    </div>
                    <div className="row y-gap-40 justify-between pt-50">
                        { manifestes }
                    </div>
                </div>

            </div>
        )
    }
}
export default Expedition