import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   TotalColiForm extends Component{

    state = {
        id : null,
        total_coli : "",
        error : ""
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    storeTotalcoli = (val = 1) =>{
        var data = {
                total_coli : val == 1 ? this.state.total_coli : 0, 
            }
        var request = axios.post('colis/'+this.props.formData.id, data)
        request.then(async(response) =>{
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(1);
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
    }

    form=()=>{
        this.setState({
            total_coli: this.props.formData ? this.props.formData.total_coli : "",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal
                className="bg-dark"
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="sm"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header >
                    <Modal.Title  className="nom-id-icon">{ "Total colis" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0">
                 

                    <div className="col- mt-2 form">
                        
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        <div className="form-group ">
                            <label htmlFor="h6 font-monospace">Total coli</label>
                            <input type="number" className="form-control" placeholder=""  onChange={this.handleInput} name="total_coli"  value={this.state.total_coli} id="nom-id-icon" />
                        </div>
                        <Modal.Footer>
                            <button onClick={() => this.props.showModal(null)} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                            {
                                this.props.formData?.total_coli > 0 &&(
                                    <button onClick={() => this.storeTotalcoli(0)} className="btn btn-sm btn-outline-danger"><i className="ti ti-trash"></i> Annuler</button>
                                )
                            }
                            <button onClick={() => this.storeTotalcoli(1)} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Sauvegarder</button>
                        </Modal.Footer>
                    </div>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default TotalColiForm;
