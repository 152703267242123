import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class ReportManifeste extends Component{

    state = {
        manifestes : [],
        manifesteCount : [],
        status : "EnCours",
        value : null,
        branche : null,
        isManifeste : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getManifestes();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getManifestes();
      };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
            value : null
        })
    }
    
    async getManifestes(val = null){
        this.setState({
            manifestes : [],
            loading : true
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        if(val?.column)
        {
            let request = await axios.get('/manifestes/$' +  val?.column +"$/" + start + '/' + end + '/' + val?.value);
            if(request.status === 200)
                this.setState({manifestes : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.status
            let request = await axios.get('/manifestes/status/' + start + '/' + end + '/' + status);
            if(request.status === 200)
                this.setState({manifestes : request.data, loading : false})

            request = await axios.get('/manifestes/count/' + start + '/' + end );
            if(request.status === 200)
                this.setState({manifesteCount : request.data})
        }
    }

    changeType = (type)  => {
        this.getManifestes(type)
        this.setState({
            status : type,
            isRecherche : false,
            column : ""
        })
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let detailExpedition = this.state.status == "EnCours" ? "Liste des manifestes encours d'embarquement" : this.state.status == "Acredit" ? "Liste des manifestes expediés acredit" : "Liste des expéditions arrivées a destination"
        let activate = "", countmanifestes = 0;
        let status = 
        ['EnCours', 'Acredit', 'Arriver', "Payer"].map((type, count) => {
            activate = ""
            if(this.state.status == type) activate = " is-tab-el-active ";
            countmanifestes = this.state.manifesteCount.filter(val => val.status == type)
            countmanifestes = countmanifestes.length == 1 ? countmanifestes[0].count : 0
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeType(type)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)} title={ type == "EnCours" ? "Liste des manifestes encours d'embarquement" : type == "Acredit" ? "Liste des manifestes expediés acredit" : "Liste des expéditions arrivées a destination"}> { type } ({ countmanifestes })</button>
                </div>
            )
        })

        let colis = 0, coliInvoice = 0, price = 0, due = 0, depense = 0, tColis = 0, tColiInvoice = 0, tPrice = 0, tdue = 0, tdepense = 0
        let manifestes =
        this.state.manifestes.map((manifeste, count) => {
            colis = 0
            price = 0
            coliInvoice = 0
            due = 0
            depense = 0
            manifeste.depenses?.map( depese => depense += depese.mount)
            manifeste.manifestecolis?.map((manifestecoli) => {
                colis += manifestecoli.coli?.expedie
                price += manifestecoli.price
                coliInvoice +=  manifestecoli.coli ? manifestecoli.coli?.quantity : 0
                manifestecoli.invoices?.map((inv) => {
                    due += inv.isDue == 1 ? inv.due : 0
                })
            })

            tdepense += depense
            tColis += colis
            tPrice += price
            tColiInvoice += coliInvoice
            tdue += due
            return(
                <tr>
                    <td>{ count+1} </td>
                    <td><Link to={"/manifestes/" + manifeste?.id }>{ manifeste.ref_manifeste } </Link></td>
                    <td>{ manifeste.origine?.city } { manifeste.destination?.city }  
                    <span className="text-primary"> <Link to={"/profile/" + manifeste.driver?.id}><small>{ manifeste.driver?.name }</small></Link> </span>  { manifeste.vehicle?.plaque } { manifeste.vehicle?.mark?.name } </td>
                    <td>{  colis + "/" + coliInvoice }</td>
                    <td>{ price }</td>
                    <td>{ due }</td>
                    <td>{ depense }</td>
                    <td><small>{ this.state.status == "EnCours" ? new Date(manifeste.createdAt).toLocaleDateString("en-GB") : manifeste.datesortie ? new Date(manifeste.datesortie).toLocaleDateString("en-GB") : "..../..../...."}</small></td>
                </tr>
            )
        })
        return(

                    <div className=" bg-light-2">
                               
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs row">
                                <div className="row y-gap-20 justify-between items-end">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            { status }
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <form className="row form">
                                            <div className="col-auto m-0 p-0 ms-1">
                                                <Link to="#" className="my-3" >
                                                    <ReactToPrint
                                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                                        content={() =>  this.componentRef}
                                                    />
                                                </Link> 
                                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <select name="column" value={this.state.column} className="form form-control" onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                    <option></option>
                                                    <option value="ref_manifeste">par ref manifeste</option>
                                                    <option value="vehicle.plaque">par plaque</option>
                                                    <option value="origine.city">par origine</option>
                                                    <option value="destination.city">par destination</option>
                                                    <option value="driver.name">par chauffeur</option>
                                                </select>
                                            </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getManifestes({ column : this.state.column, value : select.target.value }))) }} />
                                            </div>
                                        </form>
                                    </div>

                                </div>

                                <div ref={el => (this.componentRef = el)}>
                                    <HeadDoc title={"Expédition " + this.state.status } />
                                    

                                    <div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                                    <div className="tabs__content pt-30 js-tabs-content">
                                        <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                            <div className="overflow-scroll scroll-bar-1">
                                            <table className="table_css -border-bottom col-12">
                                                <thead className="bg-light-2">
                                                    <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                                        <th>#</th>
                                                        <th>Réf man</th>
                                                        <th>Expédition</th>
                                                        <th>Nbre colis</th>
                                                        <th>Montant($)</th>
                                                        <th>Dette($)</th>
                                                        <th>Depense($)</th>
                                                        <th>{ this.state.status == "EnCours" ? "Date"  : "Sortie"}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { manifestes}
                                                    <tr>
                                                        <th></th>
                                                        <th>Total</th>
                                                        <th></th>
                                                        <th>{ tColis + "/" + tColiInvoice }</th>
                                                        <th>{ (tPrice).toFixed(2) }</th>
                                                        <th>{ tdue }</th>
                                                        <th>{ tdepense }</th>
                                                        <th></th>
                                                    </tr>
                                                </tbody>
                                            </table>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                       
                        <Modal
                            show={this.state.isDateModal}
                            onHide={() => this.showModalDate()}
                            size="sm"
                            backdrop={"static"}>
                            <Modal.Body className="row">

                                <strong>{this.renderSelectionValue()}</strong>
                                <DateRangePicker
                                    value={this.state.value}
                                    onSelect={this.onSelect}
                                    singleDateRange={true}
                                />

                            </Modal.Body>
                        </Modal>

                        </div>

      
                


        )
    }
}
export default ReportManifeste;
