import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import axios from "axios";
import { HelmetProvider } from 'react-helmet-async';

var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
axios.defaults.baseURL = process.env.REACT_APP_API_EVIVEMANIFESTE;
axios.defaults.headers.common['x-access-token'] = user.refresh_token ? user.refresh_token : "";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
