import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Footer } from "./partials/Footer";
import {Navbar} from './partials/Navbar';
import RouterPage from "./RouterPage";
import  Sidebar  from "./partials/Sidebar";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

function App() {
  function handleCheck(val) {
    var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
    return permissions.some(item => val === item.name)
  }
  let token = localStorage.getItem("user")
    token = token ? JSON.parse(token) : false
  return (
    <Router> 
          <Navbar />
          <div className={ (token && handleCheck("afficher dashboard")) && ("dashboard")} data-x="dashboard" data-x-toggle="-is-sidebar-open">
            { (token && handleCheck("afficher dashboard")) && (<Sidebar />) }
            <div className={ (token && handleCheck("afficher dashboard")) && ("dashboard__main")}>
                <RouterPage />
            </div>
          </div>
          <ToastContainer />
          { !token  && (<Footer />)}
    </Router>
  );
}

export default App;
