import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import InvoiceDesign from "../components/InvoiceDesign";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class ReportDepense extends Component{

    state = {
        depenses : [],
        value : null,
        branches : [],
        depenseCount : [],
        brancheId : null,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getDepenses();
        this.getBranches();
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            brancheId : brancheId?.userprofile.brancheId
        })
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getDepenses();
    };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getDepenses(val = null){
        this.setState({
            depenses : [],
            loading : true
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        if(val?.column)
        {
            let request = await axios.get('/depenses/$' +  val?.column +"$/" + start + '/' + end + '/' + val?.value);
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.brancheId
            let request = await axios.get('/depenses/brancheId/' + start + '/' + end + '/' + status);
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})

            request = await axios.get('/depenses/count/' + start + '/' + end );
            if(request.status === 200)
                this.setState({depenseCount : request.data})
        }
    }


    changeStatus = (branche)  => {
        this.getDepenses(branche)
        this.setState({
            brancheId : branche,
            isRecherche : false,
            column : ""
        })
    }

    render(){
        
        let activate = "", countdepenses = 0;
        let status = 
        this.state.branches.map((branche, count) => {
            activate = ""
            if(this.state.brancheId == branche.id) activate = " is-tab-el-active";
            countdepenses = this.state.depenseCount.filter(val => val.brancheId == branche.id)
            countdepenses = countdepenses.length == 1 ? countdepenses[0].count : 0
            return(
                <div className="col-auto" key={ branche.id}>
                    <button onClick={ () => this.changeStatus(branche.id)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)} title={branche.country}>{ branche.country } { branche.city } { branche.region } ({ countdepenses })</button>
                </div>
            )
        })
        
        let tdepenses = 0 
        let depenses =
        this.state.depenses.map((depense, count) => {
            tdepenses += depense?.mount
            return(
                <tr key={ depense.id }>
                    <td>{ count+1} </td>
                    <td><label htmlFor={count}>{ depense.ref_depense }</label></td>
                    <td><Link to={"/profile/" + depense.beneficer?.id}>{ depense.beneficer?.name }  { depense.beneficer?.userprofile?.profile?.name }</Link></td>
                    <td><Link to={"/profile/" + depense.user?.id}>{ depense.user?.name }</Link></td>
                    <td><label htmlFor={count}>{ depense.typedepense?.type }</label></td>
                    <td><label htmlFor={count}>{ depense.mount }</label></td>
                    <td><label htmlFor={count}>{ depense.manifeste?.ref_manifeste }</label></td>
                    <td><small>{ new Date(depense.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })

        return(
            <div className=" bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             { status}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_depense">par ref depense</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="typedepense.type">par type depense</option>
                                        <option value="beneficer.name">par beneficiaire</option>
                                        <option value="user.name">par responsable</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getDepenses({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)}>
                        <div className=" text-center text-primary">
                            <HeadDoc title={"Depense" } />
                        </div><div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                                <table className="table_css -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                            <th>#</th>
                                            <th>Ref depense</th>
                                            <th>Beneficiaire</th>
                                            <th>Responsable</th>
                                            <th>Type depense</th>
                                            <th>Montant($)</th>
                                            <th title="Nombre des depenses">Manifeste</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { depenses }
                                        <tr>
                                            <th></th>
                                            <th>Total</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>{tdepenses}</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default ReportDepense;
