import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class ReportClient extends Component{

    state = {
        users : [],
        value : null,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getClients();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getClients();
      };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getClients(val = null){
        this.setState({
            users : [],
            loading : true
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        if(val?.column)
        {
            let request = await axios.get('/clients/$' +  val?.column +"$/" + start + '/' + end + '/' + val?.value);
            if(request.status === 200)
                this.setState({users : request.data, loading : false})
        }
        else{
            let request = await axios.get('/clients/plaque/' + start + '/' + end + '/null');
            if(request.status === 200)
                this.setState({users : request.data, loading : false})
        }
    }

    render(){
        
        let tcolis = 0, tstock = 0, tprice = 0, ttcolis = 0, ttstock = 0, ttprice = 0
        let users =
        this.state.users.map((client, count) => {
            tcolis = 0
            tprice = 0

            client.colis?.map(coli => {
                coli.manifestecolis?.map(manifestecoli => {
                    tcolis +=   manifestecoli.quantity || 0
                })
                tstock +=  coli.quantity || 0
                tprice +=  coli.price

                ttcolis +=  coli.quantity || 0
                ttstock += coli.quantity || 0
                ttprice += tprice
            })
            return(
                <tr key={ client.id }>
                    <td>{ count+1} </td>
                    <td>{ client.name }</td>
                    <td>{ tstock }</td>
                    <td>{ tcolis }</td>
                    <td>{ tprice }</td>
                </tr>
            )
        })

        return(
            <div className="border bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="colis.manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="colis.manifeste.vehicle.plaque">par plaque</option>
                                        <option value="colis.manifeste.vehicle.groupe">par groupe</option>
                                        <option value="colis.manifeste.vehicle.mark.name">par marque</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column && ( select.target.value?.length > 3 && (this.getClients({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)}>
                        <div className=" text-center text-primary">
                            <HeadDoc title={"Client" } />
                        </div> <div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " }>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                                <table className="table_css -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                            <th>#</th>
                                            <th>Client</th>
                                            <th>Stock</th>
                                            <th>Expédié</th>
                                            <th>Montant($)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { users }
                                        <tr>
                                            <th></th>
                                            <th>Total</th>
                                            <th>{ ttstock }</th>
                                            <th>{ ttcolis }</th>
                                            <th>{ ttprice }</th>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default ReportClient;
