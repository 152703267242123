import axios from "axios";
import React, { Component } from "react";

class Contact extends Component{

    state = {
        name : "",
        email : "",
        subject : "",
        message : "",
        branches : [],
    }

    componentDidMount(){
        this.getBranches()
    }

    async getBranches(){
        let request = await axios.get("/branches")
        if(request.status == 200)
            this.setState({ branches : request.data})
    }

    render(){
        return(
            <div className="dashboard__content bg-light-2">
                <div className=" items-end">
                    <div className="col-auto">
                        <h1 className="text-25 lh-14 fw-500 text-primary">Contactez nous  </h1>
                    </div>
                </div>

                <section data-anim="fade" className="d-flex items-center py-15 border-top-light">
                    <div className="container">
                        <div className="row y-gap-10 items-center justify-between">
                        <div className="col-auto">
                            <div className="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                            <div className="col-auto">
                                <div className="">Afrique</div>
                            </div>
                            <div className="col-auto">
                                <div className="">{">"}</div>
                            </div>
                            <div className="col-auto">
                                <div className="">Kenya</div>
                            </div>
                            <div className="col-auto">
                                <div className="">{">"}</div>
                            </div>
                            <div className="col-auto">
                                <div className="text-dark-1">Kinshasa</div>
                            </div>
                            </div>
                        </div>

                        <div className="col-auto">
                            <a href="#" className="text-14 text-light-1">Kampala city</a>
                        </div>
                        </div>
                    </div>
                </section>

                <div className="ratio ratio-16:9">
                    <div className="map-ratio">
                        <div className="map js-map-single"></div>
                    </div>
                </div>

                <section>
                <div className="relative container">
                    <div className="row justify-end">
                    <div className="col-xl-5 col-lg-7">
                        <div className="map-form px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
                        <div className="text-22 fw-500">
                            Laissez nous un message
                        </div>

                        <div className="row y-gap-20 pt-20">
                            <div className="col-12">

                            <div className="form-input ">
                                <input type="text" value={ this.state.name } />
                                <label className="lh-1 text-16 text-light-1">Nom</label>
                            </div>

                            </div>
                            <div className="col-12">

                            <div className="form-input ">
                                <input type="text" value={ this.state.email } />
                                <label className="lh-1 text-16 text-light-1">Email</label>
                            </div>

                            </div>
                            <div className="col-12">

                            <div className="form-input ">
                                <input type="text" value={ this.state.subject } />
                                <label className="lh-1 text-16 text-light-1">Objet</label>
                            </div>

                            </div>
                            <div className="col-12">

                            <div className="form-input ">
                                <textarea value={this.state.message } rows="4"></textarea>
                                <label className="lh-1 text-16 text-light-1">Message</label>
                            </div>

                            </div>
                            <div className="col-auto">

                            <a href="/" className="button px-24 h-50 -dark-1 bg-blue-1 text-white">
                                Envoyer <div className="icon-arrow-top-right ml-15"></div>
                            </a>

                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section className="layout-pt-md layout-pb-lg">
                <div className="container">
                    <div className="row  justify-between">
                    <div className="col-12">
                        <div className="text-18 fw-500">Contactez nous</div>
                    </div>
                    {
                        this.state.branches?.map((branche) => {
                            return(
                                <div className="row justify-between">
                                    <div className="col-auto">
                                        <div className="text-13 text-light-1">{ branche.country } { branche.city }</div>
                                        <div className="text-14">{ branche.region } { branche.municipality } { branche.neighborhood } { branche.avenue } { branche.other_adress }</div>
                                    </div>

                                    <div className="col-auto">
                                        <div className="text-13 text-light-1">{ branche.email }</div>
                                        <div className="text-14">{ branche.tel }</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    </div>
                </div>
                </section>

                <section className="layout-pt-lg layout-pb-lg bg-blue-2">
                    <div className="container">
                        <div className="row justify-center text-center">
                            <div className="col-auto">
                                <div className="sectionTitle -md">
                                <h2 className="sectionTitle__title">Pourquoi choisir notre agence</h2>
                                <p className=" sectionTitle__text mt-5 sm:mt-0">Le secret de notre popularité est du à notre rapidité et fiabilité</p>
                                </div>
                            </div>
                        </div>

                        <div className="row y-gap-40 justify-between pt-50">

                            <div className="col-lg-4 col-sm-6">

                                <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                                    <div className="d-flex justify-center">
                                        <img src="/img/featurelcons/1/1.svg" alt="image" className="js-lazy" />
                                    </div>

                                    <div className="text-center mt-30">
                                        <h4 className="text-18 fw-500">Meilleur prix abordable</h4>
                                        <p className="text-15 mt-10">Notre tarif est juste et considerable par la majorité des clients potentiels.</p>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-6">

                                <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                                <div className="d-flex justify-center">
                                    <img src="/img/featurelcons/1/2.svg" alt="image" className="js-lazy" />
                                </div>

                                <div className="text-center mt-30">
                                    <h4 className="text-18 fw-500">Facile & Rapide</h4>
                                    <p className="text-15 mt-10">Nous mettons à votre disposition une gamme de service de transport adapté à tous vos colis.</p>
                                </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-sm-6">

                                <div className="featureIcon -type-1 -hover-shadow  bg-white p-2">
                                <div className="d-flex justify-center">
                                    <img src="/img/featurelcons/1/3.svg" alt="image" className="js-lazy" />
                                </div>

                                <div className="text-center mt-30">
                                    <h4 className="text-18 fw-500">24/7 Au service du client</h4>
                                    <p className="text-15 mt-10">Les horaires des voyageurs et des marchandises sont respectés en permanence.</p>
                                </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
export default Contact