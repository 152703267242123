import axios from "axios";
import React, {Component} from "react";
import ManifesteForm from "../components/ManifesteForm";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Head from "../partials/Head";
import ManifestDetail from "../components/ManifestDetail";
import { toast } from "react-toastify";

class Manifeste extends Component{

    state = {
        manifestes : [],
        manifesteCount : [],
        currentPage : 1,
        status : "EnCours",
        isManifeste : false,
        loading : false,
    }

    componentDidMount(){
        this.getManifestes();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getManifestes(val = null, page=1){
        this.setState({
            manifestes : [],
            manifesteCount : [],
            loading : true
        })
        if(val?.column)
        {
            let request = await axios.get('/manifestes/$' + val?.column + '$/' + val?.value+"/" + page);
            if(request.status === 200)
                this.setState({manifestes : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.status
            let request = await axios.get('/manifestes/status/' + status+"/" + page);
            if(request.status === 200)
                this.setState({manifestes : request.data, loading : false})

            request = await axios.get('/manifestes/count/status');
            if(request.status === 200)
                this.setState({manifesteCount : request.data})
        }
    }

    showModalManifeste = (e) => {
        this.setState({ isManifeste : !this.state.isManifeste })
        if(e == 1)
            this.getManifestes()
    }

    delete = (manifeste) =>{
        axios.delete("manifestes/" + manifeste).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getManifestes()
        }).catch((error)=>{})
    }

    changePage = (page) =>{
        this.setState({ currentPage : parseInt(page)})
        this.getManifestes(this.state.status, parseInt(page))
    }


    changeType = (type)  => {
        this.getManifestes(type)
        this.setState({
            currentPage : 1,
            status : type,
            isRecherche : false,
            column : ""
        })
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        const PAGINATION = parseInt(process.env.REACT_APP_PAGINATE)
        let detailExpedition = this.state.status == "EnCours" ? "Liste des manifestes encours d'embarquement" : this.state.status == "Acredit" ? "Liste des manifestes expediés" : "Liste des expéditions arrivées a destination"
        let activate = "", countmanifestes = 0;
        let status = 
        ['EnCours', 'Acredit', 'Arriver', "Payer"].map((type, count) => {
            activate = ""
            if(this.state.status == type) activate = " is-tab-el-active ";
            countmanifestes = this.state.manifesteCount.filter(val => val.status == type)
            countmanifestes = countmanifestes.length == 1 ? countmanifestes[0].count : 0
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeType(type)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)}> { type } ({ countmanifestes })</button>
                </div>
            )
        })

        let buttons = [], elements = [], count = 0;
        this.state.manifesteCount.filter(val => val.status == this.state.status).map((val, valcount) => {
            for (let index = 0; index <= val.count; index += PAGINATION) {
                count += 1
                elements.push(count)
            }
        })

        buttons = 
        elements.map((element) => {
            return(
                <div className="col-auto">
                    <div type="button" onClick={ () => this.changePage( element  ) } className={ element  == this.state.currentPage ? "size-30 flex-center rounded-full bg-dark-2 text-white" : "size-30 flex-center rounded-full bg-light-2"}>{ element }</div>
                </div>
            )
        })

        let manifestes =
        this.state.manifestes.map((manifeste, count) => {
            return(
                this.state.status == "EnCours" ? 
                    <div>
                        <ManifestDetail formData={ { id : manifeste.id }} />
                        <br />
                        <br />
                        <br />
                    </div>
                :
                <tr>
                    <td>{ (count + (this.state.currentPage * PAGINATION)+1)-PAGINATION} </td>
                    <td><Link to={"/manifestes/" + manifeste?.id }>{ manifeste.ref_manifeste } <small className={ manifeste.status == "EnCours" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : manifeste.status == "Cloture" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> { manifeste.status }</small> </Link></td>
                    <td>{ manifeste.vehicle?.plaque } { manifeste.vehicle?.mark?.name }</td>
                    {/* <td>{ manifeste.origine?.city }</td> */}
                    <td>{ manifeste.destination?.city }</td>
                    <td><Link to={"/profile/" + manifeste.driver?.id}>{ manifeste.driver?.name }</Link> </td>
                    <td><small>{ new Date(manifeste.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })
        return(

                    <div className="dashboard__content bg-light-2">
                           
                            <div className="breadcrumbs">
                                <Head title={'Liste des manifestes'} url={'/manifestes/'} logo={'/logo192.png'} />
                                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                                    <div className="col-auto">

                                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Manifeste ' + this.state.status}</h1>
                                        <div className="text-15 text-light-1">{ detailExpedition }</div>

                                    </div>

                                    <div className="col-auto">
                                    {
                                        this.handleCheck("ajouter manifeste") == true && (
                                            <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModalManifeste(null)}><i className="fa fa-plus text-15"></i> Nouveau manifeste</button>
                                            </div>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                                
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs row">
                                <div className="row y-gap-20 justify-between items-end">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            { status }
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <form className="row form">
                                            <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <select name="column" value={this.state.column} className="form form-control" onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                    <option></option>
                                                    <option value="ref_manifeste">par ref manifeste</option>
                                                    <option value="vehicle.plaque">par plaque</option>
                                                    {/* <option value="origine.city">par origine</option> */}
                                                    <option value="destination.city">par destination</option>
                                                    <option value="driver.name">par chauffeur</option>
                                                </select>
                                            </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getManifestes({ column : this.state.column, value : select.target.value }))) }} />
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            
                                <div className={this.state.status == "EnCours" ? "d-none" : "tabs__content pt-30 js-tabs-content "}>
                                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                        <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-3 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Réf man</th>
                                                    <th>Vehicule</th>
                                                    {/* <th>Origine</th> */}
                                                    <th>Destination</th>
                                                    <th>Chauffeur</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { manifestes}
                                            </tbody>
                                        </table>
                                        </div>

                                        <div className="">
                                            <div className="row justify-between">
                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage > 2 ? this.state.currentPage - 1 : 1)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-left text-12"></i>
                                                    </button>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="row x-gap-20 y-gap-20 items-center">

                                                    { buttons.length> 0 ?  buttons : ""}

                                                    </div>
                                                </div>

                                                <div className="col-auto">
                                                    <button onClick={() => this.changePage( this.state.currentPage < buttons.length ? this.state.currentPage + 1 : buttons.length)} className="button -blue-1 size-40 rounded-full border-light">
                                                        <i className="fa fa-chevron-right text-12"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className={ this.state.status == "EnCours" ? "tabs -underline-2 js-tabs" : "d-none"}>
                                        <div className="tabs__content pt-30 js-tabs-content">

                                            { manifestes }

                                        </div>
                                </div>
                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                        <ManifesteForm
                            isModal={this.state.isManifeste}
                            // formData={this.state.formData}
                            showModal={this.showModalManifeste}
                        />

                        </div>

      
                


        )
    }
}
export default Manifeste;
