import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import Head from "../partials/Head";
import DepenseForm from "../components/DepenseForm";
import { toast } from "react-toastify";

class Depense extends Component{

    state = {
        brancheId : null,
        isModal : false,
        formData : null,
        depenses : [],
        soldeCount : [],
        branches : [],
        currentPage : 1,
        isRecherche : false,
        loading : false
    }

    componentDidMount(){
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            brancheId : brancheId?.userprofile.brancheId
        })
        this.getDepenses(brancheId.userprofile?.brancheId);
        this.getBranches();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getDepenses(val, page = 1){
        this.setState({
            loading : true
        })
        if(val?.column)
        {
            let request = await axios.get('/depenses/$' + val?.column + '$/' + val?.value +"/"+page);
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})
        }
        else{
            let brancheId = localStorage.getItem('branche')
            brancheId = brancheId ? JSON.parse(brancheId) : {}
            if(!val)
                val = brancheId.userprofile?.brancheId    

            var request = await axios.get('depenses/brancheId/' + val  + "/" + page);
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})
             var request = await axios.get('depenses/count/brancheId');
            if(request.status === 200)
                this.setState({soldeCount : request.data, loading : false})
        }
    }

    showModal = (depense = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : depense,
        })
        if(depense == 1)
            this.getDepenses()
    }

    changeBranche = (branche = null)  => {
        this.getDepenses(branche)
        this.setState({
            currentPage : 1,
            brancheId : branche,
            isRecherche : false,
            column : ""
        })
    }

    changePage = (page) =>{
        this.setState({ currentPage : parseInt(page)})
        this.getDepenses(this.state.brancheId, parseInt(page))
    }

    deleteDepense = (depense) => {
        axios.delete("/depenses/" + depense).then((res) => {
            toast(res.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getDepenses()
        }).catch((err) => {
            console.log("error")
        })
    }
    
    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        const PAGINATION = parseInt(process.env.REACT_APP_PAGINATE)
        let brancheId, user = localStorage.getItem('user')
        user = user ?  JSON.parse(user):{}
        
        let arrayListe = this.state.branches

        let countdepenses = 0 ,  activate = "", branches = 
        arrayListe.map((branche, count) => {
            activate = ""
            if(this.state.brancheId == branche.id || branche.id == null && this.state.brancheId == null) activate = " is-tab-el-active";
            countdepenses = this.state.soldeCount.filter(val => val.brancheId == branche.id)
            countdepenses = countdepenses.length == 1 ? countdepenses[0].count : 0
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeBranche(branche.id)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + count}> { branche.city } ({ countdepenses })</button>
                </div>
            )
        })

        let buttons = [], elements = [], count = 0;
        this.state.soldeCount.filter(val => val.brancheId == this.state.brancheId).map((val, valcount) => {
            for (let index = 0; index <= val.count; index += PAGINATION) {
                count += 1
                elements.push(count)
            }
        })

        buttons = 
        elements.map((element) => {
            return(
                <div className="col-auto">
                    <div type="button" onClick={ () => this.changePage( element  ) } className={ element  == this.state.currentPage ? "size-30 flex-center rounded-full bg-dark-2 text-white" : "size-30 flex-center rounded-full bg-light-2"}>{ element }</div>
                </div>
            )
        })

        let depenses = 
        this.state.depenses.map((depense, count) => {
            brancheId = false
            if(user && user.id == depense.userId) brancheId = true

            return(
                <tr>
                    <td>{ (count + (this.state.currentPage * PAGINATION)+1)-PAGINATION}</td>
                    {/* <td><Link to={"#"}>{ depense.ref_depense }</Link></td> */}
                    <td>{ depense.mount > 0 ? depense.mount : "" }</td>
                    <td>{ depense.typedepense?.type }{ depense.typedepense?.ismanifeste ? (<small className="text-info" style={{ fontSize : 9 }}>(Commission)</small>) : depense.typedepense?.ismonth == 1 ? (<small className="text-success" style={{ fontSize : 9 }}>(Mensuelle)</small>) : depense.typedepense?.ismonth == 1 ? (<small className="text-success" style={{ fontSize : 9 }}>(Mensuelle)</small>) : depense.typedepense?.ismonth == 3 ? (<small className="text-info" style={{ fontSize : 9 }}>(Annuelle)</small>) : (<small className="text-warning" style={{ fontSize : 9 }}>(Expédition)</small>) }</td>
                    <td>{ depense.beneficer?.name } { depense.beneficer?.userprofile?.profile?.name }</td>
                    <td>{ depense.description } { 
                        <span>
                            <Link to={"/manifestes/" + depense.manifeste?.id}>{ depense.manifeste?.ref_manifeste }</Link> 
                            <Link to={"/invoices/" + depense.invoice?.id}>{ depense.invoice?.ref_invoice }</Link>
                            <small><Link to={"/profile/" + depense.invoice?.client?.id}>{ depense.invoice?.client?.name }</Link></small> 
                            <Link to={"/depenses/" + depense.depense?.id}>{ depense.depense?.ref_depense }</Link>
                        </span> }</td>
                    <td>
                        <small>{ new Date(depense.createdAt).toLocaleString("en-GB") }</small>
                        {
                            (this.state.depenses.length == count + 1 && this.handleCheck("supprimer depense") == true && brancheId) && (
                                <button type="button" onClick={ () => this.deleteDepense(depense.id)} className="btn btn-danger btn-sm">Annuler</button>
                            )
                        }
                    </td>
                 </tr>
            )
        })
        
        return(
            <div className="dashboard__content bg-light-2">
                           
            <div className="breadcrumbs">
                <Head title={'Recapitulatif depenses'} url={'/depenses/'} logo={'/logo192.png'} />
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Mouvement depenses'}</h1>
                        <div className="text-15 text-light-1">{ 'Liste des depenses' }</div>
                    </div>
                    <div className="col-auto">
                    {
                        this.handleCheck("ajouter depense") == true && (
                            <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-15"></i> Nouveau depense</button>
                            </div>
                        )
                    }
                </div>
                </div>
            </div>

            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    
                    <div className="row y-gap-20 justify-between items-end ">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                { this.handleCheck("afficher branche") != true && ( branches )}
                            </div>
                        </div>
                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })} className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_depense">par ref depense</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="beneficer.name">par beneficiaire</option>
                                        <option value="typedepense.type">par type de depense</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getDepenses({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>
                        

                        {/* <div className="col-auto">
                        { this.handleCheck("ajouter type de depense") == true && (<Link to="#" onClick={ () => this.showModalTypedepense()} ><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-plus"></i> Type depense</span></Link>)}
                        </div> */}
                    </div>

                    <div className={"tabs__content pt-30 js-tabs-content "}>
                        <div className="tabs__pane -tab-item-1 is-tab-el-active">
                            <div className="overflow-scroll scroll-bar-1">
                            <table className="table_css -border-bottom col-12">
                                <thead className="bg-light-2">
                                    <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                        {/* <th>Num</th> */}
                                        <th>N°</th>
                                        <th>Montant($)</th>
                                        <th>Type</th>
                                        <th>Beneficiaire</th>
                                        <th>Description</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { depenses }
                                </tbody>
                            </table>
                            </div>

                            <div className="">
                                <div className="row justify-between">
                                    <div className="col-auto">
                                        <button onClick={() => this.changePage(1)} className="button -blue-1 size-40 rounded-full border-light">
                                            <i className="fa fa-chevron-left text-12"></i>
                                        </button>
                                    </div>

                                    <div className="col-auto">
                                        <div className="row x-gap-20 y-gap-20 items-center">

                                            { buttons.length> 0 ?  buttons : ""}

                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <button onClick={() => this.changePage( this.state.budgets.length > 0 ? (this.state.budgets.length / PAGINATION) + 1 : PAGINATION)} className="button -blue-1 size-40 rounded-full border-light">
                                            <i className="fa fa-chevron-right text-12"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <DepenseForm
                isModal={this.state.isModal}
                formData={this.state.formData}
                brancheId={ this.state.brancheId}
                showModal={this.showModal}
            />
            
            {
                this.state.loading && (<Loading /> )
            }
      </div>
        )
    }
}
export default Depense;
