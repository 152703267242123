import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import PrintManifeste_ from "./PrintManifeste_";

class   PrintManifeste extends Component{

    state = {
        id : null,
        manifeste : null,
        depenses : [],
        branches : [],
        isModal : false,
    }
    
    async getManifeste(){
        let id = this.props.formData?.id
        let request = await axios('manifestes/' + id );
        if(request.status == 200)
            this.setState({ manifeste : request.data })
    }

    async getBranches(){
        let request = await axios('branches/' );
        if(request.status == 200)
            this.setState({ branches : request.data })
    }

    async getDepense(){
        let id = this.props.formData?.id
        var request = await axios('/depenses/manifesteId/' + id );
        if(request.status == 200)
            this.setState({ depenses : request.data  })
    }

    edit=()=>  {
        this.getBranches()
        this.getManifeste()
        this.getDepense()
    }

    render(){

        let brancheId, branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}
        brancheId = false

        if(branche && branche.userprofile?.brancheId == this.state.manifeste?.origine?.id) brancheId = true
        if(branche && branche.userprofile?.brancheId == this.state.manifeste?.destination?.id) brancheId = true

        let colis = []

        this.state.branches.map((branche) => {
            let colisArray = []
            this.state.manifeste?.manifestecolis?.sort((a, b) => a.coli?.ref_coli > b.coli.ref_coli ? 1 : -1).filter(val => val.coli?.destination?.city == branche.city).map((manifestecoli, count) => {
                let pricecolis = 0
                manifestecoli.coli?.invoices?.filter(val => val.brancheId == process.env.REACT_APP_BRANCHE_KMPL)?.map(invoice =>{
                    pricecolis += invoice.price 
                })
                colisArray.push(
                    <tr>
                        <td className="padd-0">{ manifestecoli.coli?.ref_coli } </td>
                        <td className="padd-0"><label htmlFor={count} style={{ fontSize : 9}}>{ manifestecoli.coli?.client?.name }</label></td>
                        <td className="padd-0">{ manifestecoli.coli?.client?.tel}</td>
                        <td className="padd-0">{ manifestecoli.coli?.expedie } </td>
                        <td className="padd-0"><label htmlFor={count}>{ ((manifestecoli.coli?.price || 0) - pricecolis) > 0 ? ((manifestecoli.coli?.price || 0) - pricecolis) : ""}</label></td>
                        <td className="padd-0"><label htmlFor={count}>{ pricecolis > 0 ? pricecolis : "" }</label></td>
                        <td className="padd-0">{ manifestecoli.coli?.emprunt > 0 ? manifestecoli.coli?.emprunt : "" }</td>
                        <td className="padd-0"><span className={ manifestecoli.coli?.parent?.ref_coli ? "" : "d-none"}> {manifestecoli.coli?.parent?.ref_coli} ({ (parseFloat(manifestecoli.coli?.parent?.stock) + parseFloat(manifestecoli.coli?.parent?.expedie))+" colis" })</span></td>
                    </tr>
                )
            })
            if(colisArray.length > 0)
                colis.push({ city : branche.city, list : colisArray })
        })

       
        return(
            <div>
            <Modal
                className=""
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                    <Modal.Body className="row mt-4 pt-4 p-0 m-0">
                {
                    colis.map((coliData, index) => {
                        return(
                            <PrintManifeste_ coliData={coliData} manifeste={this.state.manifeste} />
                        )
                    })
                }
                    </Modal.Body>
                    <Modal.Footer>
                    <button type="button" onClick={ () => this.props.showModal()} className="border-light rounded-100 py-5 px-20 text-14 lh-14"> <i className=""></i>Fermer </button>
                      
                </Modal.Footer>
    
            </Modal>
        </div>
        )
    }
}
export default PrintManifeste;
