import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   StaffForm extends Component{

    state = {
        id : null,
        profileId : null,
        brancheId : null,
        name : "",
        email : "",
        tel : "",
        password : "",
        adress : "",
        gender : "",
        avatar : "",
        error : "",
        status : "",

        title : "",
        profiles : [],
        branches : [],
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async getProfiles(){
        let request = await axios.get('/profiles')
        if(request.status == 200)
            this.setState({ profiles : request.data })
    }

    async getBranches(){
        let request = await axios.get('/branches')
        if(request.status == 200)
            this.setState({ branches : request.data })
    }

    storeUser = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })

        let country = localStorage.getItem("country") ?  JSON.parse(localStorage.getItem("country")) : {};
        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}
        var data = {
                brancheId :this.state.brancheId ? this.state.brancheId : branche.userprofile?.brancheId,
                profileId : this.state.profileId ? this.state.profileId : this.props.env.REACT_APP_ID_CLIENT,
                status : this.state.status ? this.state.status : "Actif",
                name : this.state.name,
                email : this.state.email ? this.state.email : null,
                tel : this.state.tel ? this.state.tel : null,
                password : "123",
                country : country?.country,
                countryData : country?.country,
                countryCode : country?.countryCode?.toLowerCase(),
                region : country?.regionName,
                city : country?.city,
                adress : this.state.adress,
                gender : this.state.gender,
        }
        var request = this.state.id ? axios.put('users/'+this.state.id, data) : axios.post('users', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(response.data?.data?.id)
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
        this.getProfiles()
        this.getBranches()
    }

    form=()=>{

        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            status: this.props.formData ? this.props.formData.userprofile?.status : "Actif",
            profileId: this.props.profileId,
            brancheId: this.props.formData?.userprofile?.brancheId,
            name: this.props.formData ? this.props.formData.name : "",
            email: this.props.formData ? this.props.formData.email : "",
            tel: this.props.formData ? this.props.formData.tel : "",
            adress: this.props.formData ? this.props.formData.adress : "",
            gender: this.props.formData ? this.props.formData.gender  || "" : "",
            title : this.props.formData ? "Modifier " + this.props.profile : "Ajouter " + this.props.profile,
            error : "",
        })
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let display = this.props.formData ? this.props.formData.id ? this.handleCheck("modifier personnel") == true ? "" : " d-none" : " d-none" : " d-none";
        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <form onSubmit={this.storeUser}>
                <Modal.Body className="row">
                    <Modal.Title>{ this.state.title}</Modal.Title>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-md-12 mt-2   mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Nom<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Email</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="email"  value={this.state.email} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Téléphone</label>
                            <div className="position-relative mt-1">
                                <input type="number" className="form-control"  name="tel"  placeholder="" onChange={this.handleInput} value={this.state.tel} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Adresse</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="adress"  value={this.state.adress} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Genre</label>
                            <div className="position-relative mt-1">
                                <select className="form-control" placeholder=""  onChange={this.handleInput} name="gender"  value={this.state.gender}>
                                    <option>....</option>
                                    <option value={"Masculin"}>Masculin</option>
                                    <option value={"Feminin"}>Feminin</option>
                                </select>
                            </div>
                        </div>
                    </div>

                {
                    display != " d-none" && (
                    <div className={"col-md-4 mt-2 mt-2 " + display} >
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Branche</label>
                            <div className="position-relative mt-1">
                                <select id="brancheId" name="brancheId"   value={this.state.brancheId} onChange={this.handleInput} className="form-select">
                                    <option value="">...</option>
                                    {
                                        this.state.branches.map((branche) => {
                                            return(
                                                <option key={ branche.id } value={ branche.id}>{ branche.city} { branche.region}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    )
                }
                    
                    {
                    display != " d-none" && (
                    <div className={"col-md-4 mt-2 mt-2 " + display} >
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Profil</label>
                            <div className="position-relative mt-1">
                                <select id="profileId" name="profileId"   value={this.state.profileId} onChange={this.handleInput} className="form-select">
                                    <option value="">...</option>
                                    {
                                        this.state.profiles.filter(val => val.id != process.env.REACT_APP_ID_SUPER_USER).map((profile) => {
                                            return(
                                                <option key={ profile.id } value={ profile.id}>{ profile.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    )}

                {
                    display != " d-none" && (
                    <div className={"col-md-4 mt-2 mt-2 " + display}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Statut</label>
                            <div className="position-relative mt-1">
                                <select id="status" name="status"   value={this.state.status} onChange={this.handleInput} className="form-select">
                                    <option value="" key={"status"}>...</option>
                                    <option value="Actif" key={"Actif"}>Actif</option>
                                    <option value="NonActif" key={"NonActif"}>NonActif</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    )}
 

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} type="button" className="btn btn-outline-secondary btn-sm"><i className="bi bi-corner-down-left"></i> Fermer</button>
                    <button type="submit" className="btn btn-sm btn-outline-primary" disabled={ this.state.btn }><i className="bi bi-check"></i> Sauvegarder</button>
                </Modal.Footer>
                </form>
            </Modal>


        </div>
        )
    }
}
export default StaffForm;
