import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   MarkForm extends Component{

    state = {
        id : null,
        name : "",
        error : "",
        isModal : false,
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    storeMark = () =>{

        this.setState({
            btn : !this.state.btn
        })

        var data = {
                name : this.state.name
        }

        var request = this.state.id ? axios.put('marks/'+this.state.id, data) : axios.post('marks', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(1)
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form();
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            name: this.props.formData ? this.props.formData.name : "",
            title : this.props.formData ? "Modifier marque " : "Ajouter marque",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    delete = (mark) =>{
        axios.delete("marks/" + mark).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.props.showModal(1)
        }).catch((error)=>{
            this.setState({
                error : error.response.data.msg
            })
        })
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val == item.name)
    }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size=""
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Body className="border border-2 mt-4 border-primary border-bottom-0">
                    <div>{ this.state.title}</div>
                    <form>
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        <div className="col-12 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Marque</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="border border-2 mt-4 border-primary border-top-0">
                    {
                    (this.handleCheck("supprimer marque") == true && this.state.id) && (
                        <a href="#" onClick={() => this.delete(this.state.id)} className="button py-5 px-5 -dark-1 bg-red-1 text-white"><i className="fa fa-trash text-white font-15"></i> Supprimer</a>
                    )}
                    <button onClick={this.props.showModal} className="button py-5 px-5 -red-1 bg-blue-1 text-white"><i className="fa fa-corner-down-left"></i> Fermer</button>
                    <button onClick={() => this.storeMark()} disabled={ this.state.btn } className="button py-5 px-5 py-5 -dark-1 bg-blue-1 text-white"><i className="fa fa-check"></i> Sauvegarder</button>
                </Modal.Footer>
            </Modal>


        </div>
        )
    }
}
export default MarkForm;
