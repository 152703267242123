import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../translations/i18n";

var permissions = JSON.parse(localStorage.getItem("permissions"))
? JSON.parse(localStorage.getItem("permissions"))
: [];

function logout() {
  axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
  window.localStorage.clear();
  window.location.replace(process.env.REACT_APP_EVIVEMANIFESTE)
}

function handleCheck(val) {
  var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
  return permissions.some(item => val === item.name)
}

export const Navbar = () =>{

  const { t, i18n } = useTranslation();
  
  let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;

      return(
          <>
            <div className="header-margin"></div>

            <header data-add-bg="" className="header -dashboard js-header" data-x="header" data-x-toggle="is-menu-opened" style={{ backgroundColor : "rgb(8, 40, 80)"}}>
              <div className="header__container px-30 sm:px-20">
                <div className="-left-side">
                  <a href="/" className="header-logo" data-x="header-logo" data-x-toggle="is-logo-dark">
                    <img src="/img/logo1.png"  style={{ width : 60}} alt="logo icon" /><b className="mt-5 text-white">LA VICTOIRE EXPRESS</b>
                  </a>
                </div>

                <div className="row justify-between items-center pl-60 lg:pl-20">
                  <div className="col-auto">
                    <div className="d-flex items-center">
                      <button data-x-click="dashboard" type="button" className={ (user && handleCheck("afficher dashboard")) ? "" : "d-none"}>
                        <i className="fa fa-bars text-white"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="d-flex items-center">

                      <div className="header-menu " data-x="mobile-menu" data-x-toggle="is-menu-active">
                        <div className="mobile-overlay"></div>

                        <div className="header-menu__content">
                          <div className="mobile-bg js-mobile-bg"></div>

                          <div className="menu js-navList">
                            <ul className="menu__nav text-dark-1 fw-500 -is-active">

                              <li className="text-white">
                                <a href="/">Accueil</a>
                              </li>

                              {
                                (user && handleCheck("afficher mes recus")) && (
                                  <li className="text-white">
                                    <a href="/recus">Reçu </a>
                                  </li>
                                )
                              }
                              {
                                (user && handleCheck("afficher mes factures")) && (
                                  <li className="text-white">
                                    <a href="/factures">Facture</a>
                                  </li>
                                )
                              }
                              {
                                (user && handleCheck("afficher mes expeditions")) && (
                                  <li className="text-white">
                                    <a href="/expeditions">Expéditions</a>
                                  </li>
                                )
                              }
                              <li className="text-white">
                                <a href="#">Apropos</a>
                              </li>

                              <li className="text-white">
                                <a href="/contacts">Contact</a>
                              </li>
                              

                              <li className="text-white">
                                { user ? <a href="#" onClick={ () => logout()}>Déconnexion</a> : <a href="/login">Connexion</a> }
                              </li>
                            </ul>
                          </div>

                          <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer">
                          </div>
                        </div>
                      </div>


                      {
                        user && (
                          <div className="pl-15 text-white">
                            <Link to="/profile"><img src={ user ? user.avatar : "/img/misc/avatar-1.png"} alt="image" className="size-50 rounded-22 object-cover" /></Link>
                          </div>
                        )
                      }

                      <div className="d-none xl:d-flex x-gap-20 items-center pl-20" data-x="header-mobile-icons" data-x-toggle="text-white">
                        <div><button  type="button" className="d-flex items-center text-20" data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"><i className="fa fa-bars text-white"></i></button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
        </>
        )
    }