import axios from "axios";
import React, {Component} from "react";
import VehicleForm from "../components/VehicleForm";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import Head from "../partials/Head";
import MarkForm from "../components/MarkForm";
import { toast } from "react-toastify";

class Vehicle extends Component{

    state = {
        isModal : false,
        isModalMark : false,
        formData : null,
        formDataMark : null,
        vehicles : [],
        marks : [],
        markId : null,
        loading : false
    }

    componentDidMount(){
        this.setState({ markId : process.env.REACT_APP_ID_CLIENT})
        this.getMarKs();
        this.getVehicles();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getMarKs(){
        let request = await axios.get('/marks');
        if(request.status == 200)
            this.setState({ marks : request.data})
    }

    async getVehicles(){
        this.setState({
            loading : true
        })
        var request = await axios.get('/vehicles');
        if(request.status === 200)
        {
            this.setState({vehicles : request.data, isModal : false, loading : false})
        }
    }

    showModal = (vehicle = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : vehicle,
        })
        if(vehicle == 1)
        this.getVehicles()
        this.getMarKs()
    }

    showModalMark = (mark= null) => {
        this.setState({
            isModalMark : !this.state.isModalMark,
            formDataMark : mark
        });
        if(mark == 1)
            this.getMarKs()
    }

    delete = (vehicle) =>{
        axios.delete("vehicules/" + vehicle).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getVehicles()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let activate = "";
        let marks = 
        this.state.marks.map((mark, count) => {
            activate = ""
            if(this.state.markId == mark.id) activate = " is-tab-el-active";

            return(
                <div className="col-auto">
                    <button onClick={ () => this.showModalMark(mark)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " }  data-tab-target={".-tab-item-" + count}> { mark.name } </button>
                </div>
            )
        })
        
        var vehicles =
        this.state.vehicles.map((vehicle, count) => {
            return(
                <tr>
                    <td>{ count+1}</td>
                    <td><Link to="#" onClick={ () => { this.handleCheck("modifier vehicule") == true && ( this.showModal(vehicle) ) } }> { vehicle.plaque }</Link> </td>
                    <td>{ vehicle.mark.name }</td>
                    <td>{ vehicle.groupe }</td>
                    <td>{ vehicle.description }</td>
                </tr>
            )
        })
        return(
         
                    
                    <div className="dashboard__content bg-light-2">
                           
                            <div className="breadcrumbs">
                                <Head title={'Liste vehicules'} url={'/vehicles/'} logo={'/logo192.png'} />
                                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                                    <div className="col-auto">

                                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Vehicules'}</h1>
                                        <div className="text-15 text-light-1">{ 'Liste des vehicules' }</div>

                                    </div>

                                    <div className="col-auto">
                                        {
                                            this.handleCheck("ajouter vehicule") == true && (
                                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-15"></i> Nouveau vehicle</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                                
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs">
                                <div className="row y-gap-20 justify-between items-end ">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            { this.handleCheck("afficher marque") == true && ( marks )}
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                    { this.handleCheck("ajouter marque") == true && (<Link to="#" onClick={ () => this.showModalMark()} ><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-plus"></i> marque</span></Link>)}
                                    </div>
                                </div>
                            
                                <div className="tabs__content pt-30 js-tabs-content">
                                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                        <div className="overflow-scroll scroll-bar-1">
                                        <table className="table-3 -border-bottom col-12">
                                            <thead className="bg-light-2">
                                                <tr>
                                                    <th>Num</th>
                                                    <th>Plaque</th>
                                                    <th>Marque</th>
                                                    <th>Groupe</th>
                                                    <th>Description</th>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { vehicles}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                  

                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                        <VehicleForm
                            isModal={this.state.isModal}
                            formData={this.state.formData}
                            showModal={this.showModal}
                            getVehicule={this.getVehicles}
                        />

                        <MarkForm
                            isModal={this.state.isModalMark}
                            formData={this.state.formDataMark}
                            showModal={this.showModalMark}
                        />

                        </div>

      
                


        )
    }
}
export default Vehicle;
