import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import InvoiceDesign from "../components/InvoiceDesign";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class ReportColiclient extends Component{

    state = {
        colis : [],
        value : null,
        branches : [],
        coliCount : [],
        typeColi : "EnStock",
        formDataInvoice : false,
        isInvoiceModal : false,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getColis();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getColis();
      };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getColis(val = null){
        this.setState({
            colis : [],
            loading : true
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        
        let status = val ? val : this.state.typeColi
        let request = await axios.get('/colis/clients/' + start + '/' + end + '/' + status + '/' + this.props.formData?.clientId);
        if(request.status === 200)
            this.setState({colis : request.data, loading : false})

        request = await axios.get('/colis/count/clients/' + start + '/' + end +'/' + this.props.formData?.clientId);
        if(request.status === 200)
            this.setState({coliCount : request.data})
        
    }

    showModalInvoice = (val, type) =>{
        let data = {}
        if(val && val != 1)
        {
            data = {
                id : val.id,
                ref_number : type == "FACTURE" ? val.invoice?.ref_invoice : val.ref_coli,
                due : val.invoice?.due,
                typeColi : type,
                type : type
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        if(val == 1)
            this.getColis()
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeStatus = (type)  => {
        this.getColis(type)
        this.setState({
            typeColi : type,
            isRecherche : false,
            column : ""
        })
    }

    render(){
        
        let activate = "", countColis = 0;
        let status = 
        [{ val :  'EnStock', title : "Coli en attente dans le stock"}, { val : "Manifeste", title : "Coli enregisté sur manifeste a credit"},  { val : 'Paye', title : "Coli payé enregisté sur facture"}].map((statut, count) => {
            activate = ""
            if(this.state.typeColi == statut.val) activate = " is-tab-el-active";
            countColis = this.state.coliCount.filter(val => val.status == statut.val)
            countColis = countColis.length == 1 ? countColis[0].count : 0
            return(
                <div className="col-auto" key={ statut.val}>
                    <button onClick={ () => this.changeStatus(statut.val)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)} title={statut.title}> { statut.val } ({ countColis })</button>
                </div>
            )
        })
        

        let tColis = 0, tPrice = 0, tDue = 0
        let colis =
        this.state.colis.filter(val => val.ref_coli == this.state.search || !this.state.search).map((coli, count) => {
            tColis += coli?.quantity
            tPrice += coli?.price
            tDue += coli?.invoice?.due || 0
            return(
                <tr key={ coli.id }>
                    <td>{ count+1} </td>
                    { (<td><Link to="#" onClick={ () => this.showModalInvoice(coli, "BORDEREAU")}>{ coli.ref_coli } </Link></td>) }
                    { this.state.typeColi != "EnStock" &&( <td><Link onClick={ () => this.showModalInvoice(coli, "FACTURE")}>{ coli.invoice?.ref_invoice } <small className={ coli.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> <span className={ coli.invoice?.type == "Dette" && ("text-danger")}> { coli.invoice?.type } { coli.invoice?.due > 0 ? coli.invoice?.due : "" } </span> </small></Link></td>)}
                    { this.state.typeColi != "EnStock" &&(<td>
                        <label htmlFor={count}>
                        {
                            coli.manifestecolis.map(manifestecoli => {
                                return(
                                    <Link to={"/manifestes/" + manifestecoli.manifeste?.id }>{ manifestecoli.manifeste?.ref_manifeste } <small className={ coli.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}>{ manifestecoli.manifeste?.status} </small></Link>
                                )
                            })
                        }
                        </label></td>)
                    }
                    <td><label htmlFor={count}>{ coli.quantity }.<small>{ coli?.destination?.country } { coli?.destination?.city }</small></label></td>
                    <td><label htmlFor={count}>{ coli.invoice?.due > 0 ? <span><span className="text-danger">{coli.invoice.due}</span> /{coli.price}</span> : coli.price }</label></td>
                    <td><label htmlFor={count}>{ coli.colidetails.map((colidetail) => { return (<span>{ colidetail.quantity}{ colidetail.unite?.name} { colidetail.article?.name}, </span>)  }) }</label></td>
                    <td><small>{ new Date(coli.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })

        return(
            <div className="dashboard__content bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             { status}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, search : null })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { ( select.target.value?.length > 2 && (this.setState({ search : select.target.value }))) }} placeholder="ref_Coli" />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)}>
                        <div className=" text-center text-primary">
                            <HeadDoc title={"Colis " + this.props.formData?.name } />
                        </div> <div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                                <table className={ (this.state.typeColi == "EnStock" ? "table-3" : "table_css") +" -border-bottom col-12"}>
                                    <thead className="bg-light-2">
                                        <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                            <th>#</th>
                                            { (<th>Réf colis</th>) }
                                            { this.state.typeColi != "EnStock" &&(<th>Réf fac</th>)}
                                            { this.state.typeColi != "EnStock" &&(<th>Réf Mani</th>)}
                                            <th title="Nombre des colis">Total coli</th>
                                            <th>Prix($)</th>
                                            <th>Contenus</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { colis }
                                        <tr>
                                            <th></th>
                                            <th>Total</th>
                                            { this.state.typeColi != "EnStock" &&(<th></th>)}
                                            { this.state.typeColi != "EnStock" &&(<th></th>)}
                                            <th>{ tColis }</th>
                                            <th>{ tDue > 0 ? <span><span className="text-danger">{ tDue }</span> / { tPrice } </span> : tPrice }</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default ReportColiclient;
