import axios from "axios";
import React, {Component} from "react";
import Login from "./Login";
import LoginGoogle from "./LoginGoogle";
import ReactLoading from "react-loading";
  
class Register extends Component {

    state = {
        name : "",
        tel : "",
        password : "",
        confPassword : "",
        error : "",
        click : false,
        checked : true,
        login : false
    }
    
    componentDidMount(){
        window.localStorage.clear();
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            click : false
        });
    }

    createLogin = () =>{
        this.setState({ login : !this.state.login})
    }

    register  = () => {
        this.setState({
            click : true,
            error : ""
        })
        if(this.state.password == this.state.confPassword)
        {
            axios.post('register', {
                name: this.state.name,
                tel: this.state.tel,
                password : this.state.password,
                conf_password : this.state.confPassword
            }).then((login) => {
                var user = (login.data.data);
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("branche", JSON.stringify(login.data.branche ? login.data.branche : {}) );
                localStorage.setItem("permissions", JSON.stringify(login.data.permissions ? login.data.permissions : []) );
                window.location.replace(process.env.REACT_APP_EVIVEMANIFESTE)
            }).catch((error) => {
                window.localStorage.clear();
                this.setState({
                    click: false,
                    error: error.response.data.msg,
                });
            })
        }
        else{
            this.setState({
                click : false,
                error:  "Mot de passes differents"
            });
        }
    }

    render(){
        return(
            <div>
                {
                    this.state.login && (<Login />)
                }
                {  !this.state.login && (
                    <div className="dashboard__content bg-light-2" style={{backgroundImage: "url('img/background.png')", backgroundSize: "cover", backgroundPosition: "center center"}}>
                     
                        <div className="container-md">
                            <div className="row vh-50 d-flex justify-content-center">
                                <div className="col-12 align-self-center">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4 mx-auto">
                                                <div className="card mb-4 rounded rounded-3 border-white shadow">
                                                    
                                                    <div className="card-body pt-0">                                    
                                                        <form className="my-4 form" onSubmit={this.register}>  
                                                            {this.state.error && (<div className="alert alert-danger text-center text-red-1 py-1 px-2 text-small">{this.state.error.type ? this.state.error.type + " error" : this.state.error}</div>)}          
                                                            <div className="form-group mb-2">
                                                                <label className="form-label" htmlFor="name">Nom</label>
                                                                <input type="text" className="form-control" id="name" name="name" onChange={this.handleInput} placeholder="" />                               
                                                            </div>

                                                            <div className="form-group mb-2">
                                                                <label className="form-label" htmlFor="tel">Téléphone</label>
                                                                <input type="number" className="form-control" id="tel" name="tel"  onChange={this.handleInput} placeholder="" />                               
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="userpassword">Mot de passe</label>                                            
                                                                <input type="password" className="form-control" name="password" onChange={this.handleInput} id="userpassword" placeholder="" />                            
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="form-label" htmlFor="confPassword">Confirmer votre mot de passe</label>                                            
                                                                <input type="password" className="form-control" name="confPassword" onChange={this.handleInput} id="confPassword" placeholder="" style={{ border : "1px solid white"}}/>                            
                                                            </div>
                                
                                                            <div className="form-group mb-0 row">
                                                                <div className="col-12">
                                                                    <div className="d-grid mt-3">
                                                                        <button className="button py-5 -dark-1 bg-blue-1 text-white" type="button" onClick={ () => this.register() }>Enregister { !this.state.click ? <i className="fa fa-lock font-20 ms-1"></i> : <ReactLoading type="spokes"  className="float-end text-orangered" height={15} width={15} />}</button>
                                                                    </div>
                                                                </div>
                                                            </div>                           
                                                        {/* <div className="text-end text-primary"><a href="#" onClick={() => this.createLogin()}>J'ai déjà un compte</a> </div> */}
                                                        </form>
                                                        
                                                        
                                                        <hr className="hr-dashed mt-4" />
                                                         
                                                        <div className="d-flex justify-content-center mb-1">
                                                             
                                                            <div className="mt-2 text-center d-none">
                                                                <LoginGoogle />
                                                            </div>  
                                                        </div>
                                                        <div className="text-center"><h6 className="card-bg text-center text-blue-1 d-inline-block" type="button" onClick={() => this.createLogin()}>J'ai déjà un compte</h6></div>
                                                    </div>{/*end card-body*/}
                                                </div>{/*end card*/}
                                            </div>{/*end col*/}
                                        </div>{/*end row*/}
                                    </div>{/*end card-body*/}
                                </div>{/*end col*/}
                            </div>{/*end row*/}
                        </div>{/*end container*/}

                        {/* App js */}
                        
                    </div>
                )}
            </div>
        )
    }
}
export default Register