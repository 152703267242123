import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import TypedepenseForm from "./TypedepenseForm";
import { toast } from "react-toastify";
import StaffForm from "./StaffForm";

class   DepenseForm extends Component{

    state = {
        id : null,
        typedepenseId : null,
        beneficerId : null,
        manifesteId : null,
        budgetId : null,
        mountManifeste : 0,
        mount : "",
        description : "",
        error : "",
        month : 0,
        liste : [],

        title : "",
        isTypedepense : false,
        typedepenses : [],
        manifestes : [],
        manifesteVehicules : [],
        users : [],
        btn  : false,
        isUser : false,
        ismanifeste : false
    }

    handleInput = (e) => {
        this.setState({
            btn : false,
            [e.target.name]: e.target.value
        });
    }

    async getBudgets(){
        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{} 
        branche = branche.userprofile?.brancheId
        if(this.props.brancheId == branche){
            let request = await axios.get('/budgets/brancheId/' + branche );
            if(request.status === 200)
                this.setState({manifestes : request.data })
        }
    }

    async getBudgetVehicules(){
        let request = await axios.get('/budgets/brancheId/' + this.props.brancheId );
        if(request.status === 200)
            this.setState({manifesteVehicules : request.data })
    }

    async getTypedepenses(){
        let data = await axios.get('/typedepenses')
        if(data.status == 200)
            this.setState({ typedepenses : data.data })
    }

    async getUsers(){
        let data = await axios.get('/users')
        if(data.status == 200)
            this.setState({ users : data.data })
    }

    showModalTypedepense = (typedepense= null) => {
        this.setState({
            isTypedepense : false,
        });
        if(typedepense){
            this.setState({ typedepenseId : typedepense})
            this.getTypedepenses()
        }
    }


    addListe = () => {
        let liste = this.state.liste
        let mount = this.state.mountManifeste - this.state.mount
        if(this.state.mount > 0 && this.state.beneficerId && this.state.typedepenseId || this.state.month == 1 || this.state.month == 3)
        {
            if(mount >= 0 && this.state.month == 0 || this.state.month == 1 || this.state.month == 3)
            {
                liste.push({
                    budgetId : this.state.budgetId,
                    manifesteId : this.state.manifesteId,
                    mount : this.state.mount,
                    beneficerId : this.state.beneficerId,
                    typedepenseId : this.state.typedepenseId,
                    description : this.state.description
                })
                this.setState({
                    mountManifeste : mount,
                    mount : 0,
                    beneficerId : "",
                    typedepenseId : "",
                    description : "",
                })
            }else
                this.setState({ error : "Montant insuffisant " + this.state.mount })
        }
    }

    storeTypedepense = (e) =>{
        e.preventDefault();

        this.setState({
            btn : !this.state.btn
        })
        
        let branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}

        this.state.liste?.map((list) => {
            var data = {
                budgetId : list.budgetId,
                typedepenseId : list.typedepenseId,
                beneficerId : list.beneficerId ,
                mount : list.mount,
                description : list.description,
                brancheId : (this.state.month == 1 || this.state.month == 3) ? this.props.brancheId : branche.userprofile?.brancheId,
                manifesteId : list.manifesteId
            }
    
            var request = this.state.id ? axios.put('depenses/'+this.state.id, data) : axios.post('depenses', data);
            request.then((response) =>{
                this.setState({
                    liste : [],
                    btn : !this.state.btn
                })
                toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
                this.props.showModal(1)
            }).catch((error) => {
                this.setState({
                    error : error.response.data.msg,
                    btn : !this.state.btn
                })
                toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            })
        })
    }

    showModalUser = (val= null) => {
        this.setState({
            isUser : false,
        });
        if(val != null && !val?.id){
            this.setState({ beneficerId : val})
            this.getUsers()
        }
    }
    
    edit=()=>  {
        this.form()
        this.getTypedepenses()
        this.getUsers()
        this.getBudgets()
        this.getBudgetVehicules()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            liste : [],
            beneficerId: this.props.formData ? this.props.formData.beneficerId : null,
            manifesteId: this.props.formData ? this.props.formData.manifesteId : null,
            description: this.props.formData ? this.props.formData.description : "",
            mount: this.props.formData ? this.props.formData.mount : null,
            typedepenseId: this.props.formData ? this.props.formData.typedepenseId : null,
            title : this.props.formData ? "Depense" : "Ajouter depense",
            error : "",
        })
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){

        let optionUsers = [{ value : null, label : "Nouveau bénéficiaire"}]
        this.state.users.filter(user => user.userprofile?.profileId != process.env.REACT_APP_ID_SUPER_USER).map((user) => {
            optionUsers.push({
                label : user.name + " (" + user.userprofile?.profile?.name+")",
                value : user.id
            })
        })

        let optionManifestes = [], optionManifestevehicules = []
        this.state.manifestes.map((budget) => {
            optionManifestes.push({
                mount : budget.restant,
                label : budget.manifeste?.ref_manifeste + ", " + budget.manifeste?.vehicle?.plaque + " " + budget.manifeste?.vehicle?.mark?.name + ", " + budget.manifeste?.driver?.name +" (" + budget.restant + "$)",
                value : budget?.id,
                manifesteId : budget.manifeste?.id
            })
        })

        this.state.manifesteVehicules.map((budget) => {
            optionManifestevehicules.push({
                value : budget.manifeste?.id,
                label : budget.manifeste?.vehicle?.plaque + " " + budget.manifeste?.vehicle?.mark?.name  +" # " + budget.manifeste?.ref_manifeste
            })
        })

        let optionTypedepenses = [{ value : null, label : "Nouveau type de depense"}]
        this.state.typedepenses.filter(val => !val.ismanifeste && val.ismonth == this.state.month && (val.id != process.env.REACT_APP_ID_DEPENSE_COMPTE_VEHICULE &&  val.id != process.env.REACT_APP_ID_DEPENSE_RESERVE)).map((typedepense) => {
            optionTypedepenses.push({
                label :  typedepense.type + " " +(typedepense.ismanifeste ? "(Commission)" : typedepense.ismonth == 1 ? "(Mensuelle)" : typedepense.ismonth == 3 ? "(Annuelle)" : ("(Expédition)")) ,
                value : typedepense.id,
                ismanifeste : typedepense.ismanifeste
            })
        })

        let depenses_ = []
        let budgets = 
        this.state.manifestes.filter(val => val.id == this.state.budgetId)?.map((budget) => {
            depenses_ = budget.depenses;
             return(
                budget.depenses?.map((depense) => {
                    return(
                        <tr>
                            <td>{ depense.mount }$</td>
                            <td>{ optionUsers.filter(val => val.value == depense.beneficerId)[0]?.label }</td>
                            <td>{ this.state.typedepenses.filter(val => val.id == depense.typedepenseId)[0]?.type }</td>
                            <td>{ depense.description  }</td>
                        </tr>
                    )
                })
            )
        })

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <form onSubmit={this.storeTypedepense}>
                <Modal.Body className="row">
                    <Modal.Title>{ this.state.title }</Modal.Title>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    
                    <div className="">
                        <label htmlFor="year" className={ this.handleCheck("ajouter depense mensuelle") == true ?  "h6 font-monospace" : "d-none" }>Depense Annuelle<input type="radio" id="year" placeholder=""  onChange={ () => this.setState({ month : 3 }) } name="month"  value={this.state.month} /></label>
                        <label htmlFor="ismonth1" className={ this.handleCheck("ajouter depense mensuelle") == true ?  "h6 font-monospace" : "d-none" }>Depense mensuelle<input type="radio" id="ismonth1" placeholder=""  onChange={ () => this.setState({ month : 1 }) } name="month"  value={this.state.month} /></label>
                        <label htmlFor="ismonth2" className="h6 font-monospace">Depense par expedition<input type="radio" defaultChecked={true} id="ismonth2" placeholder=""  onChange={() => this.setState({ month : 0 })} name="month"  value={this.state.month} /></label>
                    </div>

                    <div className={((this.state.month == 0) || this.state.id && this.props.formData?.manifeste?.ref_manifeste) ? "col-md-12 mt-2 mt-2 " :"d-none"}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Manifeste à destination {  this.state.mountManifeste }$ <span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className={ (this.state.month == 0) ? "form-control form-control-sm p-0" : "d-none"}
                                    value = {
                                        optionManifestes.filter(option => 
                                            this.state.budgetId != null && this.state.budgetId === option.value)
                                    }
                                    defaultValue={ this.state.budgetId }
                                    placeholder={ "Selectionner le manifeste*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                mountManifeste : select.mount,
                                                budgetId : select.value,
                                                liste : [],
                                                manifesteId : select.manifesteId
                                            })
                                        }
                                    }
                                    options={optionManifestes}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={((this.state.month == 1) || (this.state.month == 3)) ? "col-md-12 mt-2 mt-2 " :"d-none"}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Vehicule <span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className={ (this.state.month == 1 || this.state.month == 3) ? "form-control form-control-sm p-0" : "d-none"}
                                    value = {
                                        optionManifestevehicules.filter(option => 
                                            this.state.manifesteId != null && this.state.manifesteId == option.value
                                        )
                                    }
                                    defaultValue={ this.state.manifesteId }
                                    placeholder={ "Selectionner le vehicule*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                manifesteId : select.value
                                            })
                                        }
                                    }
                                    options={optionManifestevehicules}
                                />
                                
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2   mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Montant<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="mount"  value={this.state.mount} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Description</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="description"  value={this.state.description} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-6 mt-2 mt-2 "}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Type de depense<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionTypedepenses.filter(option => 
                                            this.state.typedepenseId != null && this.state.typedepenseId === option.value)
                                    }
                                    defaultValue={ this.state.typedepenseId }
                                    placeholder={ "Selectionner type depense*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                isTypedepense : select.value == null ? true : false,
                                                typedepenseId : select.value,
                                                ismanifeste : select.ismanifeste
                                            })
                                        }
                                    }
                                    options={optionTypedepenses}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-6 mt-2 mt-2 "}>
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Beneficiaire<span className="text-danger">*</span></label>
                            <div className="position-relative mt-1">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionUsers.filter(option => 
                                            this.state.beneficerId != null && this.state.beneficerId === option.value)
                                    }
                                    defaultValue={ this.state.beneficerId }
                                    placeholder={ "Selectionner un beneficiaire*"}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                isUser : select.value == null ? true : false,
                                                beneficerId : select.value,
                                            })
                                        }
                                    }
                                    options={optionUsers}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={ (depenses_.length == 0 && this.handleCheck("ajouter depense") || depenses_.length >= 0 && this.handleCheck("modifier depense") ) ? "text-end" :"d-none"}>
                        <button className="btn btn-sm btn-primary" type="button" onClick={ () => this.addListe()}><i className="fa fa-plus"></i> Ajouter</button>
                    </div>

                    <div className="mt-5">
                        <table className="table_css_invoice_">
                            <thead>
                                <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                    <th>Montant</th>
                                    <th>Beneficiaire</th>
                                    <th>Depense</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                { budgets}
                                {
                                    this.state.liste?.map((list) => {
                                        return(
                                            <tr>
                                                <td>{ list.mount }$</td>
                                                <td>{ optionUsers.filter(val => val.value == list.beneficerId)[0]?.label }</td>
                                                <td>{ this.state.typedepenses.filter(val => val.id == list.typedepenseId)[0]?.type }</td>
                                                <td>{ list.description  }</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
 

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} type="button" className="btn btn-outline-secondary btn-sm"><i className="bi bi-corner-down-left"></i> Fermer</button>
                    <button type="submit" className={ this.state.id ? "d-none" : "btn btn-sm btn-outline-primary" } disabled={ this.state.btn }><i className="bi bi-check"></i> Valider</button>
                </Modal.Footer>
                </form>
            </Modal>

            <StaffForm
                isModal={this.state.isUser}
                profile={"Personne externe"}
                profileId={ process.env.REACT_APP_ID_EXTERNE}
                showModal={this.showModalUser}
            />
            <TypedepenseForm
                isModal={this.state.isTypedepense}
                showModal={this.showModalTypedepense}
            />
        </div>
        )
    }
}
export default DepenseForm;
