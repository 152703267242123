import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
import Reportsyntheserec from "./Reportsyntheserec";
import Reportsortiemensuel from "./Reportsortiemensuel";
import ReportGestioncaisse from "./ReportGestioncaisse";
import ReportReserve from "./ReportReserve";
const moment = extendMoment(originalMoment);

class Solde extends Component{

    state = {
        depenses : [],
        typedepenses : [],
        value : null,
        branches : [],
        depenseCount : [],
        brancheId : null,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getDepenses();
        this.getTypedepenses();
        this.getBranches();
        let brancheId = localStorage.getItem('branche')
        brancheId = brancheId ? JSON.parse(brancheId) : {}
        this.setState({
            brancheId : brancheId?.userprofile.brancheId
        })
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getDepenses();
    };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getTypedepenses(){
        let request = await axios.get("/typedepenses")
        if(request.status == 200)
            this.setState({ typedepenses : request.data?.sort((a, b) => a.type > b.type ? 1 : -1).filter(a => a.ismonth == 0)})
    }

    async getDepenses(val = null){
        this.setState({
            depenses : [],
            loading : true
        })
        
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD 00:00');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');

        let date =  moment.range(startOfMonth, endOfMonth)

        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
       
       
        if(val?.column)
        {
            let request = await axios.get('/depenses/$' +  val?.column +"$/" + start + '/' + end + '/null');
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.brancheId
            let request = await axios.get('/depenses/brancheId/' + start + '/' + end + '/null');
            if(request.status === 200)
                this.setState({depenses : request.data, loading : false})

            request = await axios.get('/depenses/count/' + start + '/' + end );
            if(request.status === 200)
                this.setState({depenseCount : request.data})
        }
    }


    changeStatus = (branche)  => {
        this.getDepenses(branche)
        this.setState({
            brancheId : branche,
            isRecherche : false,
            column : ""
        })
    }

    render(){
        
        
        let manifestes = []
        this.state.depenses.map((depense, count) => {
            if(depense.manifeste?.id && manifestes.filter(val => val?.id == depense.manifeste?.id)?.length == 0)
                manifestes.push(depense.manifeste)
        })

        return(
            <div className=" bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             {/* { status } */}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1 d-none">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_depense">par ref depense</option>
                                        <option value="manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="typedepense.type">par type depense</option>
                                        <option value="beneficer.name">par beneficiaire</option>
                                        <option value="user.name">par responsable</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getDepenses({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                    
                    <div ref={el => (this.componentRef = el)}>
                        <div className=" text-center text-primary">
                            <HeadDoc title={"Sythese périodique" } />
                        </div><div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                                <table className="table_css -border-bottom col-12">
                                    <thead>
                                        <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                            <th>LIBELLES</th>
                                            {
                                                manifestes?.map((manifest) => {
                                                    return(
                                                        <th>{ manifest?.ref_manifeste} / { manifest?.driver?.name }</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.branches?.map((branche) =>{
                                                return(<>
                                                    <tr style={{ color : "rgb(240, 100, 60)", fontWeight : "800"}}>
                                                        <td align="center" colSpan={ (manifestes.length + 1) }>{ branche?.city }</td>
                                                    </tr>
                                                    {
                                                        this.state.typedepenses.filter(val => (val.id != process.env.REACT_APP_ID_DEPENSE_COMPTE_VEHICULE &&  val.id != process.env.REACT_APP_ID_DEPENSE_RESERVE) )?.map((typedepense) => {
                                                            if(this.state.depenses.filter(type => branche.id == type.brancheId && type.typedepense?.id == typedepense.id).length > 0)
                                                            {
                                                                return(
                                                                    <tr>
                                                                        <td>{ typedepense.type }</td>
                                                                        {
                                                                            manifestes?.map((manifest) => {
                                                                                let mountManifeste = 0
                                                                                this.state.depenses.filter(type => branche.id == type.brancheId && type.typedepense?.id == typedepense.id && type.manifeste?.id == manifest.id)?.map((depens) => {
                                                                                     mountManifeste += depens.mount
                                                                                })
                                                                                return(
                                                                                    <td>{ mountManifeste > 0 ? mountManifeste.toFixed(2) + "$" : "" }</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <tr style={{ color : "rgb(0, 0, 160)", fontWeight : "800"}}>
                                                        <th>Total</th>
                                                        {
                                                            manifestes?.map((manifest) => {
                                                                let mountManifeste = 0
                                                                this.state.depenses.filter(type => type.typedepense?.ismonth == 0 && type.manifeste?.id == manifest.id && type.brancheId == branche.id && (type.typedepense.id != process.env.REACT_APP_ID_DEPENSE_COMPTE_VEHICULE &&  type.typedepense.id != process.env.REACT_APP_ID_DEPENSE_RESERVE))?.map((depens) => {
                                                                        mountManifeste += depens.mount
                                                                })
                                                                return(
                                                                    <td>{ mountManifeste > 0 ? mountManifeste.toFixed(2) + "$" : "" }</td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </>)
                                            })
                                        }
                                    </tbody>
                                </table>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            
            <div className="col-md-12 p-0 pt-20 pb-10 mt-10 mb-10 pb-10">
                <Reportsyntheserec />
            </div>

            <div className="col-md-12 p-0 pt-20 pb-10 mt-10">
                <Reportsortiemensuel />
            </div>

            <div className="col-md-12 p-0 pt-20 pb-10 mt-10">
                <ReportGestioncaisse />
            </div>

            <div className="col-md-12 p-0 pt-20 pb-10 mt-10">
                <ReportReserve  typedepenseId={ process.env.REACT_APP_ID_DEPENSE_COMPTE_VEHICULE } title={"Compte vehicule"} />
            </div>

            <div className="col-md-12 p-0 pt-20 pb-10 mt-10">
                <ReportReserve  typedepenseId={ process.env.REACT_APP_ID_DEPENSE_RESERVE } title={ "Reserve"} />
            </div>
            
            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
      </div>
        )
    }
}
export default Solde;
