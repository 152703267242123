import axios from "axios";
import React, {Component} from "react";
import StaffForm from "../components/StaffForm";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import Head from "../partials/Head";
import { toast } from "react-toastify";

class Staff extends Component{

    state = {
        isModal : false,
        formData : null,
        staffs : [],
        profiles : [],
        profileId : null,
        profile : "Chauffeur",
        isRecherche : false,
        loading : false
    }

    componentDidMount(){
        this.setState({ profileId : process.env.REACT_APP_ID_DRIVER})
        this.getProfiles();
        this.getStaffs();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getProfiles(){
        let request = await axios.get('profiles');
        if(request.status == 200)
            this.setState({ profiles : request.data})
    }

    async getStaffs(profile){
        this.setState({
            loading : true
        })
        if(profile?.column)
        {
            let request = await axios.get('/staffs/$' + profile?.column + '$/' + profile?.value);
            if(request.status === 200)
                this.setState({staffs : request.data, loading : false})
        }
        else{
            let id = profile ? profile : process.env.REACT_APP_ID_DRIVER
            var staffs = await axios.get('/staffs/$userprofile.profileId$/' + id);
            if(staffs.status === 200)
            {
                this.setState({staffs : staffs.data, loading : false})
            }
        }
    }

    showModal = (user = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : user,
        })
        this.changeProfile()
    }

    changeProfile = (profile= false) => {
        this.setState({
            staffs : [],
            loading : true,
            profileId : profile ? profile.id : this.state.profileId,
            profile : profile ?  profile.name : this.state.profile,
            isRecherche : false,
            column : ""
        });
        
        let profileId = profile ? profile.id : this.state.profileId
        this.getProfiles()
        this.getStaffs(profileId)
    }

    delete = (user) =>{
        axios.delete("users/" + user).then((response) => {
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
            this.getStaffs()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let activate = "";
        let profiles = 
        this.state.profiles.filter(dat => dat.id != process.env.REACT_APP_ID_CLIENT).map((profile, count) => {
            activate = ""
            if(this.state.profileId == profile.id) activate = " is-tab-el-active";

            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeProfile(profile)} className={"tabs__button text-12 lg:text-12 text-light-1 fw-500 pb-2 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + count}> { profile.name } ({profile.userprofiles.length})</button>
                </div>
            )
        })
        
        var staffs =
        this.state.staffs.map((user, count) => {
                if(this.state.profileId == process.env.REACT_APP_ID_DRIVER || this.state.profileId == process.env.REACT_APP_ID_EXTERNE){
                    return(
                        <tr>
                            <td>{ count+1}</td>
                            <td>{ user.name }</td>
                            <td><a  href={ 'tel:'+user.tel }>{ user.tel }</a> <a  href={ 'mail:'+user.email }>{ user.email }</a> </td>
                            <td>{ user.adress }</td>
                            <td>
                                <div className={"dropdown js-dropdown js-actions-" + count + "-active"}>
                                    <div className="dropdown__button d-flex items-center rounded-4 text-blue-1 bg-blue-1-05 text-14 px-15 py-5" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="js-dropdown-title">Actions</span>
                                        <i className="fa fa-chevron-down text-7 ml-10"></i>
                                    </div>
        
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to={"/profile/" + user.id}>Profil</Link></li>
                                        { (this.handleCheck("modifier client") == true ) && (<li><a className="dropdown-item" href="#" onClick={() => this.showModal(user)}>Editer</a></li>)}
                                        { (this.handleCheck("supprimer client") == true ) && (<li><a className="dropdown-item text-danger" onClick={ () => this.delete(user.id)} href="#">Supprimer</a></li>)}
                                    </ul>
                                </div>
                                
                            </td>
                        </tr>
                    )
                }
                else{
                    
                    return(

                        <div className="col-md-3 overflow-hidden" key={user.id}>
                        <div className="card shadow bg-white mb-2">
                            <div className="card-body pb-0">
                                
                                <Link to={"/profile/" + user.id }  className="text-decoration-none">
                                    <img src={ user.avatar } className="img-thumbnail"  style={{ width : 185 +"px", height : 220 + "px" }} />
                                </Link>
                                <div className="ms-2 mt-2">
                                    {/* <div className="text-end small text-danger">{  user.userprofile ? user.userprofile.profile ? user.userprofile.profile.name : "staff" : "staff" }</div> */}
                                    <div className="">{  user.name  } <span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">{ user.userprofile.status}</span></div>
                                    <div><a href={"tel:"+  user.tel  }  target="_blank" className="text-dark text-decoration-none me-2 text-12"> <i className="fa fa-phone text-primary"></i> {  user.tel  }</a> <br />
                                    <a href={"mailto:"+ user.email }  target="_blank" className="text-dark text-capitalize text-decoration-none text-12"> <i className="fa fa-envelope text-yellow"></i> { user.email}</a> </div>
                                    <div><Link to={"/profile/" + user.id } className="text-dark text-capitalize text-decoration-none text-12"> <i className="fa fa-flag text-warning"></i> { user.adress}</Link></div>
                                    <div><Link to={"/profile/" + user.id } className="text-dark text-capitalize text-decoration-none font-10 small"> <i className="fa fa-clock text-danger"></i> <small>{ new Date( user.updatedAt ).toUTCString() }</small></Link></div>
                                </div>
                                    
                                <div className="float-end">
                                {(this.handleCheck("modifier personnel") == true) && (
                                <a href="#" onClick={ () => this.showModal(user)}><i className="fa fa-edit text-primary text-16 me-3" ></i></a>
                                )}

                                {(this.handleCheck("supprimer personnel") == true) && (
                                    <a href="#" onClick={() => this.delete(user.id)}><i className="fa fa-trash text-danger font-16"></i></a>
                                )}
                                </div>   
                            </div>
                        </div>
                    </div>
                    )
                }
        })
        return(
         
                    
                    <div className="dashboard__content bg-light-2">
                           
                            <div className="breadcrumbs">
                                <Head title={'Liste staff'} url={'/staffs/'} logo={'/logo192.png'} />
                                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                                    <div className="col-auto">

                                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Utilisateurs'}</h1>
                                        <div className="text-15 text-light-1">{ 'Liste des utilisateurs' }</div>

                                    </div>
                               
                                    <div className="col-auto">
                                        {
                                            (this.handleCheck("ajouter personnel") == true || this.handleCheck("ajouter client") == true) && (
                                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModal(null)}><i className="fa fa-plus text-15"></i> Nouveau { this.state.profile}</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                                
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs">
                                <div className="row y-gap-20 justify-between items-end ">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            { this.handleCheck("afficher personnel") == true && ( profiles )}
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        {/* { this.handleCheck("afficher profil") == true && (<Link to="/profiles"><span className="rounded-100 py-4 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1"> <i className="fa fa-user"></i> Profile</span></Link>)} */}
                                        <div className="col-auto">
                                            <form className="row form">
                                                <div className="col-auto m-0 p-0 ms-1"><Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                        <option></option>
                                                        <option value="name">par nom</option>
                                                        <option value="tel">par telephone</option>
                                                        <option value="country">par pays</option>
                                                        <option value="city">par ville</option>
                                                        <option value="adress">par adresse</option>
                                                    </select>
                                                </div>
                                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getStaffs({ column : this.state.column, value : select.target.value }))) }} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            
                                {
                                    (this.state.profileId == process.env.REACT_APP_ID_DRIVER  || this.state.profileId == process.env.REACT_APP_ID_EXTERNE) ?
                                        <div className="tabs__content pt-30 js-tabs-content">
                                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                                <div className="overflow-scroll scroll-bar-1">
                                                <table className="table-3 -border-bottom col-12"  style={{ marginBottom : "150px"}}>
                                                    <thead className="bg-light-2">
                                                        <tr>
                                                            <th>Num</th>
                                                            <th>Nom</th>
                                                            <th>Contact</th>
                                                            <th>Adresse</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { staffs}
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    : 
                                    <div className="row"> { staffs} </div>
                                }

                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                        <StaffForm
                            isModal={this.state.isModal}
                            formData={this.state.formData}
                            profileId={this.state.profileId}
                            profile={this.state.profile}
                            showModal={this.showModal}
                        />

                        </div>

      
                


        )
    }
}
export default Staff;
