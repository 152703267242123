import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
import ManifesteDue from "../components/ManifesteDue";
const moment = extendMoment(originalMoment);

class Reportsyntheserec extends Component{

    state = {
        manifestes : [],
        manifesteCount : [],
        status : "A",
        value : null,
        branche : null,
        isManifeste : false,
        loading : false,
        formData : {},
        isDateModal : false
    }

    componentDidMount(){
        this.getManifestes();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getManifestes();
      };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
            value : null
        })
    }

    showModalManifeste = (manifesteId) => {
        this.setState({
            isManifeste : !this.state.isManifeste,
            formData : manifesteId
        })
    }
    
    async getManifestes(val = null){
        this.setState({
            manifestes : [],
            loading : true
        })
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD 00:00');
        const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD hh:mm');

        let date =  moment.range(startOfMonth, endOfMonth)
        
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        if(val?.column)
        {
            let request = await axios.get('/manifestes/$' +  val?.column +"$/" + start + '/' + end + '/' + val?.value);
            if(request.status === 200)
                this.setState({manifestes : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.status
            let request = await axios.get('/manifestes/status/' + start + '/' + end + '/' + status);
            if(request.status === 200)
                this.setState({manifestes : request.data, loading : false})

            request = await axios.get('/manifestes/count/' + start + '/' + end );
            if(request.status === 200)
                this.setState({manifesteCount : request.data})
        }
    }

    changeType = (type)  => {
        this.getManifestes(type)
        this.setState({
            status : type,
            isRecherche : false,
            column : ""
        })
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let detailExpedition = this.state.status == "EnCours" ? "Liste des manifestes encours d'embarquement" : this.state.status == "Acredit" ? "Liste des manifestes expediés acredit" : "Liste des expéditions arrivées a destination"
        let activate = "", countmanifestes = 0;
        let status = 
        ['EnCours', 'Acredit', 'Arriver', "Payer"].map((type, count) => {
            activate = ""
            if(this.state.status == type) activate = " is-tab-el-active ";
            countmanifestes = this.state.manifesteCount.filter(val => val.status == type)
            countmanifestes = countmanifestes.length == 1 ? countmanifestes[0].count : 0
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeType(type)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)} title={ type == "EnCours" ? "Liste des manifestes encours d'embarquement" : type == "Acredit" ? "Liste des manifestes expediés acredit" : "Liste des expéditions arrivées a destination"}> { type } ({ countmanifestes })</button>
                </div>
            )
        })

        let colis = 0, coliInvoice = 0, price = 0, due = 0, litige = 0, budget = 0, depense = 0, comission = 0, tBudget = 0, tLitige = 0, tColis = 0, tColiInvoice = 0, tPrice = 0, tdue = 0, tdepense = 0
        let litiges = [], manifestes =
        this.state.manifestes.map((manifeste, count) => {
            let invoiceK = 0, coliprice = 0
            colis = 0
            price = 0
            coliInvoice = 0
            due = 0
            depense = 0
            comission = 0
            litige = 0
            budget = 0
            manifeste.depenses?.filter(dep => dep.typedepense?.ismanifeste == 1).map( depese => comission += depese.mount)
            manifeste.depenses?.filter(dep => dep.typedepense?.ismonth == 0 && dep.brancheId != process.env.REACT_APP_BRANCHE_KMPL).map( depese => {
                depense += depese.mount
            })
            litiges = 
            manifeste.manifestecolis?.map((manifestecoli) => {
                return(
                    manifestecoli?.invoices?.filter(val => val.litige > 0)?.map((invoice) => {
                        litige += invoice.litige || 0
                        return(
                            <tr>
                                <td>{ invoice?.litige }</td>
                                <td>{ invoice?.description }</td>
                            </tr>
                        )
                    })
                )
            })

            manifeste.manifestecolis?.map((manifestecoli) => {
                manifestecoli?.invoices?.filter(val => val.brancheId == process.env.REACT_APP_BRANCHE_KMPL).map((invoice) =>{
                    invoiceK += invoice.price
                })
                due += (manifestecoli?.invoices?.length == 0) ? manifestecoli.price : 0
                manifestecoli?.invoices.map((invoice) =>{
                    due += (invoice.due > 0 && invoice.isDue == 1) ? invoice.due : 0
                })
                coliprice = manifestecoli.coli?.price
                colis += manifestecoli.quantity
                price += manifestecoli.price
                coliInvoice +=  manifestecoli.coli ? manifestecoli.coli?.quantity : 0
            })

            let totalKmpl = 0;
            manifeste.budgets.map((budge) => {
                if(budge.brancheId == process.env.REACT_APP_BRANCHE_KMPL)
                    totalKmpl = budge.mount
                budget += budge.mount
            })
            

            tdepense += depense
            tColis += colis
            tPrice += price
            tColiInvoice += coliInvoice
            tdue += due
            tLitige += litige
            tBudget += budget
            return(
                <tr>
                    <td><Link to={"/manifestes/" + manifeste?.id }>{ manifeste.ref_manifeste } </Link></td> {/* A */}
                    <td>{ price }</td> {/* B */}
                    <td>{ comission }</td> {/* C */}
                    <td>{ price - comission }</td> {/* D */}
                    <td>{ depense - 600 } </td> {/* E */}
                    <td>{ "600" } </td> {/* E */}
                    <td>{ litige }</td> {/* F */}
                    <td> { invoiceK }</td> {/* G */}
                    <td> { totalKmpl - invoiceK }</td> {/* H */}
                    <td> { totalKmpl } </td> {/* I */}
                    <td>{ price - comission - depense - litige - totalKmpl }</td> {/* J */}
                    <td><span type="button" onClick={() => { this.showModalManifeste(manifeste.id)}}>{ due }</span></td> {/* K */}
                    <td>{ (price - comission - depense - litige - totalKmpl - due)?.toFixed(2)}</td> {/* L */}
                </tr>
            )
        })
        return(

                    <div className=" bg-light-2">
                               
                        {/* <div className="border bg-white p-1 m-1"><Profil /></div> */}

                        <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                            <div className="tabs -underline-2 js-tabs row">
                                <div className="row y-gap-20 justify-between items-end">
                                    <div className="col-auto">
                                        <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                                            { status }
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <form className="row form">
                                            <div className="col-auto m-0 p-0 ms-1">
                                                <Link to="#" className="my-3" >
                                                    <ReactToPrint
                                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                                        content={() =>  this.componentRef}
                                                    />
                                                </Link> 
                                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1 d-none">Rechercher</Link> </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <select name="column" value={this.state.column} className="form form-control" onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                                    <option></option>
                                                    <option value="ref_manifeste">par ref manifeste</option>
                                                    <option value="vehicle.plaque">par plaque</option>
                                                    <option value="origine.city">par origine</option>
                                                    <option value="destination.city">par destination</option>
                                                    <option value="driver.name">par chauffeur</option>
                                                </select>
                                            </div>
                                            <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                                <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getManifestes({ column : this.state.column, value : select.target.value }))) }} />
                                            </div>
                                        </form>
                                    </div>

                                </div>

                                <div ref={el => (this.componentRef = el)} className="rotated">
                                    <HeadDoc title={"Syntèse rapport " + this.state.status } />
                                    

                                    <div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                                    <div className="tabs__content pt-30 js-tabs-content">
                                        <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                            <div className="overflow-scroll scroll-bar-1">
                                            <table className="table_css -border-bottom col-12">
                                                <thead className="bg-light-2">
                                                    <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)",fontSize : 12}}>
                                                        <th>Réf man</th>
                                                        <th>Cash à payer</th>
                                                        <th>Commission</th>
                                                        <th>Montant net</th>
                                                        <th>Depense</th>
                                                        <th>Vehicule</th>
                                                        <th>Litige</th>
                                                        <th>Paye KMPL</th>
                                                        <th>Rembour KMPL</th>
                                                        <th>Total KMPL</th>
                                                        <th>Brut avant dette</th>
                                                        <th>Evolution dette</th>
                                                        <th>Brute apres dette</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { manifestes}
                                                    {/* <tr>
                                                        <th></th>
                                                        <th colSpan={2}>Total</th>
                                                        <th>{ (tPrice).toFixed(2) }</th>
                                                        <th>{ tdepense }</th>
                                                        <th>{ tBudget}</th>
                                                        <th>{ tLitige}</th>
                                                        <th>{ tColis + "/" + tColiInvoice }</th>
                                                        <th>{ tdue }</th>
                                                        <td>{ tPrice - tdepense - tBudget - tLitige - tdue }</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>


                                            </div>
                                        </div>

                                        <div className="tabs__pane -tab-item-1 is-tab-el-active col-md-6 d-none" style={{ marginTop : 50 }}>
                                            <div className="overflow-scroll scroll-bar-1">
                                                <table className="table_css -border-bottom col-12">
                                                    <thead className="bg-light-2">
                                                        <tr style={{ color : "rgb(0, 0, 150)",fontSize : 12}}>
                                                            <th>Litige</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { litiges }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                                    
                                    </div>
                                </div>
                                <ManifesteDue isModal={ this.state.isManifeste} formData={ this.state.formData} showModal={ this.showModalManifeste} />
                            {
                                this.state.loading && (<Loading /> )
                            }

                            </div>

                             
                        </div>

                       
                        <Modal
                            show={this.state.isDateModal}
                            onHide={() => this.showModalDate()}
                            size="sm"
                            backdrop={"static"}>
                            <Modal.Body className="row">

                                <strong>{this.renderSelectionValue()}</strong>
                                <DateRangePicker
                                    value={this.state.value}
                                    onSelect={this.onSelect}
                                    singleDateRange={true}
                                />

                            </Modal.Body>
                        </Modal>

                        </div>

      
                


        )
    }
}
export default Reportsyntheserec;
