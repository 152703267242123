import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Head from "../partials/Head";
import ReportManifeste from "./ReportManifeste";
import ReportColi from "./ReportColi";
import ReportInvoice from "./ReportInvoice";
import axios from "axios";
import ReportVehicle from "./ReportVehicle";
import ReportArticle from "./ReportArticle";
import ReportDepense from "./ReportDepense";
import ReportDriver from "./ReportDriver";
import ReportClient from "./ReportClient";
import Reportsynthese from "./Reportsynthese";

const moment = extendMoment(originalMoment);
class Report extends Component{

    state = {
        branches : [],
        value : null,
        isDateModal : false
    }

    componentDidMount(){
        this.getBranches()
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getBranches(){
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")

        let request = await axios.get('branches/' + start + '/' + end + '/')
        if(request.status == 200){
            this.setState({ branches : request.data })
        }
    }


    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getBranches()
      };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };

    showModalDate = () => {
        this.setState({
            isDateModal : true,
        })
    }

    handleCheck(val) {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        let branches = 
        this.state.branches.map((branche) => {
            let mount = 0, price = 0, colis = 0, stock = 0, prices = 0
            branche.depenses?.map(depense => {
                mount += depense.mount || 0
            })
            branche.coli_origines?.map(coli => {
                
                if(coli.manifestecolis)
                {
                    coli.manifestecolis.map(manifestecoli => {
                        colis += manifestecoli.quantity
                        price += manifestecoli.price
                    })
                }else{
                    stock += coli.quantity
                    prices += coli.price
                }
            })
            return(
                <div className="col-xl-4 col-md-4" key={ branche.id }>
                    <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="row y-gap-20 justify-between items-center">
                        <div className="col-auto">
                        <div className="fw-500 lh-14">{ branche.country}</div>
                        <div className="text-26 lh-16 fw-600 mt-5" title={"solde expedie " + price + "$ - " + mount + "$ depense"}>{ price.toFixed(2) - mount }$</div>
                        <div className="text-15 lh-14 text-light-1 mt-5">{ branche.city} { branche.region}</div>
                        </div>

                        <div className="col-auto" title="solde expedie ~ solde en stock">
                            { price.toFixed(2)}$ ~ { prices.toFixed(2)}$ <span className="text-danger" title=" (depense)">{ mount }$</span>
                            <div title="cols expedie ~ colis en stock">{ colis} ~ { stock}</div>
                        </div>
                    </div>
                    </div>
                </div>
            )
        })
        return(
            <div className="dashboard__content bg-light-2">
                           
            <div className="breadcrumbs">
                <Head title={'Recapitulatif des mouvements'} url={'/soldes/'} logo={'/logo192.png'} />
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Rapports ' } </h1>
                        <div className="text-15 text-light-1">{ 'Recapitulatif des mouvements' }</div>

                    </div>
                    <div className="col-auto">
                    
                        <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                            <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row y-gap-30">
                { branches }
            </div>

            {
            <div className="row pb-10 mb-10">

                <div className="col-md-12 p-0 pt-10 pb-10">
                    <ReportManifeste />
                </div>

                <div className="col-md-12 p-0 pt-10 pb-10">
                    <ReportInvoice />
                </div>

                <div className="col-md-7 p-0 pe-1 pt-10 pb-10">
                    <ReportVehicle />
                </div>

                <div className="col-md-5 p-0 pt-10 pb-10">
                    <ReportArticle />
                </div>

                <div className="col-md-12 p-0 pt-10 pb-10">
                    <ReportColi />
                </div>

                <div className="col-md-12 p-0 pt-10 pb-10">
                    <ReportDepense />
                </div>

                <div className="col-md-5 p-0 pt-10 pb-10">
                    <ReportClient />
                </div>
                <div className="col-md-7 p-0 ps-1 pt-10 pb-10">
                    <ReportDriver />
                </div>

                <div className="col-md-12 p-0 pt-10 pb-10">
                    <Reportsynthese />
                </div>

            </div>
            }
            

            <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>

      </div>
        )
    }
}
export default Report;
