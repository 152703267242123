import axios from "axios";
import React, {Component} from "react";
import ManifesteForm from "./ManifesteForm";
import { Link } from "react-router-dom";
import InvoiceDesign from "./InvoiceDesign";
import DepenseListeForm from "./DepenseListeForm";
import Modal from "react-bootstrap/Modal";
import BudgetForm from "./BudgetForm";
import PrintManifeste from "./PrintManifeste";

class   ManifestDetail extends Component{

    state = {
        id : null,
        manifeste : {},
        depenses : [],
        budgets : [],
        branches : [],
        displayDetail : false,
        isPrint : false,
        search : "",
        formData : null,
        formDataDepense : null,
        formDataBudget : null,
        isManifesteModal : false,
        isDepenseModal : false,
        isBudgetModal : false,
        isInvoiceModal : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount(){
        this.getBranches()
        this.getManifeste()
        this.getDepense()
        this.getBudget()
    }

    async getManifeste(){
        let id = this.props.formData ? this.props.formData.id :  this.props.match?.params?.id
        var request = await axios('manifestes/' + id );
        if(request.status == 200)
            this.setState({ manifeste : request.data, displayDetail : this.props.formData?.detail  })
    }

    async getDepense(){
        let id = this.props.formData ? this.props.formData.id :  this.props.match?.params?.id
        var request = await axios('/depenses/manifesteId/' + id );
        if(request.status == 200)
            this.setState({ depenses : request.data  })
    }

    async getBudget(){
        let id = this.props.formData ? this.props.formData.id :  this.props.match?.params?.id
        var request = await axios('/budgets/manifesteId/' + id );
        if(request.status == 200)
            this.setState({ budgets : request.data  })
    }

    showModalManifeste = (data = null, count = null) => {
        if(data != 1)
        data.count = count
        this.setState({
            isManifesteModal : !this.state.isManifesteModal,
            formData : data
        })
        if(data == 1)
            this.getManifeste()
    }

    showModalDepense = (data = null, brancheId = false) => {
        this.setState({
            isDepenseModal : !this.state.isDepenseModal,
            formDataDepense : {
                brancheId : brancheId,
                status : this.state.manifeste?.status,
                column : "manifesteId",
                value : data ? data.id : null,
                manifestecolis : data ? data.manifestecolis : [],
                manifesteId : data ? data.id : null
            }
        })
        if(data == 1)
            this.getDepense()
    }

    showModalBudget = (data = null, brancheId = false) => {
        this.setState({
            isBudgetModal : !this.state.isBudgetModal,
            formDataBudget : {
                brancheId : brancheId,
                manifesteId : data ? data.id : null
            }
        })
        if(data == 1)
            this.getBudget()
    }

    showModalInvoice = (val, type, column) =>{
        
        let data = null
        if(val && val != 1)
        {
            data = {
                id : val?.coli?.id || val.id,
                manifestecoliId : val?.coli?.id ?  val.id : null,
                invoiceId : type == "FACTURE" ? null : null,
                ref_number : type == "FACTURE" ? val?.ref_invoice : val?.ref_coli,
                due : val?.due,
                column : column,
                type : type
            }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        }
        if(val == 1){
            this.setState({isInvoiceModal : !this.state.isInvoiceModal})
            this.getManifeste()
        }
    }

    showPrint=()=>{
        this.setState({ isPrint : false})
    }
    
    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    async getBranches(){
        let request = await axios('branches/' );
        if(request.status == 200)
            this.setState({ branches : request.data })
    }


    render(){
        let brancheId, branche = localStorage.getItem('branche')
        branche = branche ?  JSON.parse(branche):{}
        brancheId = false

        if(branche && branche.userprofile?.brancheId == this.state.manifeste?.origine?.id) brancheId = true
        if(branche && branche.userprofile?.brancheId == this.state.manifeste?.destination?.id) brancheId = true

        let totalDepense = 0, colis = "", totalBudget = 0, price_Total = 0, coli_Total = 0, due = 0;
        let city = "Bukavu", coliT = 0, priceT = 0, listsomme = ""
        colis = 
        this.state.branches.map((branche) => {
            coliT = 0
            priceT = 0
            return(
                <>{
            this.state.manifeste?.manifestecolis?.filter( val => val.coli?.destination?.city == branche.city && (  !this.state.search || this.state.search && (val.coli?.ref_coli?.toLowerCase().includes(this.state.search?.toLowerCase()) || val.coli?.client?.name?.toLowerCase().includes(this.state.search?.toLowerCase()) )))?.sort((a, b) => a.coli?.ref_coli > b.coli.ref_coli ? 1 : -1).map((manifestecoli, count) => {
                let pricecolis = 0
                let dueInvoice = 0
                coliT += manifestecoli.coli?.expedie || 0
                priceT += manifestecoli.price || 0
                manifestecoli.coli?.invoices?.filter(val => val.brancheId == process.env.REACT_APP_BRANCHE_KMPL)?.map(invoice =>{
                    pricecolis += invoice.price 
                })
                coli_Total += parseFloat(manifestecoli.coli?.expedie)
                price_Total += parseFloat(manifestecoli.price)
                return(
                    <tr>
                        <td>{ count+1} </td>
                        <td><label htmlFor={count}><Link to={"/profile/" + manifestecoli.coli?.client?.id }>{ manifestecoli.coli?.client?.name }</Link> </label></td>
                        <td><Link to="#" onClick={ () => this.showModalInvoice(manifestecoli.coli, "BORDEREAU", "$colis.id$")}>{ manifestecoli.coli?.ref_coli } <small className={ manifestecoli.coli?.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1 table_action" : manifestecoli.coli?.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3 table_action" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green table_action"}>{ manifestecoli.coli?.status != "Manifeste" ? manifestecoli.coli?.status : "" } </small></Link></td>
                        { (this.state.manifeste.status == "Arriver" || this.state.manifeste.status == "Payer" ) && (<td>
                            {
                                manifestecoli.coli?.invoices?.sort((a, b) => a.ref_invoice > b.ref_invoice ? 1 : -1)?.map((invoice) => {

                                    // let count = manifestecoli.coli?.invoices?.filter(a => a.coliId == invoice.coliId && a.type == "Dette").length
                                    due +=  invoice.isDue == 1 ? (invoice?.due || 0): 0
                                    dueInvoice += ( invoice.isDue == 1 ) ? invoice?.due || 0 : false
                                    return(
                                        <Link to="#" onClick={ () => this.showModalInvoice(invoice, "FACTURE", "$invoices.id$")}>{ invoice?.ref_invoice || "Facture" } <small className={ manifestecoli.coli?.status == "Manifeste" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1 table_action" : manifestecoli.coli?.status == "EnStock" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3 table_action" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green table_action"}> <span className={ invoice?.type == "Dette" && ("text-danger")}> { invoice?.type } { invoice?.due > 0 ? invoice?.due +"$" : "" } </span> </small></Link>
                                    )
                                })
                            }
                            
                            {
                                (dueInvoice > 0  || manifestecoli.coli?.invoices?.length == 0)?     <Link to="#" onClick={ () => this.showModalInvoice(manifestecoli, "FACTURE", "$colis.id$")}>{ manifestecoli.coli?.ref_invoice || "Facture" }</Link>: ""
                            }
                            </td>)}
                        <td><label htmlFor={count}>{ manifestecoli.coli?.expedie }</label></td>
                        <td><label htmlFor={count}>{ manifestecoli.price?.toLocaleString() }</label></td>
                        <td><label htmlFor={count}>{ manifestecoli.manifestecolidetails?.map((manifestecolidetail) => { return (<span>{ manifestecolidetail.quantity}{ manifestecolidetail.colidetail?.unite?.name} { manifestecolidetail.colidetail?.article?.name}, </span>)  }) }</label></td>
                        <td><label htmlFor={count}>{ manifestecoli.coli?.destination?.city } { manifestecoli.coli?.destination?.region }</label></td>
                    </tr>
                )
            }) }
                <tr style={{ color : "rgb(0, 0, 160)", fontWeight : "800"}}>
                    <th colSpan={4}>{ coliT > 0 ? branche.city : "."}</th>
                    <th>{ coliT > 0 ? coliT?.toLocaleString() : "" }</th>
                    <th>{ coliT > 0 ?  priceT?.toLocaleString() : "" }</th>
                </tr>
             </>
            )
        })

        let rows = []
        for (let index = 0; index < (10 - this.state.manifeste?.colis?.length); index++) {
            rows.push(<tr><td>.</td><td></td>{ (this.state.manifeste.status == "Arriver" || this.state.manifeste.status == "Payer" ) && (<td></td>)}<td></td><td></td><td></td></tr>)
        }

        this.state.depenses.map((depense) => {
            totalDepense += depense.mount
        })

        this.state.budgets.map((buget) => {
            totalBudget += buget.mount
        })

        return(
            <div>
            <div>

                    <div className={ this.handleCheck("ajouter facture") ?" d-flex border border-1 mt-2 border" : "d-none"}>
                        <div className="flex-shrink-1"><img src={ this.state.manifeste?.origine?.logo} style={{ width : "60px", height : "60px"}}/></div>
                        <div className="w-100 text-center">
                            <strong><small>AGENCE DE TRANSPORT </small> { this.state.manifeste?.origine?.name }</strong>
                            <div><small>{ this.state.manifeste?.origine?.social_reason }{ this.state.manifeste?.origine?.trade_registry }{ this.state.manifeste?.origine?.nif }{ this.state.manifeste?.origine?.activity_sector }{ this.state.manifeste?.origine?.tax_center }</small></div>
                        </div>
                    </div>
                <div className="manifesteBodyPdf row x-gap-20 py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="col-3">

                        <div className="cardImage ratio-1:1 w-200 rounded-4">
                        {/* <div className="cardImage ratio ratio-1:1 w-200 rounded-4"> */}
                            <div className="cardImage__content">
                                <strong>N° <Link to={ this.handleCheck("valider manifeste") ? "/manifestes/" + this.state.manifeste.id : "#"}>{ this.state.manifeste.ref_manifeste }</Link></strong>
                            </div>

                            <div className="cardImage__wishlist mt-10">
                                <span className="table_action"><small className={ this.state.manifeste.status == "EnCours" ? "table_actionrounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : this.state.manifeste.status == "Cloture" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> { this.state.manifeste.status }</small></span>
                                <button className="table_action button -blue-1 bg-white size-30 rounded-full shadow-2">
                                    <i className="fa fa-heart text-12 text-danger"></i>
                                </button>
                                <div className="text-14 lh-14 text-light-1">Expéditeur</div>
                                <div className="text-14 lh-14 text-light-1"><Link to={"/profile/" + this.state.manifeste.user?.id}>{ this.state.manifeste.user?.name }</Link></div>
                                <div className="text-14 lh-14 text-light-1"><a href={"tel:" + this.state.manifeste.user?.tel}>{this.state.manifeste.user?.tel}</a> </div>
                                {
                                    (this.handleCheck("valider manifeste") == true && this.state.manifeste?.status != "Payer" && this.state.manifeste?.status != "Arriver" && brancheId) && (
                                        <div className="table_action">
                                            <button type="button" onClick={() => this.showModalManifeste(this.state.manifeste, 1)} className="border-light rounded-100 py-5 px-20 text-14 lh-14 text-primary">Valider expédition</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <h3 className="text-18 lh-14 fw-500">{ this.state.manifeste.origine?.country } { this.state.manifeste.origine?.city } { this.state.manifeste.origine?.region } <i className="fa fa-road text-primary"></i> { this.state.manifeste.destination?.country } { this.state.manifeste.destination?.city } { this.state.manifeste.destination?.region }</h3>

                        <div className="d-flex x-gap-5 items-center pt-10 table_action justify-center ">

                            <i className="fa fa-star text-10 text-yellow-1"></i>

                            <i className="fa fa-star text-10 text-yellow-1"></i>

                            <i className="fa fa-star text-10 text-yellow-1"></i>

                            <i className="fa fa-star text-10 text-yellow-1"></i>

                            <i className="fa fa-star text-10 text-yellow-1"></i>

                        </div>

                        <div className="row x-gap-10 y-gap-10 items-center pt-20">
                            <div className="col-auto">
                            <p className="text-14">
                                <span className=""> { this.state.manifeste.vehicle?.description}</span>
                                <button data-x-click="mapFilter" className="text-blue-1 underline ml-10">  { this.state.manifeste.vehicle?.plaque} { this.state.manifeste.vehicle?.mark?.name}</button>
                            </p>
                            </div>

                            <div className="col-auto">
                            <div className="size-3 rounded-full bg-light-1"></div>
                            </div>

                            <div className="col-auto">
                            <p className="text-14"><strong>{ price_Total?.toLocaleString() }$</strong>, { coli_Total }colis. <small className="text-danger">{ due > 0 ? " Dette " + due +"$" : ""}</small>  
                                <small className="text-primary" type="button" onClick={() => (this.handleCheck("afficher depense") == true ) && (this.showModalDepense(this.state.manifeste, brancheId))}>{ totalDepense > 0 ? " Commission de " + totalDepense +"$" : ""}, </small>
                                <small className="text-success" type="button" onClick={() => (this.showModalBudget(this.state.manifeste, brancheId))}>{ totalBudget > 0 ? " Budget de " + totalBudget +"$" : ""}</small></p>
                            </div>
                        </div>

                        <div className={ this.handleCheck("afficher manifeste") ? "row x-gap-10 y-gap-10 pt-20 table_action" : "d-none"}>

                            <div className="col-auto">
                                <button type="button" onClick={ () => this.setState({ displayDetail : !this.state.displayDetail })} className="border-light rounded-100 py-5 px-20 text-14 lh-14">Details</button>
                            </div>

                            {
                                (this.handleCheck("afficher depense") == true ) && (
                                    <div className="col-auto -blue-1">
                                        <button type="button" onClick={() => this.showModalDepense(this.state.manifeste, brancheId)} className="border-light rounded-100 py-5 px-20 text-14 lh-14 text-primary">Commission</button>
                                    </div>
                                )
                            }

                            {
                                <div className="col-auto -blue-1">
                                    <button type="button" onClick={() => this.showModalBudget(this.state.manifeste, brancheId)} className="border-light rounded-100 py-5 px-20 text-14 lh-14 text-primary">Budget</button>
                                </div>
                            }

                            <div className="col-auto -blue-1">
                                <button type="button" onClick={() => this.setState({ isPrint : true })} className="border-light rounded-100 py-5 px-20 text-14 lh-14"> <i className="fa fa-print"></i> </button>
                            </div>

                            {
                                (this.handleCheck("modifier manifeste") == true && this.state.manifeste?.status == "EnCours" && brancheId) && (
                                    <div className="col-auto -blue-1">
                                        <button type="button" onClick={() => this.showModalManifeste(this.state.manifeste)} className="border-light rounded-100 py-5 px-20 text-14 lh-14 text-primary"><i className="fa fa-edit"></i> </button>
                                    </div>
                                )
                            }

                            <div className="col-12 -blue-1">
                                <form><input type="text" name="search" placeholder="recherche CLIENT OU REF COLIS" value={ this.state.search} onChange={ this.handleInput} className="form form-control form-sm" /></form>
                            </div>


                        </div>
                    </div>

                    <div className="col-3 text-right md:text-left">
                        <div className="d-flex flex-column justify-between h-full">
                            <div className="row x-gap-10 y-gap-10 justify-end items-center md:justify-start">
                            <div className="col-auto">
                                <div className="text-14 lh-14 fw-500">Chauffeur</div>
                                <div className="text-14 lh-14 text-light-1"><Link to={"/profile/" + this.state.manifeste.driver?.id}>{this.state.manifeste.driver?.name}</Link></div>
                                <div className="text-14 lh-14 text-light-1"><a href={"tel:" + this.state.manifeste.driver?.tel}>{this.state.manifeste.driver?.tel}</a> </div>
                                <div className="text-14 lh-14 fw-500">Date sortie</div>
                                <div className="text-14 lh-14 text-light-1">{ this.state.manifeste.datesortie ?  new Date(this.state.manifeste.datesortie).toLocaleDateString("en-Gb")  : "..../..../...."}</div>
                            </div>
                            <div className="col-auto">
                                <div className="table_action flex-center text-white fw-600 text-14 size-40 rounded-4">
                                    <i className="fa fa-user-o text-primary"></i>
                                </div>
                                <div className="table_action flex-center text-white fw-600 text-14 size-40 rounded-4">
                                    <i className="fa fa-calendar text-danger"></i>
                                </div>
                            </div>
                            </div>

                            <div className="pt-24">
                                <div className="fw-500">
                                    <small className="manifesteHeadPdf"> { new Date().toLocaleDateString("en-GB") } </small>
                                    <small className="table_action"><i className="fa fa-calendar text-primary"></i> Période</small>
                                </div>
                                <span className="table_action fw-500 text-blue-4"><small>{ new Date(this.state.manifeste.createdAt).toLocaleDateString("en-GB") } # <span className="">{ new Date(this.state.manifeste.updatedAt).toLocaleDateString("en-GB") }</span></small></span>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className={this.state.displayDetail ? "tabs__content js-tabs-content mb-10 pb-10" : "d-none"}>
                    <div className="tabs__pane -tab-item-1 is-tab-el-active">
                        <div className="">
                        <table className="table_css -border-bottom col-12">
                            <thead className="bg-light-2">
                                <tr style={{ backgroundColor : "rgb(8, 24, 80)",  color : "white"}}> 
                                    <th>#</th>
                                    <th>Client</th>
                                    <th>Bordereau</th>
                                    {
                                        (this.state.manifeste.status == "Arriver" || this.state.manifeste.status == "Payer" ) && (
                                            <th>Réf fac</th>
                                        )
                                    }
                                    <th title="Nombre des colis">Expédiés</th>
                                    <th>Prix($)</th>
                                    <th>Contenus</th>
                                    <th>Destination</th>
                                    {/* {
                                        (this.state.manifeste.status == "EnCours") && (
                                            <th>Action</th>
                                        )
                                    } */}
                                </tr>
                            </thead>
                            <tbody>
                                { colis}
                                { rows }
                                <tr><td colSpan={8}>.</td></tr>
                                <tr style={{ color : "rgb(0, 0, 160)", fontWeight : "800"}}>
                                    <th>Total</th>
                                    <th colSpan={2}></th>
                                    { (this.state.manifeste?.status == "Arriver" || this.state.manifeste?.status == "Payer" ) && (<th></th>)}
                                    <th>{ coli_Total }</th>
                                    <th>{ price_Total?.toLocaleString() }</th>
                                    <th></th>
                                 </tr>
                                 <tr style={{ color : "rgb(0, 0, 160)", fontWeight : "800"}}>
                                    <td>Commission</td>
                                    <td colSpan={2}></td>
                                    { (this.state.manifeste?.status == "Arriver" || this.state.manifeste?.status == "Payer" ) && (<td></td>)}
                                    <td></td>
                                    <td>{ totalDepense?.toLocaleString() }</td>
                                    <td></td>
                                 </tr>
                                 <tr style={{ color : "rgb(0, 0, 160)", fontWeight : "800"}}>
                                    <th>Solde</th>
                                    <th colSpan={2}></th>
                                    { (this.state.manifeste?.status == "Arriver" || this.state.manifeste?.status == "Payer" ) && (<th></th>)}
                                    <th></th>
                                    <th>{ (price_Total - totalDepense)?.toLocaleString() }</th>
                                    <th></th>
                                 </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>

            </div>
                <hr />
                <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
                <ManifesteForm showModal={ this.showModalManifeste} formData={ this.state.formData} isModal={ this.state.isManifesteModal} />
                <PrintManifeste showModal={ this.showPrint } formData={{ id : this.state.manifeste?.id }} isModal={this.state.isPrint} />
                <Modal
                    show={this.state.isDepenseModal}
                    onHide={() => this.showModalDepense()}
                    size="lg"
                    // onEntered={this.edit}
                    backdrop={"static"}>
                    <Modal.Body className="row">

                        <DepenseListeForm  formData={this.state.formDataDepense} />

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => this.showModalDepense(1)} type="button" className="btn btn-outline-secondary btn-sm"><i className="bi bi-corner-down-right"></i> Fermer</button>
                        <button onClick={() => this.showModalDepense(1)} type="button" className="btn btn-outline-primary btn-sm"><i className="bi bi-corner-down-left"></i> Valider</button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.isBudgetModal}
                    onHide={() => this.showModalBudget()}
                    size="lg"
                    // onEntered={this.edit}
                    backdrop={"static"}>
                    <Modal.Body className="row">

                        <BudgetForm  formData={this.state.formDataBudget} />

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => this.showModalBudget(1)} type="button" className="btn btn-outline-secondary btn-sm"><i className="bi bi-corner-down-left"></i> Fermer</button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
export default ManifestDetail;
