import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import ReactToPrint from "react-to-print";
import InvoiceDesign from "../components/InvoiceDesign";
import Modal from "react-bootstrap/Modal";
import DateRangePicker from "react-daterange-picker";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeadDoc from "../partials/HeadDoc";
const moment = extendMoment(originalMoment);

class ReportInvoice extends Component{

    state = {
        invoices : [],
        branches : [],
        value : null,
        invoiceCount : [],
        typeInvoice : "Cash",
        formDataInvoice : false,
        isInvoiceModal : false,
        isRecherche : false,
        loading : false,
        isDateModal : false
    }

    componentDidMount(){
        this.getInvoices();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    onSelect = (value, states) => {
        this.setState({ value, states, isDateModal : false });
        this.getInvoices();
      };
    
    renderSelectionValue = () => {
        return (
            <span>
                {this.state.value?.start?.format("YYYY-MM-DD")}
                {" - "}
                {this.state.value?.end?.format("YYYY-MM-DD")}
            </span>
        );
    };
    
    showModalDate = () => {
        this.setState({
            isDateModal : true,
            value : null
        })
    }

    async getBranches(){
        this.setState({
            loading : true
        })
        var request = await axios.get('branches');
        if(request.status === 200)
        {
            this.setState({branches : request.data, loading : false})
        }
    }

    async getInvoices(val = null){
        this.setState({
            invoices : [],
            loading : true
        })
        const today = moment();
        let date =  moment.range(today.clone().subtract(7, "days"), today.clone())
        this.setState({ value : !this.state.value ? date : this.state.value})
        let start = this.state.value ? this.state.value?.start?.format("YYYY-MM-DD") : date.start?.format("YYYY-MM-DD")
        let end = this.state.value ? this.state.value?.end?.format("YYYY-MM-DD") : date.end?.format("YYYY-MM-DD")
        if(val?.column)
        {
            let request = await axios.get('/invoicesreport/$' +  val?.column +"$/" + start + '/' + end + '/' + val?.value);
            if(request.status === 200)
                this.setState({invoices : request.data, loading : false})
        }
        else{
            let status = val ? val : this.state.typeInvoice
            let request = await axios.get('/invoicesreport/type/' + start + '/' + end + '/' + status);
            if(request.status === 200)
                this.setState({invoices : request.data, loading : false})

            request = await axios.get('/invoice/count/' + start + '/' + end );
            if(request.status === 200)
                this.setState({invoiceCount : request.data})
        }
    }

    showModalInvoice = (coli, invoice) =>{
        
        let data = {}
        if(coli && coli != 1)
        {
            data = {
                id : coli,
                invoiceId : invoice?.id,
                ref_number : invoice?.ref_invoice,
                due : invoice?.due,
                type : "FACTURE"
            }
        }
        this.setState({ formDataInvoice : data, isInvoiceModal : !this.state.isInvoiceModal})
        if(coli == 1)
            this.getInvoices(this.state.typeInvoice)
    }


    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeType = (type)  => {
        this.getInvoices(type)
        this.setState({
            typeInvoice : type,
            isRecherche : false,
            column : ""
        })
    }

    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }


    render(){
        
        let activate = "", countInvoice = 0, status = 
        ['Cash', 'Dette'].map((type, count) => {
            activate = ""
            countInvoice = this.state.invoiceCount.filter(val => val.type == type) ? this.state.invoiceCount.filter(val => val.type == type)[0]?.count || 0 : 0
            if(this.state.typeInvoice == type) activate = " is-tab-el-active ";
            return(
                <div className="col-auto">
                    <button onClick={ () => this.changeType(type)} className={"tabs__button text-16 lg:text-16 text-light-1 fw-500 pb-5 lg:pb-0 js-tabs-button " + activate }  data-tab-target={".-tab-item-" + (count++)}> { type } ({countInvoice})</button>
                </div>
            )
        })

        let tColis = 0, tPrice = 0, tDue = 0, texpedie = 0;
        let invoices =
        this.state.invoices.map((invoice, count) => {
            let sum = 0, coliD = null, expedie = 0
            
                expedie += invoice?.coli.expedie
                coliD = invoice.coli; 
                sum += invoice?.coli.quantity
                invoice?.coli.manifestecolis?.map(manifestecoli => { expedie += manifestecoli.quantity })
             
            tColis += sum
            tPrice += invoice.price
            tDue += invoice.due
            texpedie += expedie
            return(
                <tr>
                    <td>{ count+1} </td>
                    <td><Link to={"#"} onClick={ () => this.showModalInvoice(coliD.id, invoice)}>{ invoice.ref_invoice }</Link> </td>
                    {/* <td>{ invoice.colis?.map(coli => { return <Link to={"/manifestes/" + coli.manifeste?.id }>{ coli.manifeste?.ref_manifeste } <small className={ coli.manifeste?.status == "EnCours" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-blue-1-05 text-blue-1" : coli.manifeste?.status == "Cloture" ? "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-yellow-4 text-yellow-3" : "rounded-100 py-4 px-10 text-center text-10 fw-500 bg-green-1 text-green"}> { coli.manifeste?.status }</small> ,</Link> }) }</td> */}
                    <td><Link to={"/profile/" + invoice.client?.id}>{ invoice?.client?.name }</Link></td>
                    <td>{ expedie }</td>
                    <td>{ sum }</td>
                    <td>{ invoice.price }</td>
                    <td>{ invoice.due }  </td>
                    <td><small>{ new Date(invoice.createdAt).toLocaleDateString("en-GB") }</small></td>
                </tr>
            )
        })

        
        return(
            <div className=" bg-light-2">
         
            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                    <div className="row y-gap-20 justify-between items-end">
                        <div className="col-auto">
                            <div className="tabs__controls row x-gap-40 y-gap-10 lg:x-gap-20 js-tabs-controls">
                             { status}
                            </div>
                        </div>

                        <div className="col-auto">
                            <form className="row form">
                                <div className="col-auto m-0 p-0 ms-1">
                                <Link to="#" className="my-3" >
                                    <ReactToPrint
                                        trigger={() => <button type="button"  className="rounded-100 px-5 text-center text-14 fw-500 bg-yellow-4 text-yellow-3" ><i className="fa fa-print"></i> Imprimer</button>}
                                        content={() =>  this.componentRef}
                                    />
                                </Link>
                                <Link to="#" onClick={ () => this.setState({ isRecherche : !this.state.isRecherche, column : "" })}  className="rounded-100 py-2 px-10 text-center text-14 fw-500 bg-blue-1-05 text-blue-1">Rechercher</Link> </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <select name="column" className="form form-control" value={ this.state.column} onChange={ (select) => { this.setState({ column : select.target.value})}} >
                                        <option></option>
                                        <option value="ref_invoice">par ref facture</option>
                                        <option value="colis.manifestecolis.manifeste.ref_manifeste">par ref manifeste</option>
                                        <option value="client.name">par client</option>
                                    </select>
                                </div>
                                <div className={ this.state.isRecherche ?"col-auto m-0 p-0 ms-1" : "d-none"}>
                                    <input type="text" name="value" onChange={(select) => { this.state.column &&( select.target.value?.length > 3 && (this.getInvoices({ column : this.state.column, value : select.target.value }))) }} />
                                </div>
                            </form>
                        </div>

                    </div>
                
                    <div ref={el => (this.componentRef = el)}>
                        <div className=" text-center text-primary">
                            <HeadDoc title={"Facture" } />
                        </div> <div className="text-center table_action"> <button type="button" className="rounded-100 px-10 text-center text-14 fw-500 bg-red-3 text-red-2" onClick={() => this.showModalDate(null)}><i className="fa fa-calendar text-15"></i> {this.renderSelectionValue()} </button></div>
                        <div className={"tabs__content pt-30 js-tabs-content " + this.state.colTable}>
                            <div className="tabs__pane -tab-item-1 is-tab-el-active">
                                <div className="overflow-scroll scroll-bar-1">
                                <table className="table_css -border-bottom col-12">
                                    <thead className="bg-light-2">
                                        <tr style={{ backgroundColor : "rgb(0, 0, 150)", color : "rgb(250, 250, 250)"}}>
                                            <th>#</th>
                                            <th>Réf fac</th>
                                            {/* <th>Réf Man</th> */}
                                            <th>Client</th>
                                            <th title="Nombre des colis expédiés">Expédiés</th>
                                            <th title="Nombre des colis">Total colis</th>
                                            <th>{ "Prix($)" }</th>
                                            <th>Dette($)</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { invoices }
                                        <tr>
                                            <th></th>
                                            <th>Total</th>
                                            <th></th>
                                            <th>{texpedie }</th>
                                            <th>{ tColis }</th>
                                            <th>{ tPrice} </th>
                                            <th>{ tDue} </th>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.loading && (<Loading /> )
                    }

                </div>

            </div>
            <InvoiceDesign showModal={ this.showModalInvoice} formData={ this.state.formDataInvoice} isModal={ this.state.isInvoiceModal} />
              <Modal
                show={this.state.isDateModal}
                onHide={() => this.showModalDate()}
                size="sm"
                backdrop={"static"}>
                <Modal.Body className="row">

                    <strong>{this.renderSelectionValue()}</strong>
                    <DateRangePicker
                        value={this.state.value}
                        onSelect={this.onSelect}
                        singleDateRange={true}
                    />

                </Modal.Body>
            </Modal>
                        
      </div>
        )
    }
}
export default ReportInvoice;
