import React, {Component} from "react";
import Head from "../partials/Head";
import  ManifestDetail   from "../components/ManifestDetail";
import ManifesteForm from "../components/ManifesteForm";

class ManifesteDetail  extends Component{

    state = {
        isModalManifeste : false
    }

    componentDidMount(){
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    showModalManifeste = (e = null) => {
        this.setState({
            isModalManifeste : !this.state.isModalManifeste
        })
        if(e == 1)
            window.location.replace(process.env.REACT_APP_EVIVEMANIFESTE + "/manifestes");
    }
    handleCheck(val) {
        let permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    render(){
        return(
        <div className="dashboard__content bg-light-2">
                           
            <div className="breadcrumbs">
                <Head title={'Detail du manifeste'} url={'/manifestes/'} logo={'/logo192.png'} />
                <div className="row y-gap-20 justify-between items-end pb-60 lg:pb-40 md:pb-32">
                    <div className="col-auto">

                        <h1 className="text-25 lh-14 fw-500 text-primary">{ 'Detail manifeste '}</h1>
                        <div className="text-15 text-light-1">{ 'Detail des colis sur le manifeste' }</div>

                    </div>

                    <div className="col-auto">
                        {
                            this.handleCheck("ajouter manifeste") == true && (
                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                    <button type="button" className="button py-5 px-5 -dark-1 bg-blue-1 text-white" onClick={() => this.showModalManifeste(null)}><i className="fa fa-plus text-15"></i> Nouveau manifeste</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            

            <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                <div className="tabs -underline-2 js-tabs row">
                
                    <ManifestDetail formData={{ id : this.props.match?.params?.id , detail : true}} />
                </div>
            </div>
            <ManifesteForm isModal={ this.state.isModalManifeste} showModal={ this.showModalManifeste} />
        </div>

        )
    }
}
export default ManifesteDetail;
