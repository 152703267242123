import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Login from "./partials/Login";
import Register from "./partials/Register";
import Society from "./pages/Society";
import Staff from "./pages/Staff";
import Profile from "./pages/Profile";
import Profil from "./pages/Profil";
import Vehicle from "./pages/Vehicle";
import Coli from "./pages/Coli";
import Invoice from "./pages/Invoice";
import Home from "./pages/Home";
import Manifeste from "./pages/Manifeste";
import ManifesteDetail from "./pages/ManifesteDetail";
import Solde from "./pages/Solde";
import Depense from "./pages/Depense";
import Report from "./pages/Report";
import Contact from "./pages/Contact";
import Expedition from "./pages/Expedition";
import Facture from "./pages/Facture";
import Recu from "./pages/Recu";
import Client from "./pages/Client";
import Budget from "./pages/Budget";
import Litige from "./pages/Litige";
 
var permissions = JSON.parse(localStorage.getItem("permissions"))
? JSON.parse(localStorage.getItem("permissions"))
: [];
function handleCheck(val) {
    return  (permissions ?? []).some(item => val === item.name);
}

function RouterPage(){

    return(
        <Switch>
            <Route exact path="/" component={ Home } />
            
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            {
                handleCheck("afficher dashboard") == true && (
                    <Route path="/dashboard" component={Dashboard} />
                )
            }

            {
                handleCheck("configurer branche") == true && (
                    <Route path="/settings" component={Society} />
                )
            }
            
            {
                handleCheck("afficher utilisateur") == true && (
                    <Route path="/staffs" component={Staff} />
                )
            }

            {
                handleCheck("afficher client") == true && (
                    <Route path="/clients" component={ Client} />
                )
            }

            {
                handleCheck("afficher vehicule") == true && (
                    <Route path="/vehicles" component={Vehicle} />
                )
            }

            {
                handleCheck("afficher coli") == true && (
                    <Route path="/colis" component={Coli} />
                )
            }

            {
                handleCheck("afficher facture") == true && (
                    <Route path="/invoices" component={ Invoice } />
                )
            }

            {
                handleCheck("afficher manifeste") == true && (
                    <Route path="/manifestes/:id" component={ ManifesteDetail } />
                )
            }

            {
                handleCheck("afficher manifeste") == true && (
                    <Route path="/manifestes" component={ Manifeste } />
                )
            }

            {
                handleCheck("afficher profil") == true && (
                    <Route path="/profiles" component={Profil} />
                )
            }

            {
                handleCheck("afficher solde") == true && (
                    <Route path="/soldes" component={Solde} />
                )
            }

            {
                handleCheck("afficher rapport") == true && (
                    <Route path="/reports" component={Report} />
                )
            }

            {
                handleCheck("afficher depense") == true && (
                    <Route path="/depenses" component={ Depense } />
                )
            }

            {
                handleCheck("afficher mes expeditions") == true && (
                    <Route path="/expeditions/:id" component={ ManifesteDetail } />
                )
            }
            {
                handleCheck("afficher mes expeditions") == true && (
                    <Route path="/expeditions" component={ Expedition } />
                )
            }

            {
                handleCheck("afficher mes factures") == true && (
                    <Route path="/factures" component={ Facture } />
                )
            }

            {
                handleCheck("afficher mes recus") == true && (
                    <Route path="/recus" component={ Recu } />
                )
            }

            {
                handleCheck("afficher manifeste") == true && (
                    <Route path="/budgets" component={ Budget } />
                )
            }

            {
                handleCheck("afficher facture") == true && (
                    <Route path="/litiges" component={ Litige } />
                )
            }
            
            <Route path="/profile/:id" component={Profile} />
            <Route path="/profile" component={Profile} />
            <Route path="/contacts" component={ Contact } />
            
            <Route component={NotFound} />
        </Switch>
    )
}

export default RouterPage;