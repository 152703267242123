import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   UniteForm extends Component{

    state = {
        id : null,
        name : "",
        error : "",
        unites : [],
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async unites(){
        var request = await axios('unites');
        if(request.status == 200)
            this.setState({ unites : request.data  })
    }

    storeUnite = () =>{
        var data = {
                name : this.state.name,
            }
        var request = this.state.id ? axios.put('unites/'+this.state.id, data) : axios.post('unites', data);
        request.then(async(response) =>{
            toast(response.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(response.data?.data?.id);
        }).catch((error) => {
            this.setState({
                error : error.response?.data?.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
        this.unites()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            name: this.props.formData ? this.props.formData.name : "",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

        return(
            <div>
            <Modal
                className="bg-dark"
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header >
                    <Modal.Title  className="nom-id-icon">{ this.state.id ? "Modifier" : "unite" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0">
                    <div className="col-md-6 mt-2">
                        {
                            this.state.unites.map((unite) => {
                                return(
                                    <label className="border border-1 rounded-3 px-2 mx-1 mb-2" onClick={() => this.setState({ id : unite.id, name : unite.name}) }>{ unite.name }</label>
                                )
                            })
                        }
                    </div>

                    <div className="col-md-6 mt-2 form">
                        
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        <div className="form-group ">
                            <label htmlFor="h6 font-monospace">Unite</label>
                            <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                        </div>
                        <Modal.Footer>
                            <button onClick={() => this.props.showModal(null)} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                            <button onClick={() => this.storeUnite()} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Sauvegarder</button>
                        </Modal.Footer>
                    </div>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default UniteForm;
