import { Component } from "react"; 
import axios from "axios";
import { Link } from "react-router-dom";

class Sidebar extends Component{

  state = {
    tableLink : []
  }

  componentDidMount(){
    this.refreshToken()
  }

  async refreshToken () {
    try{
      var token = await axios.get('token');
      if(token.status === 200)
      {
          var user = (token.data.data);
          localStorage.setItem("user", JSON.stringify(user));
      }
      else if(token.status === 401)
      {
        axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
      }
    }catch(error) {
        axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
    }
}

  handleCheck(val) {
    var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
    return permissions.some(item => val === item.name)
  }

  logout = (e) => {
    axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
    window.localStorage.clear();
    window.location.replace(process.env.REACT_APP_EVIVEMANIFESTE)
  }

  changeLink = (val) => {
    this.setState({
      tableLink : []
    })
    let table = [];
    table.push(val)
    this.setState({
      tableLink : table
    })
  }

  render(){
  let user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : false;
  
    return(
      <div className={ user ? "dashboard__sidebar scroll-bar-1 border-top bg-sidebar" : "d-none"} >
        
      <div className="sidebar -dashboard text-white">

      <div className= { this.handleCheck("afficher dashboard") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button "+ this.state.tableLink.filter(data => data == "dashboard").map((val) => { return " -is-active " })}>
            <Link to="/dashboard" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("dashboard")}>
              {/* <img src="/img/dashboard/sidebar/compass.svg" alt="image" className="mr-15  text-white" /> */}
              <i className="fa fa-compass mr-15"></i>
              Dashboard
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher coli") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "colis").map((val) => { return " -is-active " })}>
            <Link to="/colis" className="d-flex items-center text-15 lh-1 fw-500"  onClick={() => this.changeLink("colis")}>
            <i className="fa fa-compress  mr-15" style={{  fontWeight: "normal" }}></i>
              Colis
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher manifeste") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "manifests").map((val) => { return " -is-active " })}>
            <Link to="/manifestes" className="d-flex items-center text-15 lh-1 fw-500"  onClick={() => this.changeLink("manifests")}>
              {/* <img src="/img/dashboard/sidebar/booking.svg" alt="image" className="mr-15" /> */}
              <i className="fa fa-book mr-15"></i>
              Expédition
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher manifeste") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "budgets").map((val) => { return " -is-active " })}>
            <Link to="/budgets" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("budgets")}>
            <i className="fa fa-flag  mr-15"></i>
              Budget
            </Link>
          </div>
        </div>
        
        <div className= { this.handleCheck("afficher facture") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "invoices").map((val) => { return " -is-active " })}>
            <Link to="/invoices" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("invoices")}>
            <i className="fa fa-usd  mr-15"></i>
              Facture
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher facture") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "litiges").map((val) => { return " -is-active " })}>
            <Link to="/litiges" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("litiges")}>
            <i className="fa fa-credit-card  mr-15"></i>
              Litige
            </Link>
          </div>
        </div>


        <div className= { this.handleCheck("afficher depense") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "depenses").map((val) => { return " -is-active " })}>
            <Link to="/depenses" className="d-flex items-center text-15 lh-1 fw-500"  onClick={() => this.changeLink("depenses")}>
              {/* <img src="/img/dashboard/sidebar/bookmark.svg" alt="image" className="mr-15" /> */}
              <i className="fa fa-bookmark mr-15"></i>
              Dépense
            </Link>
          </div>
        </div>
        
        <div className= { this.handleCheck("afficher solde") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "soldes").map((val) => { return " -is-active " })}>
            <Link to="/soldes" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("soldes")}>
            <i className="fa fa-archive text-16  mr-15"></i>
              Récapitulatif
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher rapport") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "reports").map((val) => { return " -is-active " })}>
            <Link to="/reports" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("reports")}>
              <i className="fa fa-database text-17  mr-15" ></i>
              Rapports
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher client") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "clients").map((val) => { return " -is-active " })}>
            <Link to="/clients" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("clients")}>
            <i className="fa fa-user text-20  mr-15"></i>
              Client
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher utilisateur") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "staffs").map((val) => { return " -is-active " })}>
            <Link to="/staffs" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("staffs")}>
            <i className="fa fa-user-o text-20  mr-15"></i>
              Utilisateur
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher vehicule") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "vehicles").map((val) => { return " -is-active " })}>
            <Link to="/vehicles" className="d-flex items-center text-15 lh-1 fw-500"  onClick={() => this.changeLink("vehicles")}>
              <i className="fa fa-bus text-17  mr-15"></i>
              Véhicule
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("configurer branche") == true ? "sidebar__item" : "sidebar__item d-none"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "settings").map((val) => { return " -is-active " })}>
            <Link to="/settings" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("settings")}>
              {/* <img src="/img/dashboard/sidebar/gear.svg" alt="image" className="mr-15" /> */}
              <i className="fa fa-gear mr-15"></i>
              Paramètre
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher dashboard") == true ? "sidebar__item" : "sidebar__item"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "profile").map((val) => { return " -is-active " })}>
            <Link to="/profile" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("profile")}>
              <i className="fa fa-user text-17  mr-15" ></i>
              Mon profil
            </Link>
          </div>
        </div>

        <div className= { this.handleCheck("afficher dashboard") == true ? "sidebar__item" : "sidebar__item"}>
          <div className={"sidebar__button  "+ this.state.tableLink.filter(data => data == "files").map((val) => { return " -is-active " })}>
            <a href={"/files/manuel_evive_manifeste_v3.html"} target="_blank" className="d-flex items-center text-15 lh-1 fw-500" onClick={() => this.changeLink("files")}>
              <i className="fa fa-folder text-17  mr-15" ></i>
              Manuel.v3
            </a>
          </div>
        </div>

        <div className="sidebar__item">
          <div className={"sidebar__button "}>
            <Link to="#" onClick={ () => this.logout() } className="d-flex items-center text-15 lh-1 fw-500">
              {/* <img src="/img/dashboard/sidebar/log-out.svg" alt="image" className="mr-15" /> */}
              <i className="fa fa-lock mr-15"></i>
              Déconnexion
            </Link>
          </div>
        </div>

      </div>


    </div>
      )
    }
  }
  export default Sidebar;