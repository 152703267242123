import React from "react";
import { useEffect, useState } from "react";

export default function HeadDoc(title = null){

    const [brancheData, brancheDataSet] = useState([])

    useEffect(() => {
      async function fetchBranches() {
        let response = await fetch( process.env.REACT_APP_API_EVIVEMANIFESTE + '/branches')
        response = await response.json()
        brancheDataSet(response)
      }
  
      fetchBranches()
    }, [])

    let branche = brancheData ? brancheData[0] : null
    return (
    <div className=" text-center text-primary">
        <div className=" d-flex border border mt-2">
            <div className="flex-shrink-1">
                <img src={ branche?.logo} style={{ width : "60px", height : "60px"}}/>
            </div>
            <div className="w-100 text-center">
                <strong><small>AGENCE </small> { branche?.name }</strong>
                <div>
                    <small>{ branche?.social_reason }{ branche?.trade_registry }{ branche?.nif }</small>
                    <small className="text-dark ms-2"> { title?.title}</small>
                </div>
            </div>
        </div>
    </div>
    );
}